<template>
 <div>
	 <Navbar :items="routes" />
		<GlobRow>
			<GlobCol :medium="12">
				<GlobXpanel title="Enheter">
					<router-link :to="{name: 'createDevice'}" style="margin-bottom: 1rem; display: inline-block;" class="button button--margin button--small">Skapa ny</router-link>
					<InputText type="text" v-model="searchString" placeholder="Sök" :style="'margin-left: 1rem; padding: .4rem 1rem'"/>
				<GlobTable
					:enableRenderFunctions="true"
					:maxHeight="50"
					:data="{
						head : deviceListHead,
						body : filteredDeviceList
					}"
					:actions="actions"
				/>
				</GlobXpanel>
			</GlobCol>
		</GlobRow>
</div>
</template>

<script>
import { onMounted, computed, watch, ref } from "vue";
import { useApi } from "@/plugins/api";
import { useRouter } from "vue-router";
import Navbar from "@/views/Admin/Navbar.vue";
import { routes } from "@/views/Admin/config";

export default {
components: {
	Navbar
},
 setup() {
	const api = useApi();
	const router = useRouter();
	const endpoint = computed(() => api.devices);
    
	const loaded = ref(false);
	const searchString = ref('');
	const deviceList  = ref([]);
	const deviceListHead = [{
				Alias: "Id",
				Value: "Id"
			},
			{
				Alias: "Name",
				Value: "Namn"
			},
			{
				Alias: "Location",
				Value: "Location"
			},
			{
				Alias: "LastContact",
				Value: "LastContact"
			}
		];
	
	const actions = [
      {
      name: "Redigera",
      color: "green",
      action: (obj) => {
		router.push('/admin/edit/device/' + obj.Id)
      }
      },
    ];
	
	 onMounted(async() => {
          loadDevices();
	 });

	const loadDevices = async() => {
		endpoint.value.get().then(function(res){
           deviceList.value = res.devices;
		})
	}

	const filteredDeviceList = computed(() => {
       return searchString.value.length > 0 ? deviceList.value.filter(i => i.Name && i.Name.toLowerCase().match(searchString.value.toLowerCase())) : deviceList.value
	});

    return {
		//Methods
		loadDevices,
		//Variables
		endpoint,
		filteredDeviceList,
		deviceList,
		deviceListHead,
		searchString,
		actions,
		routes,
		loaded
    };
  }
};
</script>