<template>
  <GlobXpanel :noShadow="true"  :avoidHR="true" :isCRUD="true">
	<GlobSpinner :isLoading="isLoading"/>
    <GlobXpanel :noShadow="true" :tools="false" :title="$t('administration.article_group.picture')" >
      <div class="kits-dropzone" v-dropzone="{ callback: (data) => log(data) }">
        <img v-if="img" class="kits-edit-article__image" :src="img" /><br />
        <input ref="fileInput" type="file" accept="image/*" @change="addImage($event.target.files)" />
	  </div>
	   <template v-slot:buttons>
			<button :disabled="typeof isPrevActive === 'undefined'" :style="'margin-left: auto;'" @click="$emit('goToPrev')" class="button button--small">{{$t('general.button.previous')}}</button>
      		<button :disabled="isNextActive == undefined" :style="'margin-left: auto;'" @click="$emit('goToNext')" class="button button--small">{{$t('general.button.next')}}</button>
	   </template>
	  	<button :disabled="!file" @click="uploadImage" class="button button--margin kis-mt-4">{{$t('administration.article_group.save_image')}}</button>
		<button :disabled="!img" @click="deleteImage" style="margin-left: 1rem;" class="button button--red button--margin">
		{{$t('administration.article_group.remove_image')}}
		</button>
    </GlobXpanel>
	<GlobXpanel :noShadow="true" :tools="false" :title="'General'">
		<label style="font-size: .8rem; max-width: 800px; display: block;">
			{{$t('administration.article_group.fields.name')}}*
			<InputDynamic
			:fullWidth="true"
			styling="margin-bottom: .5rem;"
			:type="'text'"
			:placeholder="$t('administration.article_group.fields.name')"
			v-model="articleGroup.form.Name"
			/>
		</label>

		<label style="font-size: .8rem; max-width: 800px; display: block;">
			{{$t('administration.article_group.fields.description')}}
			<InputDynamic
			:fullWidth="true"
			styling="margin-bottom: .5rem;"
			:type="'text'"
			:placeholder="$t('administration.article_group.fields.description')"
			v-model="articleGroup.form.Description"
			/>
		</label>
	</GlobXpanel>
		<GlobXpanel :noShadow="true" :tools="false" title="Artiklar">
			<GlobRow>
			<GlobCol :medium="6">
				<InputText :fullWidth="true" placeholder="Sök befintliga artiklar" v-model="articleListSearchString" />
				<GlobTable
				:data="{
					body: filteredArticles,
					head: searchResult.head,
				}"
				:actions="actions"
				style="height: 30vh"
				/>
			</GlobCol>
			<GlobCol :medium="6">
				<InputText
				:fullWidth="true"
				placeholder="Sök artiklar att lägga till"
				v-model="articleSearchString"
				@input="searchArticle"
				/>
				<GlobTable :data="filteredSearchResult" :actions="addActions" style="height: 30vh" />
			</GlobCol>
			</GlobRow>
			<button @click="save" class="button kis-mt-2" :disabled="!articleGroup.form.Name">{{$t('general.button.save')}}</button>
			<button @click="$emit('showADeleteConfirmModal', true)" class="button button--red">{{$t('general.button.delete')}}</button>
		</GlobXpanel>
	<div v-if="errorMessage">
		<p class="text-danger mt-2" >{{errorMessage}}</p>
	</div>
  </GlobXpanel>

</template>

<script>
import {onMounted, reactive, ref, computed, watchEffect, watch, toRaw } from "vue";
import { useI18n } from 'vue-i18n';
import { useApi } from "@/plugins/api";
import { useRoute, useRouter } from "vue-router";
import { useNotifications } from "@/plugins/notifications";

export default {
  props: ["router", "articleGroupId", "reloadData", "loading", "isPrevActive", "isNextActive"],
   setup(props, {emit}) {

	const isLoading = ref(false);
	const { tm } = useI18n();
	const notifications = useNotifications();
    const {router } = reactive(props);
    const api = useApi();
	const endpoint = computed(() => api.articlegroups);
    const fileInput = ref(null);
    const file = ref(null);
    const formData = new FormData();    
	const articleGroup = reactive({
		form: {
			Id: "",
			Name: "",
			ImagePath: "",
			Articles: []
		},
	});

	const img = computed(() => (file.value ? URL.createObjectURL(file.value) :  articleGroup.form.ImagePath));


	onMounted(async() => {
        getArticleGroup();
	 });
	 
	const getArticleGroup = (updateMainList = false) => {
		isLoading.value = true;
		endpoint.value.getById(props.articleGroupId).then(function(res){
			if(!res.data){
				notifications.push(
					{
						msg: tm('general')['something_went_wrong']
					},
					"warning",
					false
					);
				router.push(`/admin/view/article-groups`);
			}
			articleGroup.form.Id = res.data.Id;
			articleGroup.form.Name = res.data.Name;
			articleGroup.form.Description = res.data.Description;
			articleGroup.form.ImagePath = res.data.ImagePath;
			articleGroup.form.Articles = res.data.Articles.filter((i) => i.Id);
			isLoading.value = false;

			if(updateMainList){
				emit('updated', res.data);
			}
		});
	 };
	
	
	const addImage = (files) => {
      file.value = files[0];
      console.log(img.value);
      formData.append("image", files[0]);
    };
    const hasImage = ref(file.value || ( articleGroup.form.ImagePath &&  articleGroup.form.ImagePath.length > 0));

    const uploadImage = () => {
      endpoint.value.uploadImage( articleGroup.form.Id, file.value).then((res) => {
        getArticleGroup(true)
        file.value = null;
        fileInput.value.value = null;
      });
    };

	const deleteImage = () => {
		isLoading.value = true;
		endpoint.value.deleteImage(articleGroup.form.Id).then((res) => {
			getArticleGroup(true)
		});
    };

    const data = ref( articleGroup.form);

    const articleSearchString = ref("");
    const articleListSearchString = ref("");
    const searchResult = reactive({
      body: [],
      head: [
        { Alias: "ArticleNumber", Value: tm('administration.article_group.fields.article_number')},
        { Alias: "Name", Value: tm('administration.article_group.fields.name') },
      ],
    });
    const searchArticle = () => {
      if (articleSearchString.value.length > 2 || articleSearchString.value.length == 0) {
        endpoint.value.searchArticle( articleGroup.form.Id, articleSearchString.value).then((res) => {
          searchResult.body = res.data;
        });
      }
		};
		const filteredSearchResult = computed(() => {
			return {
				head: searchResult.head,
				body: searchResult.body.filter(i => !articleGroup.form.Articles.map((art) => art.Id).includes(i.Id))
			}
		})
    const createArticle = (art) => {
      const { ArticleNumber, Id, Name } = art;
      return {
        ArticleNumber,
        Id,
        Name,
      };
    };
    const addArticle = (art) => {
      if (!articleGroup.form.Articles.map((i) => i.Id).includes(art.Id)) {
        articleGroup.form.Articles.push(createArticle(art));
      }
    };
    const removeArticle = (art) => {
      const index = articleGroup.form.Articles.findIndex((i) => i.Id == art.Id);
      articleGroup.form.Articles.splice(index, 1);
    };
    const filteredArticles = computed(() => {
      return articleListSearchString.value.length > 0
        ? articleGroup.form.Articles.filter((i) => i.Name.toLowerCase().match(articleListSearchString.value.toLowerCase()))
        : articleGroup.form.Articles;
    });

    const addActions = [
      {
        name: tm('general.button.add'),
        color: "green",
        action: (obj) => {
          addArticle(obj);
        },
      },
    ];

    const actions = [
      {
        name: tm('general.button.remove'),
        color: "red",
        action: (obj) => {
          removeArticle(obj);
        },
      },
    ];

    const save = () => {
	  isLoading.value = true;
      endpoint.value.update(data.value.Id, data.value).then((res) => {
		  notifications.push(
					{
						msg: tm('administration')['article_group']['update_success']
					},
					"success",
					false
		);
		  getArticleGroup(true)
      });
    };

    const log = (val) => console.log;

    watchEffect(() => {
      if ( articleGroup.form.Id && fileInput.value) {
        fileInput.value.value = null;
      }

    });

	watch(() => props.articleGroupId, (selection, prevSelection) => { 
		isLoading.value = true;
		getArticleGroup();
	})

    return {
	  isLoading,
	  articleGroup,
      addImage,
      save,
      hasImage,
      img,
      file,
      uploadImage,
      log,
      fileInput,
      searchArticle,
      articleSearchString,
	  searchResult,
	  filteredSearchResult,
      addActions,
      actions,
      filteredArticles,
      articleListSearchString,
	  deleteImage
    };
  },
};
</script>

<style lang="scss">
.kits-edit-article {
  &__image {
    width: 200px;
    display: block;
    margin-bottom: 0.5rem;
  }
}
.kits-article-lockers {
  display: flex;
	margin: 0.5rem 1rem 0rem 0rem;
	> div {
		margin-right: 2rem;
	}
  p {
    font-size: 0.8rem;
    text-decoration: uppercase;
  }
  &__list {
		display: flex;
		&--small {
			font-size: .6rem;
		}
  }
  span {
    padding: 0.35rem 1rem;
    border-radius: 4px;
		@include elevation(1);
    margin-right: 1.5rem;
		font-size: 0.8rem;
		align-items: center;
		display: flex;
		a {
			display: flex;
			font-size: 0.8rem;
			align-items: center;
		}
    i {
      color: $kits-steelGray;
      margin-right: 0.8rem;
    }
  }
}
</style>
