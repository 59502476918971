import { Sortable } from 'sortablejs'

export const sortable = {
	beforeMount(el, options) {
		const {disabled, callback} = options.value;
		let {sorting} = options.value;
		const isFunction = (value) => typeof value == 'function';
		Sortable.create(el, {
			disabled: disabled,
			multiDrag: true,
			multiDragKey: 'ctrl',
			selectedClass: "sortable-selected",
			onUpdate: sorting ? (e) => {
				let localSorting = [...sorting];
				if (e.items.length > 0) {
					let deleted = [];
					e.oldIndicies.map((item, i) => {
						deleted.push(...localSorting.splice(item.index, 1, null));
					})
					localSorting = localSorting.filter(i => !!i)
					localSorting.splice(e.newIndicies[0].index, 0, ...deleted);
				} else {
					const deleted = localSorting.splice(e.oldIndex, 1);
					localSorting.splice(e.newIndex, 0, deleted[0]);
				}
				sorting = localSorting;
				if (isFunction(callback)) callback(localSorting);
			} : null
		});
	}
}