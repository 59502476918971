<template>
	<input 
	class="checkBoxs" 
	ref="checkBox" 
	:checked="modelValue" 
	:id="id"
	type="checkbox" 
	@change="$emit('update:modelValue', $event.target.checked); $emit('input', $event.target.checked)" 
	/>
</template>

<script>
import { props } from './common';
export default {
  props: props,
	setup(props, { emit }) {
		const updateValue = (e) => {
			emit('input', {val: e.target.checked, srcEvent: e})
		}

		return {
			updateValue
		}
	}
}
</script>

<style lang="scss" scoped>
	.checkBoxs {
		-webkit-appearance: none;
		background-color: #D7DCDE;
		box-shadow: 0 1px 2px rgba(0,0,0,0.05), inset 0px -15px 10px -12px rgba(0,0,0,0.05);
		padding: 9px;
		border-radius: 5px;
		display: inline-block;
		position: relative;
    width: 1rem;
    height: 1rem;
		cursor: pointer;
		&:active, &:checked:active {
			box-shadow: 0 1px 2px rgba(0,0,0,0.05), inset 0px 1px 3px rgba(0,0,0,0.1);
		}
		&:checked {
			background-color: $kits-green;
			box-shadow: 0 1px 2px rgba(0,0,0,0.05), inset 0px -15px 10px -12px rgba(0,0,0,0.05), inset 15px 10px -12px rgba(255,255,255,0.1);
			color:white;
		}
		&:checked:after {
			font-family: Arial, Helvetica, sans-serif;
			content: '\2713';
			font-size: 12px;
			position: absolute;
			top: 0;
			left: 3px;
			color: white;
			transform: rotate(10deg);
			font-weight: bold;
		}
	}

</style>