<template>
	<div >
		<Navbar :items="routes" />
        <div class="kits-top-button-row">
            <router-link class="button button--linearGray" :to="'/admin/view/devices'"
                >Tillbaka till översikt</router-link>
        </div>
		<GlobXpanel title="Create device" style="max-width: 900px;">
            		<div class="ips-admin-fieldgroup">
						<GlobXpanel :noShadow="true" :tools="false" :title="'General'">
							<label style="font-size: .8rem; max-width: 800px; display: block;">
								Name*
								<InputDynamic
								:fullWidth="true"
								styling="margin-bottom: .5rem;"
								:type="'text'"
								:placeholder="'Name'"
								v-model="device.form.Name"
								/>
							</label>
							<label style="font-size: .8rem; max-width: 800px; display: block;">
								Description
								<InputDynamic
								:fullWidth="true"
								styling="margin-bottom: .5rem;"
								:type="'text'"
								:placeholder="'Description'"
								v-model="device.form.Description"
								/>
							</label>
							<label style="font-size: .8rem; max-width: 800px; display: block;">
								Location
								<InputDynamic
								:fullWidth="true"
								styling="margin-bottom: .5rem;"
								:type="'text'"
								:placeholder="'Location'"
								v-model="device.form.Location"
								/>
							</label>
						</GlobXpanel>
					</div>
                    <div class="ips-admin-fieldgroup">
						<GlobXpanel :noShadow="true" :tools="false" :title="'Function'">
							<label style="font-size: .8rem; max-width: 800px; display: block;">
								Server address
								<InputDynamic
								:fullWidth="true"
								styling="margin-bottom: .5rem;"
								:type="'text'"
								:placeholder="'Server address'"
								v-model="device.form.ServerAddress"
								/>
							</label>
						</GlobXpanel>
					</div>
                    <div class="ips-admin-fieldgroup">
						<GlobXpanel :noShadow="true" :tools="false" :title="'Wifi'">
							<label style="font-size: .8rem; max-width: 800px; display: block;">
								SSID
								<InputDynamic
								:fullWidth="true"
								styling="margin-bottom: .5rem;"
								:type="'text'"
								:placeholder="'SSID'"
								v-model="device.form.SSID"
								/>
							</label>
							<label style="font-size: .8rem; max-width: 800px; display: block;">
								Passcode
								<InputDynamic
								:fullWidth="true"
								styling="margin-bottom: .5rem;"
								:type="'text'"
								:placeholder="'Passcode'"
								v-model="device.form.Passcode"
								/>
							</label>
						</GlobXpanel>
					</div>
				    <div v-if="errorMessage">
                        <p class="text-danger mt-2" >{{errorMessage}}</p>
					</div>
				<button class="button" :disabled="!device.form.Name" @click="create">Skapa</button>
				<router-link class="button button--orange" :to="'/admin/view/devices'"
                >Avbryt</router-link>
		</GlobXpanel>
	</div>
</template>
<script>
import { onMounted, computed, watch, ref, reactive } from "vue";
import { useApi } from "@/plugins/api";
import { useRouter } from "vue-router";
import { useNotifications } from "@/plugins/notifications";
import Navbar from "@/views/Admin/Navbar.vue";
import { routes } from "@/views/Admin/config";


export default {
components: {
	Navbar
},
 setup() {

	const api = useApi();
	const router = useRouter();
	const notifications = useNotifications();

	const endpoint = computed(() => api.devices);
    const isLoading = ref(false);
	const errorMessage = ref(null);
    const device = reactive({
		form: {
			Name: "",
			Description: "",
			Location: "",
			ServerAddress: "",
			SSID: "",
			Passcode: "",
			Hostname: ""
		},
	});
		
	 onMounted(async() => {
       
	 });

	const create = () => {
		errorMessage.value = null;
		isLoading.value = true;
		endpoint.value.create(device.form).then(function(res){
           if(res.success){
				notifications.push(
					{
						title: "Device created",
						msg: `Device created successfully`
					},
					"success",
					false
					);
				router.push('/admin/view/devices')
		   } else {
				if (res.errors) {
					for (const item in res.errors) {
						errorMessage.value = res.errors[item][0];
						break;
					}
				}
		   }
		   isLoading.value = false;
		})
	 }

     	
    return {
		routes,
		device,
		isLoading,
		create,
		errorMessage
    };
  }
};
</script>

<style scoped>

	.disable-block{
		pointer-events: none;
		opacity: 0.4;
		position: relative;
	}
</style>
