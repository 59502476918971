<template>
  <div class="ips-notifications" v-show="show">
    <div class="ips-notifications__filter">
      <b>Notifikationer</b>
      <div class="ips-notifications__filter-buttons">
        <button
          class="ips-notifications__filter-button"
          :class="filters.includes('success') ? 'ips-notifications__filter-button--active' : ''"
          @click="addFilter('success')"
        >
          <i class="fas fa-check"></i>
        </button>
        <button
          class="ips-notifications__filter-button"
          :class="filters.includes('warning') ? 'ips-notifications__filter-button--active' : ''"
          @click="addFilter('warning')"
        >
          <i class="fas fa-exclamation-triangle"></i>
        </button>
        <button
          class="ips-notifications__filter-button"
          :class="filters.includes('error') ? 'ips-notifications__filter-button--active' : ''"
          @click="addFilter('error')"
        >
          <i class="fas fa-times"></i>
        </button>
      </div>
    </div>
    <div
      v-for="notification in filteredNotifications"
      :key="notification.id"
      class="ips-notifications__item"
      :class="notification.unread ? 'ips-notifications__item--unread' : ''"
      @click="markAsRead(notification.id)"
      @mouseleave="markAsRead(notification.id, notification.severity)"
    >
      <div class="ips-notifications__item-left">
        <i v-if="notification.severity" :class="`fas fa-${getIcon(notification.severity)}`"></i>
      </div>
      <div class="ips-notifications__item-right">
        <div class="ips-notifications__item-title">
          {{ notification.title }} <span>{{ formatTime(notification.time) }}</span>
        </div>
        <div class="ips-notifications__item-content" v-html="notification.msg"></div>
      </div>
      <div class="ips-notifications__item-after">
        <router-link v-if="notification.link" :to="notification.link"
          ><i class="fas fa-external-link-alt"></i
        ></router-link>
      </div>
    </div>
		<div class="ips-notifications__item" style="text-align: center; width: 100%;" v-if="filteredNotifications.length < 1">
			<div class="ips-notifications__item-right" style="width: 100%; max-width: none;">
        <div class="ips-notifications__item-title">
          Inga notifikationer
        </div>
        <div class="ips-notifications__item-content">Här var det tomt</div>
      </div>
		</div>
  </div>
</template>

<script>
import { useStore } from "vuex";
import { computed, ref } from "vue";
export default {
  props: ["show"],
  setup() {
    const store = useStore();
    const notifications = computed(() => store.state.NOTIFICATIONS);

    const formatTime = (time) => {
      return `${time.getHours()}:${time.getMinutes() < 10 ? "0" + time.getMinutes() : time.getMinutes()}`;
    };

    const markAsRead = (id, severity) => {
      if (!severity) {
        store.commit("READ_NOTIFICATION", id);
      } else if (severity != "error") {
        store.commit("READ_NOTIFICATION", id);
      }
    };

    const getIcon = (severity) => {
      let icon = "";
      switch (severity) {
        case "warning":
          icon = "exclamation-triangle orange";
          break;
        case "error":
          icon = "times red";
          break;
        case "success":
          icon = "check green";
          break;
      }
      return icon;
    };

    const filters = ref([]);
    const addFilter = (filter) => {
      if (filters.value.includes(filter)) {
        filters.value.splice(filters.value.indexOf(filter), 1);
      } else {
        filters.value.push(filter);
      }
    };

    const filteredNotifications = computed(() =>
      filters.value.length > 0
        ? notifications.value.filter((i) => filters.value.includes(i.severity))
        : notifications.value
    );

    return {
      notifications,
      formatTime,
      getIcon,
      markAsRead,
      filteredNotifications,
      addFilter,
      filters
    };
  }
};
</script>

<style lang="scss">
.ips-notifications {
  z-index: 9999;
  position: absolute;
  right: -10px;
  top: 40px;
  background-color: white;
  border-radius: 4px;
  @include elevation(4);
  width: rem-calc(400);
  max-height: 50vh;
  overflow-y: auto;
  @include scrollbar(4px);
  &__filter {
    position: sticky;
    z-index: 2;
    top: 0;
    display: flex;
    align-items: center;
    padding: 0.5rem 1rem;
    width: 100%;
    background-color: $kits-lightGray;
    color: $kits-green;
    &-buttons {
      margin-left: auto;
    }
    &-button {
      margin-left: 1rem;
      border: 2px solid $kits-green;
      color: $kits-green;
      border-radius: 100px;
      width: 1.6rem;
      height: 1.6rem;
      justify-content: center;
      align-items: center;
      display: inline-flex;
      i {
        font-size: rem-calc(13);
      }
      &--active {
        color: white;
        background-color: $kits-green;
      }
    }
  }
  &__item {
    transition: all 0.2s ease-in-out;
    display: flex;
    border-bottom: 1px solid $kits-lightGray;
    opacity: 0.6;
    cursor: pointer;
    &:hover {
      opacity: 1;
    }
    &:active {
      background-color: $kits-lightGray;
    }
    &-left {
      width: rem-calc(60);
      display: flex;
      justify-content: center;
      align-items: center;
      i {
        font-size: 1.2rem;
        &.red {
          color: $kits-red;
        }
        &.orange {
          color: $kits-orange;
        }
        &.green {
          color: $kits-green;
        }
      }
    }
    &-right {
      padding: 0.5rem;
      max-width: 70%;
    }
    &-after {
      padding: 0.5rem;
      margin-left: auto;
      width: 50px;
      display: flex;
      justify-content: center;
      align-items: center;
      a {
        &:hover {
          opacity: 0.8;
        }
      }
    }
    &-title {
      font-weight: 700;
      span {
        font-weight: 400;
        font-size: 0.8rem;
        color: $kits-gray;
        margin-left: 0.3rem;
      }
    }
    &-content {
    }
    &--unread {
      opacity: 1;
    }
  }
}
</style>
