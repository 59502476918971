<template>
<div>
	<div class="dynamic-type-input expanded-input" :class="!validates ? 'invalid' : ''" :style="styling"  v-if="type == 'virtualsearchmulti'">
		<div class="dynamic-type-input__validation-message">{{message}}</div>
		<GlobDropdownMultiselect
			@input="setValueFromProperty"
			@searchKey="(searchKey)  => $emit('searchKey', searchKey)"
			:activeIfEmptyData="activeIfEmptyData"
			:placeholder="placeholder"
			:disabled="disabled"
			:data="data"
			:ignoreValueWatch="ignoreValueWatch"
			@clear="handleClear"
			:value="localValue"
			:showSelectedItemInBottom="showSelectedItemInBottom"
		/>
	</div>
	<div class="dynamic-type-input expanded-input" :class="!validates ? 'invalid' : ''" :style="styling"  v-else-if="type == 'virtualsearch'">
		<div class="dynamic-type-input__validation-message">{{message}}</div>
		<GlobVirtualDropdownSearch
			@input="setValueFromProperty"
			@selectedItem ="(item) => $emit('selectedItem', item)"
			@searchKey="(searchKey)  => $emit('searchKey', searchKey)"
			@addTag = "(value)  => $emit('addTag', value)"
			:enableAddTag="enableAddTag"
			:makeFocus="makeFocus"
			:placeholder="placeholder"
			:hideInput="hideInput"
			:activeIfEmptyData="activeIfEmptyData"
			:inputSearchString="inputSearchString"
			:data="data"
			@clear="handleClear"
			:disabled="disabled"
			:value="localValue"
		/>
	</div>
</div>
</template>

<script>
export default {
	props: {
		type: {
			type: String,
			required: true
		},
		decimal: {
			type: [Number, String],
			required: false,
			default: undefined
		},
		data: {
			type: [Array, Promise, Object],
			required: false
		},
		value: {
			required: false
		},
		ignoreValueWatch: {
			required: false,
			type: Boolean,
			default: false,
		},
		disabled: {
			required: false
		},
		styling: {
			required: false
		},
		placeholder: {
			required: false
		},
		hidePlaceholder: {
			 required: false,
			 default : false
		},
		validations: {
			type: [Function, Boolean],
			required: false
		},
		blur: {
			type: [Function, Boolean],
			required: false,
		},
		showSelectedItemInBottom: {
			type: Boolean,
			required: false,
			default: false
		},
		hideInput: {
			type: Boolean,
			default: false
		},
		activeIfEmptyData: {
			type: Boolean,
			default: false
		},
		inputSearchString: {
			type: String,
			default: ''
		},
		showKeyboardIcon: {
			type: Boolean,
			default: false
		},
		inputKeyBoardType: {
			type: String,
			default: 'VirtualKeyboard'
		},
		makeFocus: {
			type: Boolean,
			default: false
		},
		enableAddTag: {
			type: Boolean,
			default: false
		},
		preventDecimal: {
			type: Boolean,
			default: false
		},
		changeInputFocus :{
			type: String,
			default: 'not'
		},
		showCustomIcon :{
			type: String,
			default: 'not'
		},
		showHint:{
			type: String,
			default: null
		},
	},
	data() {
		return {
			localValue: this.value,
			validates: true,
			message: ''
		}
	},
	methods: {
		focusInput(e){
			 this.$refs.input.focus();
		},
		focus(){
			this.$emit('focus')
		},
		handleBlur(e) {
			this.$emit('blur', this.$refs.input.value)
			if (this.blur) {
				this.blur();
			}
			e.preventDefault();
		},
		handleClear() {
			this.$emit('clear', this.localValue)
		},
		isInputTag() {
			return this.type != 'select'
		},
		setValue() {
			
			this.$emit('input', this.$refs.input.value)
			if (this.data && this.data.item) {
				this.$emit('changed', this.data.item)
			}
			if (this.validations) {
				const validation = this.$utils.doValidations(this.validations(this.$refs.input.value))
				this.validates = validation.isValid;
				this.message = validation.message;
			}
		},
		async calculate(e) {

			if(this.decimal != undefined){
				const currentValue = this.$refs.input.value;
				//Prevent user to type decimal seperator
				if((e.key==='.' || e.key===',') && this.decimal == 0){
					e.preventDefault();
				}

				if(this.decimal > 0 && isFinite(e.key)){

					let decimalPart = 0;
					if (currentValue.includes('.')) {
						 decimalPart = currentValue.split('.')[1].length;
					}

					if (currentValue.includes(',')) {
						 decimalPart = currentValue.split(',')[1].length;
					}

					if(decimalPart >= this.decimal){
						e.preventDefault();
					}

				}

			}
			// if (e.code == 'Enter') {
			// 	const value = this.$refs.input.value
			// 	if (/^(\d*[\.\,]?\d*)([\.\,\*\/\+\-]?)(\d*[\.\,]?\d*)$/.test(value)) {
			// 		const match = value.match(/^(\d*[\.\,]?\d*)([\.\,\*\/\+\-]?)(\d*[\.\,]?\d*)$/);
			// 		const first = parseFloat(match[1]);
			// 		const method = match[2];
			// 		const last = parseFloat(match[3]);
			// 		const func = this.runCalc[method];
			// 		if (this.validations) {
			// 			const validation = this.$utils.doValidations(this.validations(func(first, last)+''))
			// 			this.validates = validation.isValid;
			// 			this.message = validation.message;
			// 		}
			// 		this.$emit('input', func(first, last)+'')
			// 		return;
			// 	}
			// }
			if(e.code == 'Enter' || (e.code == 'MetaLeft' && e.ctrlKey)) {
				this.$emit('input', this.$refs.input.value)
				this.$refs.input.blur();
				this.$emit('jumpToNextLine', this.$refs.input.value)
				e.preventDefault();
			}
		},

		setValueFromProperty(prop) {
			this.$emit('update:modelValue', prop)
			this.$emit('input', prop)
		},
		setValueSelect(e) {
			this.$emit('input', this.localValue)
		},
		generateId(obj) {
			const id = Math.round((new Date()).getTime() / 1000);
			return obj.Id ? obj : {...obj, tempId: id}
		},
	},
	computed: {
		computedValue: {
			get() {
				return this.localValue
			},
			set(newValue) {
				this.$emit('input', this.value)
				this.localValue = this.value
			}
		},
		runCalc() {
			return {
				'+': (first, last) => first+last,
				'-': (first, last) => first-last,
				'*': (first, last) => first*last,
				'/': (first, last) => first/last,
			}
		},
	},
	watch: {
		value(val) {
			if (JSON.stringify(this.localValue) != JSON.stringify(val) && (this.localValue !== undefined || val !== '') && !this.ignoreValueWatch) {
			   this.localValue = val
			}
		},
		localValue(newVal) {
			this.$emit('input', this.localValue)
		},
		validates() {
			this.$emit('validateChange', this.validates)
		},
	},
	mounted(){
		if(this.changeInputFocus == 'focus'){
			this.$nextTick(() => {
				if(this.$refs.input){
					this.$refs.input.focus();
				}
			})
		}

		if(this.makeFocus){
			this.$refs.input.focus();
		}
	}
}
</script>

<style lang="scss">
	.table-editor-input, select {
		padding-left: .3rem;
		display: table-cell;
		// border: 1px solid lighten($ma-gray, 10%);
		.model-select {
			&.icon {
				top: initial !important;
			}
			input {
				padding-top: 0 !important;
			}
		}

	}
	.dynamic-type-input {
		position: relative;

		.custom-icon{
			font-size: 25px;
			margin-left: 5px;
			cursor: pointer;
			i{
				vertical-align: bottom!important;
			}
		}

		.hint{
			position: absolute;
			top: 48px;
			font-style: oblique;
			font-size: 12px !important;
			line-height: 16px !important;
		}

		&__validation-message {
			display: none;
			position: absolute;
			top: -2.5rem;
			background-color: white;
			z-index:99;
			padding: .3rem .3rem;
			border: 1px solid red;
			border-radius: 5px;
			&:after {
				content: '';
				display: block;
				position: absolute;
				bottom: -.35rem;
				left: .5rem;
				width: .6rem;
				height: .6rem;
				transform: rotate(45deg);
				border-right: 1px solid red;
				border-bottom: 1px solid red;
				background-color: white;

			}
		}
		.expanded-input {
			&__content {
				left: 0;
				right: 0;
				position: absolute;
				height: 100%;
				max-height: 200px;
				overflow-y: auto;
				background-color: white;
			}
		}
		&.invalid {
			.dynamic-type-input__validation-message {
				display: block;
			}
			input, select {
				border-color: red;
			}
		}
		input, select {
			padding: .5rem 1rem;
		}
		&__keyboard-icon{
			margin: 0;
			border-radius: 0;
			padding: revert;
			background-color: #c9c9c9;
			i{
				cursor: pointer;
				color: #73879C;
			}
		}
		&__keyboard-icon:hover{
			background-color: #c9c9c9;
		}

		textarea, textarea:focus{
			border: 1px solid #c9c9c9;
			outline: none;
			padding: 0.5rem 1rem;
		}

		input{
			border: 1px solid #c9c9c9;
		}
		input[type=datetime-local] {
			width: 100%;
		}

		select:disabled{
			opacity: .5;
			background-color: #E6E9ED;
		}
	}

	.dynamic-type-input__keyboard-icon.textarea-icon{
		position: absolute;
		right: 0;
		bottom: 0;
		height: 37.12px;
	}

	.dynamic-type-input__keyboard-icon.background-textarea-icon{
		visibility : hidden;
	}
</style>