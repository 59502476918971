<template>
	<GlobXpanel :noShadow="true"  :avoidHR="true" :isCRUD="true">
		<GlobSpinner :isLoading="isLoading"/>
		<GlobXpanel :noShadow="true" :tools="false" :avoidHR="true">
			<label style="font-size: .8rem; max-width: 800px; display: block;">
				{{$t('administration.article_group.fields.name')}}*
				<InputDynamic
				:fullWidth="true"
				styling="margin-bottom: .5rem;"
				:type="'text'"
				:placeholder="$t('administration.article_group.fields.name')"
				v-model="articleGroup.form.Name"
				/>
			</label>

			<label style="font-size: .8rem; max-width: 800px; display: block;">
				{{$t('administration.article_group.fields.description')}}
				<InputDynamic
				:fullWidth="true"
				styling="margin-bottom: .5rem;"
				:type="'text'"
				:placeholder="$t('administration.article_group.fields.description')"
				v-model="articleGroup.form.Description"
				/>
			</label>

			<div v-if="errorMessage">
				<span class="text-danger" >{{errorMessage}}</span>
			</div>
			<button @click="save" class="button kis-mt-4" :disabled="!articleGroup.form.Name">{{$t('general.button.save')}}</button>
		</GlobXpanel>
	</GlobXpanel>

</template>

<script>
import {onMounted, reactive, ref, computed, watchEffect } from "vue";
import { useI18n } from 'vue-i18n';
import { useApi } from "@/plugins/api";
import {useRouter } from "vue-router";
import { useNotifications } from "@/plugins/notifications";

export default {
  setup(props, {emit}) {
    const { tm } = useI18n();
	const endpoint = computed(() => api.articlegroups);
	const api = useApi();
	const router = useRouter();

	const notifications = useNotifications();
	const errorMessage = ref(null);
	const isLoading = ref(false);

	const articleGroup = reactive({
		form: {
			Name: "",
			Description: ""
		},
	});

    const save = () => {
		errorMessage.value = null;
		isLoading.value = true;
		endpoint.value.create({Name: articleGroup.form.Name, Description: articleGroup.form.Description}).then((res) => {
			isLoading.value = false;
			notifications.push(
				{
					msg: tm('administration')['article_group']['create_success']
				},
				"success",
				false
			);
			emit('created');
		});
    };

    return {
      save,
	  errorMessage,
	  articleGroup,
	  isLoading
    };
  },
};
</script>
