<template>
  <div class="ips-admin-fieldgroup">
    <GlobXpanel :noShadow="true" :tools="false" :title="group.name" v-if="data">
      <label v-for="input in group.fields" :key="'gi-' + input.key" style="font-size: .8rem; max-width: 800px; display: block;">
        {{ content.getFieldName($route.params.slug, input.key) }}
        <InputDynamic
					:fullWidth="true"
          styling="margin-bottom: .5rem;"
          :type="input.type"
          :data="input.data ? input.data() : null"
          :placeholder="content.getFieldName($route.params.slug, input.key)"
          v-model="dataObject[input.key]"
          :disabled="isEditPage && input.disableEdit"
          @input="updateChange()"
        />
      </label>
    </GlobXpanel>
  </div>
</template>

<script>
import { content } from "../core";
import { ref, computed, watchEffect, onMounted } from "vue";
import { useApi } from "@/plugins/api";
import { useRouter } from "vue-router";
export default {
  props: ["group", "data", "isEditPage"],
  emits: ["input"],
  setup(props, { emit }) {
    const dataObject = ref({});
    const api = useApi();
    const router = useRouter();

    const init = () => {
      dataObject.value = content.getDataObjectByFieldGroup(props.group);
    };

    const slug = computed(() => router.currentRoute.value.params.slug);
    const endpoint = computed(() => api[slug.value]);
    const selectedContent = computed(() => content.getTypeBySlug(slug.value));

    watchEffect((data) => {
      Object.keys(dataObject.value).forEach((item) => {
        dataObject.value[item] = props.data[item];
      });
    });

    const updateChange = () => {
      emit("input", dataObject.value);
    };

    onMounted(() => {
      init();
    });

    return {
      dataObject,
      content,
      updateChange
    };
  }
};
</script>

<style lang="scss">
.ips-admin-fieldgroup {
  margin: 1rem 0;
}
</style>
