<template>
    <div>
        <GlobXpanel :title="'Billing'">
            <div class="kits-admin-cruds">
                <!-- Price plan -->
                <template v-if="['superadmin', 'admin'].includes(userRole)">
                    <router-link :to="`/billing/price-plans`" class="kits-admin-cruds__item">
                        <div class="kits-admin-cruds__item-header">
                            <div class="kits-admin-cruds__item-icon">
                                <i class="fas fa-dollar-sign"></i>
                            </div>
                        </div>
                        <div class="kits-admin-cruds__item-content">
                            <h3>{{$t('billing.menus.price_plan')}}</h3>
                        </div>
                    </router-link>
                </template>

                 <!-- Discounts -->
                <template v-if="['superadmin', 'admin'].includes(userRole)">
                    <router-link :to="'/billing/discounts'" class="kits-admin-cruds__item">
                        <div class="kits-admin-cruds__item-header">
                            <div class="kits-admin-cruds__item-icon">
                                <i class="fas fa-dollar-sign"></i>
                            </div>
                        </div>
                        <div class="kits-admin-cruds__item-content">
                            <h3>{{$t('billing.menus.discounts')}}</h3>
                        </div>
                    </router-link>
                </template>

                 <!-- Statements -->
                <template v-if="['superadmin', 'admin'].includes(userRole)">
                    <router-link :to="'/billing/statements'" class="kits-admin-cruds__item">
                        <div class="kits-admin-cruds__item-header">
                            <div class="kits-admin-cruds__item-icon">
                                <i class="fas fa-dollar-sign"></i>
                            </div>
                        </div>
                        <div class="kits-admin-cruds__item-content">
                            <h3>{{$t('billing.menus.statements')}}</h3>
                        </div>
                    </router-link>
                </template>

                <!-- Lockers -->
                    <template v-if="['superadmin', 'admin'].includes(userRole)">
                        <router-link :to="'/billing/lockers'" class="kits-admin-cruds__item">
                            <div class="kits-admin-cruds__item-header">
                                <div class="kits-admin-cruds__item-icon">
                                    <i class="fas fa-door-closed"></i>
                                </div>
                            </div>
                            <div class="kits-admin-cruds__item-content">
                                <h3>{{ $t('billing.menus.lockers') }}</h3>
                            </div>
                        </router-link>
                    </template>
            </div>
        </GlobXpanel>
    </div>
</template>

<script>
import { useStore } from 'vuex';

export default {
 setup() {
    
    const store = useStore();
    const userRole = store.getters.GET_USER.UserRole;
    return {
        userRole
    }
 }
}

</script>
