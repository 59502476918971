<template>
  <div>
    <GlobSpinner :isLoading="isLoading"></GlobSpinner>
  <button v-if="userCanHandleOrders()" @click="setArticleModal(true)" class="button">Lägg till artiklar</button>
  <GlobTable
    :data="articles"
    :enableEditing="enableEditing"
    :fields="fields"
    @change-item="(item) => updateArticle(item)"
    :actions="actions"
  />
 </div>
  <GlobModal :show="articleModal" :width="30" :title="'Lägg till artiklar'" @close="setArticleModal(false)">
    <InputText :fullWidth="true" placeholder="Sök artikel" v-model="articleSearchString" @input="searchArticle" />
    <GlobTable :data="searchResult" :actions="addActions" style="height: 30vh" />
  </GlobModal>

  <GlobModal :show="articleStatModal" :isLoaded="articleStatLoaded" :width="50" :title="'Artikeltransaktioner'" @close="setArticleStatModal(false)">
    <ChartLine
      :name="'Artikeltransaktioner'"
			:xPanel="false"
      :axisX="'date'"
      :axisY="'value'"
      :data="articleTransactionData"
    />
  </GlobModal>
  <GlobLoader v-if="!articleStatLoaded"/>

</template>

<script>
import { useRouter } from "vue-router";
import { reactive, onMounted, ref, shallowRef, computed } from "vue";
import { useApi } from "@/plugins/api";
import { useNotifications } from "@/plugins/notifications";
import { usePermissions } from "@/plugins/permissions";
export default {
  props: {
	  userRole: {
		  type : String,
		  default: null
		},
		allowEnduserToSetPlacement: {
			type: Boolean,
			default: true
	  }
  },
  setup(props) {
    const router = useRouter();
    const api = useApi();
    const isLoading = ref(false);
    const id = ref(router.currentRoute.value.params.id);
    const notifications = useNotifications();
		const { userCanHandleOrders } = usePermissions();

    const getData = async () => {
      isLoading.value = true;
      await api.lockers.getArticles(id.value).then((res) => {
        articles.body = res.data;
        isLoading.value = false;
      });
    };

    const updateArticle = (item) => {
      api.lockers.updateArticle(id.value, {
        data: [item]
      });
    };
    const deleteArticle = (item) => {
      api.lockers.deleteArticle(id.value, item.LockerArticleId).then((res) => {
        getData();
        notifications.push(
          {
            title: "Artikel borttagen",
            msg: `Tog bort <b>&#160;${item.name}</b>`
          },
          "success",
          false
        );
      });
    };

    const articles = reactive({
      body: [],
      head: [
        { Alias: "ArticleNumber", Value: "Art.nr" },
        { Alias: "name", Value: "Artikel" },
        { Alias: "CurrentVolume", Value: "Antal" },
        { Alias: "PlaceOrderAmount", Value: "Beställningspunkt" },
        { Alias: "Shelf", Value: "Hylla" },
        { Alias: "Placement", Value: "Plats" },
        { Alias: "MaxStock", Value: "Maxlager" }
      ]
    });

	  const fields = computed(() => {
		if (props.userRole == 'reseller') {
			return [
					{ key: "CurrentVolume", type: "number" },
					{ key: "PlaceOrderAmount", type: "number" },
					{ key: "Shelf", type: "number" },
					{ key: "Placement", type: "text" },
					{ key: "MaxStock", type: "number" }
				]
		}else if (props.allowEnduserToSetPlacement) {
			return [
					{ key: "Placement", type: "number" },
				]
		}

		  return [];
	})

	  const enableEditing = computed(() => {
		  if (props.userRole == 'reseller' || props.allowEnduserToSetPlacement)
		  {
			  return true;
		  }

		  return false;
	  });

    const articleStatModal = ref(false);
    const articleStatLoaded = ref(true);
    const setArticleStatModal = (bool) => {
      articleStatModal.value = bool;
    };
    const articleTransactionData = ref([]);

    const actions = [
      ...(userCanHandleOrders() ? [{
        name: "Radera",
        color: "red",
        requirements: {
          consequence: (obj) => `Är du säker på att du vill radera ${obj.name} (${obj.ArticleNumber})?`
        },
        action: (obj) => {
          deleteArticle(obj);
        }
      }] : []),
      {
        name: "Statistik",
        color: "green",
        action: (obj) => {
					articleStatLoaded.value = false;
          api.analytics.getArticleTransactions(id.value, obj.LockerArticleId).then((res) => {
            articleTransactionData.value = [{
              name: obj.name,
              data: res.data.map((i) => ({
                date: new Date(i.TransactionDate),
                value: i.TransactionAmount
              }))
            }];
            setArticleStatModal(true);
						console.log(articleTransactionData.value);
						return true;
          }).then(res => {
						articleStatLoaded.value = true;
					});
        }
      }
    ];

    const addActions = [
      {
        name: "Lägg till",
        color: "green",
        action: (obj) => {
          addArticle(obj);
        }
      }
    ];

    const articleSearchString = ref("");
    const articleModal = ref(false);
    const searchResult = reactive({
      body: [],
      head: [
        { Alias: "ArticleNumber", Value: "Art.nr" },
        { Alias: "Name", Value: "Namn" }
      ]
    });
    const setArticleModal = (bool) => {
      articleModal.value = bool;
    };
    const searchArticle = () => {
      api.lockers.searchArticle(id.value, articleSearchString.value).then((res) => {
        searchResult.body = res.data;
      });
    };

    const addArticle = (article) => {
      api.lockers.addArticle(id.value, article.Id).then((res) => {
        getData();
        searchArticle();
        notifications.push(
          {
            title: "Artikel tillagd",
            msg: `Lade till <b>&#160;${article.Name}</b>`
          },
          "success",
          false
        );
      });
    };

    const log = (val) => console.log(val);

    onMounted(() => {
      getData();
      searchArticle();
    });

    return {
      articles,
      updateArticle,
      fields,
      actions,
      articleModal,
      setArticleModal,
      articleSearchString,
      searchArticle,
      searchResult,
      addActions,
      articleStatModal,
      setArticleStatModal,
			articleTransactionData,
			articleStatLoaded,
      log,
      userCanHandleOrders,
		isLoading,
	  enableEditing
    };
  }
};
</script>
