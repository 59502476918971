<template>
	<Suspense>
		<template #default>
			<ViewNew />
		</template>
		<template #fallback>
			<GlobLoader/>
		</template>
	</Suspense>
</template>

<script>
import ViewNew from '../ViewNew';
export default {
	components: {
		ViewNew
	}
}
</script>