<template>
    <div>
        <GlobRow>
            <GlobSpinner :isLoading="isLoading"></GlobSpinner>
            <GlobCol :medium="12">
                <GlobXpanel :title="$t('billing.locker.lockers')">
                    <InputText type="text" v-model="searchString" :placeholder="$t('general.search')"
                        :style="'    margin: 1rem 0rem 1rem 0rem; padding: .4rem 1rem'" />
                    <GlobTable 
                        :enableRenderFunctions="true" 
                        :enableEditing="true" 
                        :fields="fields"
                        @change-item="(item) => updateItem(item)" :maxHeight="50" :data="{
                            head: lockerListHead,
                            body: filteredLockerList
                        }" />
                </GlobXpanel>
            </GlobCol>
        </GlobRow>
    </div>
</template>

<script>
import { onMounted, computed, ref } from "vue";
import { useApi } from "@/plugins/api";
import { useRouter } from "vue-router";
import { useI18n } from 'vue-i18n'
import globSpinner from '../../../components/globals/glob-spinner.vue';

export default {
    components: { globSpinner },

    setup() {

        const { tm } = useI18n()
        const api = useApi();
        const router = useRouter();
        const endpoint = computed(() => api.billingLockers);
        const isLoading = ref(false);

        const loaded = ref(false);
        const searchString = ref('');
        const lockerList = ref([]);

        const fields = [
            { key: "BillingStartDate", type: "date" },
            { key: "Billable", type: "checkbox" },
        ];
        const lockerListHead = computed(() =>
            [{
                Alias: "Reseller",
                Value: tm('billing')['locker']['fields']['reseller']
            },
            {
                Alias: "Customer",
                Value: tm('billing')['locker']['fields']['customer']
            },
            {
                Alias: "Locker",
                Value: tm('billing')['locker']['fields']['locker']
            },
            {
                Alias: "Billable",
                Value: tm('billing')['locker']['fields']['billable']
            },
            {
                Alias: "BillingStartDate",
                Value: tm('billing')['locker']['fields']['start_date']
            }
            ]

        );


        onMounted(async () => {
            loadLockers();
        });

        const loadLockers = async () => {
            endpoint.value.get().then(function (res) {
                lockerList.value = res.lockers;
                isLoading.value = false;
            })
        }

        const updateItem = (item) => {
            isLoading.value = true;
            endpoint.value.update(item.Id, {
                Billable: item.Billable,
                BillingStartDate: item.BillingStartDate
            }).then(function (res) {
                isLoading.value = false;
            });

        }

        const filteredLockerList = computed(() => {
            return searchString.value.length > 0
                ? lockerList.value.filter((item) => {
                    const searchStringLower = searchString.value.toLowerCase();
                    return (
                        (item.Reseller && item.Reseller.toLowerCase().match(searchStringLower)) ||
                        (item.Customer && item.Customer.toLowerCase().match(searchStringLower)) ||
                        (item.Locker && item.Locker.toLowerCase().match(searchStringLower))
                    );
                })
                : lockerList.value;
        });

        return {
            //Methods
            loadLockers,
            //Variables
            endpoint,
            filteredLockerList,
            lockerList,
            lockerListHead,
            searchString,
            loaded,
            fields,
            updateItem,
            isLoading
        };
    }
};
</script>