
import Article from "@/views/crud/Article/Articles.vue";
import CreateArticle from "@/views/crud/Article/Create.vue";
import EditArticle from "@/views/crud/Article/Edit.vue";

export const article = [{
  path: "/admin/view/articles",
  component: Article,
  isCRUD: true,
  name: "Article",
  nav: {
    name: "Admin",
    icon: "sliders-h"
  },
  meta: {
    noPadding: true,
    access: ['superadmin', 'admin', 'reseller'],
    breadcrumbs: [{ name: "Admin", link: "/admin" }, { name: "Översikt" }]
  },
},
{
  path: "/admin/create/article",
  component: CreateArticle,
  isCRUD: true,
  name: "createArticle",
  nav: {
    name: "Admin",
    icon: "sliders-h"
  },
  meta: {
    noPadding: true,
    access: ['superadmin', 'admin', 'reseller'],
    breadcrumbs: [{ name: "Admin", link: "/admin" }, { name: "Skapa" }]
  }
},
{
  path: "/admin/edit/article/:id?",
  component: EditArticle,
  isCRUD: true,
  name: "editArticle",
  nav: {
    name: "Admin",
    icon: "sliders-h"
  },
  meta: {
    noPadding: true,
    access: ['superadmin', 'admin', 'reseller'],
    breadcrumbs: [{ name: "Admin", link: "/admin" }, { name: "Redigera" }]
  }
}]
