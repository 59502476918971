<template>
  <teleport to="#modals">
    <div class="ips-modal" :class="isCRUD ? 'ips-modal--crud' : ''"  v-show="show">
      <div ref="popup" class="ips-modal__popup" :style="width ? `width: ${width}${widthInPx ? 'px': 'vw'};` : ''">
        <div class="ips-modal__header">
          <h3>{{ title }}</h3>
          <button @click="handleClose" class="ips-modal__close"><i class="fas fa-times"></i></button>
        </div>
        <div class="ips-modal__content">
          <slot v-if="isLoaded" />
          <div class="ips-modal__loader" v-else>
            Laddar
          </div>
        </div>
      </div>
      <div class="ips-modal__backdrop"></div>
    </div>
  </teleport>
</template>

<script>
export default {
  emits: ["close"],
  props: {
    show: {
      type: Boolean,
      required: false
    },
    width: {
      type: Number,
      required: false
    },
    widthInPx: {
      type: Number,
      required: false
    },
    isLoaded: {
      type: Boolean,
      required: false,
      default: true
    },
    title: {
      type: String,
      required: false
    },
    isCRUD: {
      type: Boolean,
      required: false
    },
  },
  setup(props, { emit }) {
    const handleClose = () => {
      emit("close");
    };

    return {
      handleClose
    };
  }
};
</script>

<style lang="scss" scoped>
.ips-modal {
  &__popup {
    position: fixed;
    background-color: white;
    border-radius: 3px;
    z-index: 12;
    margin: 1rem 0;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    font-size: 0.8rem;
    width: 80vw;
    max-width: 1900px;
    max-height: 90vh;
    overflow-y: auto;
    overflow-y: auto;
  }
  &__header {
    border-top-left-radius: 3px;
    border-top-right-radius: 3px;
    background-color: $kits-steelGray;
    padding: 0.25rem 0.25rem 0.25rem 0.5rem;
    display: flex;
    justify-content: space-between;
    color: white;
    h3 {
      margin: 0;
    }
	}
	&__content {
		padding: .5rem;
	}
  &__close {
    right: 0.2rem;
    top: 0.2rem;
    background-color: transparent;
    border: none;
    padding: 0.2rem 0.5rem;
    color: white;
    cursor: pointer;
    &:hover {
      background-color: $kits-red;
    }
    &:focus {
      outline: 0;
    }
  }
  &__backdrop {
    width: 100%;
    height: 100%;
    position: fixed;
    display: block;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.5);
    z-index: 11;
  }
  &__loader {
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 1.5rem;
    font-weight: bold;
    height: 100px;
    animation: blink 0.5s ease-in-out infinite;
  }

  &--crud{
    .ips-modal__content {
      padding: 1.5rem;
    }
  }
}
@keyframes blink {
  from {
    opacity: 0.7;
  }
  to {
    opacity: 1;
  }
}
</style>
