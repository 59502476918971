/* Standard routes */
export const standard = [
	'Login',
	'Home',
	'Billing',
	'ResellerBilling'
]

/* Specialized modules */
export const modules = [
	'Planing',
	'Materialplanning',
	'Storage',
	'Admin'
]