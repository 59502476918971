import CreateLocker from "@/views/crud/Locker/Create.vue";
import EditLocker from "@/views/crud/Locker/Edit.vue";
import Locker from "@/views/crud/Locker/Lockers.vue";
import BillingLocker from '@/views/Billing/Locker/index.vue';

export const locker = [
  // Locker CRUD routes start
  {
    path: "/admin/view/lockers-v2",
    component: Locker,
    isCRUD: true,
    name: "lockerV2",
    nav: {
      name: "Admin",
      icon: "sliders-h"
    },
    meta: {
      noPadding: true,
      access: ['superadmin', 'admin', 'reseller'],
      breadcrumbs: [
        { name: "general.admin", link: "/admin" },
        { name: "administration.locker.breadcrumb.overview" }
      ],
      languageSupport: true
    },
  },
  {
    path: "/admin/create/locker-v2",
    component: CreateLocker,
    isCRUD: true,
    name: "createLockerV2",
    nav: {
      name: "Admin",
      icon: "sliders-h"
    },
    meta: {
      noPadding: true,
      access: ['superadmin', 'admin', 'reseller'],
      breadcrumbs: [
        { name: "general.admin", link: "/admin" },
        { name: "administration.locker.breadcrumb.overview", link: "/admin/view/lockers-v2" },
        { name: "administration.locker.breadcrumb.create" }
      ],
      languageSupport: true
    }
  },
  {
    path: "/admin/edit/locker-v2/:id?",
    component: EditLocker,
    isCRUD: true,
    name: "editLockerV2",
    nav: {
      name: "Admin",
      icon: "sliders-h"
    },
    meta: {
      noPadding: true,
      access: ['superadmin', 'admin', 'reseller'],
      breadcrumbs: [
        { name: "general.admin", link: "/admin" },
        { name: "administration.locker.breadcrumb.overview", link: "/admin/view/lockers-v2" },
        { name: "administration.locker.breadcrumb.edit" }
      ],
      languageSupport: true
    }
  },
  // Locker CRUD routes end

  // Billing lockers routes start
  {
    name: "Locker",
    isCRUD: true,
    path: "/billing/lockers",
    component: BillingLocker,
    meta: {
      noPadding: true,
      breadcrumbs: [
        { name: "general.billing", link: "/billing" },
        { name: "billing.price_plan.breadcrumb.overview" }
      ],
      languageSupport: true,
      access: ['superadmin', 'admin']
    },
  }
  // Billing lockers routes end
]
