<template>
  <GlobRow>
    <GlobCol medium="12">
      <GlobXpanel title="Notification Center Testing">
        <button class="button" @click="pushNotification(1)">Success</button>
        <button class="button button--orange" @click="pushNotification(2)">Warning</button>
        <button class="button button--red" @click="pushNotification(3)">Error</button>
      </GlobXpanel>
    </GlobCol>
  </GlobRow>
</template>

<script>
import { useNotifications } from "@/plugins/notifications";
export default {
  setup() {
    const notifications = useNotifications();
    const pushNotification = (num) => {
      if (num == 1) {
        notifications.push(
          {
            title: "Oh yes.",
            msg: "Cake is confirmed, i repeat: cake has been confirmed."
          },
          "success",
          "/"
        );
      }
      if (num == 2) {
        notifications.push(
          {
            title: "Houston",
            msg: "Ground control to mayor Tom"
          },
          "warning",
          "/"
        );
      }
      if (num == 3) {
        notifications.push(
          {
            title: "Dammit",
            msg: "Abort! The cake has been eaten."
          },
          "error"
        );
      }
    };

    return {
      pushNotification
    };
  }
};
</script>
