import PricePlan from '@/views/Billing/PricePlan/index.vue';
import CreatePricePlan from '@/views/Billing/PricePlan/create.vue';
import EditPricePlan from '@/views/Billing/PricePlan/edit.vue';

export const pricePlan = [
  {
	name: "PricePlan",
    isCRUD: true,
	path: "/billing/price-plans",
	component: PricePlan,
    meta: {
        noPadding: true,
        breadcrumbs: [{ name: "general.billing", link: "/billing" }, { name: "billing.price_plan.breadcrumb.overview" }] ,
        languageSupport: true,
        access: ['superadmin', 'admin']
    },
   },
{
	name: "CreatePricePlan",
    isCRUD: true,
	path: "/billing/create/price-plan",
	component: CreatePricePlan,
    meta: {
        noPadding: true,
        breadcrumbs: [  { name: "general.billing", link: "/billing" }, 
                        { name: "billing.price_plan.breadcrumb.overview", link: "/billing/price-plans" },
                        { name: "billing.price_plan.breadcrumb.create" }] ,
        languageSupport: true,
        access: ['superadmin', 'admin']
    },
},
{
	name: "EditPricePlan",
    isCRUD: true,
	path: "/billing/edit/price-plan/:id",
	component: EditPricePlan,
    meta: {
        noPadding: true,
        breadcrumbs: [  { name: "general.billing", link: "/billing" }, 
                        { name: "billing.price_plan.breadcrumb.overview", link: "/billing/price-plans" },
                        { name: "billing.price_plan.breadcrumb.edit" }] ,
        languageSupport: true,
        access: ['superadmin', 'admin']
    },
}];