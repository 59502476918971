<template>
	<GlobRow>
    
		<GlobCol medium="12">
      <ChartLine
        :name="'Linechartdate'"
        :axisX="'date'"
        :axisY="'value'"
        :data="[
          {
            name: 'ARM',
            data: [
              {
                date: new Date().setDate(1),
                value: 331
              },
              {
                date: new Date().setDate(2),
                value: 232
              },
              {
                date: new Date().setDate(3),
                value: 434
              },
              {
                date: new Date().setDate(4),
                value: 131
              }
            ]
          },
          {
            name: 'INV',
            data: [
              {
                date: new Date().setDate(1),
                value: 131
              },
              {
                date: new Date().setDate(2),
                value: 332
              },
              {
                date: new Date().setDate(3),
                value: 234
              },
              {
                date: new Date().setDate(4),
                value: 431
              }
            ]
          }
        ]"
      />
    </GlobCol>
    <GlobCol medium="12">
      <ChartBar
        :name="'Visits'"
        :axisX="'produkt'"
        :axisY="'antal'"
        :data="[
          {
            antal: 145,
            produkt: 'GRB'
          },
          {
            antal: 121,
            produkt: 'INV'
          },
          {
            antal: 51,
            produkt: 'ARM'
          },
          {
            antal: 152,
            produkt: 'BTG'
          }
        ]"
      />
    </GlobCol>
		</GlobRow>
</template>