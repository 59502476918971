<template>
    <GlobLoader v-if="isLoading"/>
	<div v-else class="Kits-crud">
                <div class="kits-top-button-row">
                    <router-link class="button button--linearGray" :to="{name: 'PricePlan'}"
                        >{{$t('general.button.back_to_overview')}}</router-link>
                </div>
			<GlobRow style="margin-top: 1rem">
					<GlobXpanel :title="$t('billing.price_plan.edit_price_plan')" style="max-width: 800px;">
						<div class="ips-admin-fieldgroup">
							<GlobXpanel :noShadow="true" :tools="false" :title="$t('billing.price_plan.crud_section.reseller')">
							<GlobRow>
								<GlobCol :medium="4">
									<label class="crud-label">
												{{$t('billing.price_plan.fields.reseller')}}*
									<InputSelect :fullWidth="true" v-model="pricePlan.form.ResellerCustomerId" :placeholder="$t('billing.price_plan.fields.select_reseller')" :data="resellers" />
									</label>
								</GlobCol>
								</GlobRow>
							</GlobXpanel>
						</div>
						<div class="ips-admin-fieldgroup">
							<GlobXpanel :noShadow="true" :tools="false" :title="$t('billing.price_plan.crud_section.general')">
								<GlobRow>
									<GlobCol :medium="4">
										<label class="crud-label">
											{{$t('billing.price_plan.fields.name')}}*
											<InputDynamic
											:fullWidth="true"
											styling="margin-bottom: .5rem;"
											:type="'text'"
											:placeholder="$t('billing.price_plan.fields.name')"
											v-model="pricePlan.form.Name"
											/>
										</label>
									</GlobCol>
									<GlobCol :medium="4">
										<label class="crud-label">
											{{$t('billing.price_plan.fields.start_date')}}
											<InputDynamic
											:fullWidth="true"
											styling="margin-bottom: .5rem;"
											:type="'date'"
											:placeholder="$t('billing.price_plan.fields.start_date')"
											v-model="pricePlan.form.StartDate"
											/>
										</label>
									</GlobCol>
									<GlobCol :medium="4">
									<label class="crud-label">
										{{$t('billing.price_plan.fields.stop_date')}}
										<InputDynamic
										:fullWidth="true"
										styling="margin-bottom: .5rem;"
										:type="'date'"
										:placeholder="$t('billing.price_plan.fields.stop_date')"
										v-model="pricePlan.form.StopDate"
										/>
									</label>
									</GlobCol>
									<GlobCol :medium="4">
										<label class="crud-label">
											{{$t('billing.price_plan.fields.billing_type')}}*
											<InputSelect
											:fullWidth="true"
											styling="margin-bottom: .5rem;"
											:type="'number'"
											:placeholder="$t('billing.price_plan.fields.select_billing_type')"
											v-model="pricePlan.form.BillingType"
											:data="billingTypes"
											/>
										</label>
									</GlobCol>
									<GlobCol :medium="4">
										<label class="crud-label">
											{{$t('billing.price_plan.fields.currency')}}
											<InputSelect
											:fullWidth="true"
											styling="margin-bottom: .5rem;"
											:type="'number'"
											:placeholder="$t('billing.price_plan.fields.select_currency')"
											v-model="pricePlan.form.CurrencyId"
											:data="currencies"
											/>
										</label>
									</GlobCol>
									<GlobCol :medium="12" class="d-flex">
										<InputCheckbox
											:id="'allowEndCustomerOverRide'"
											:modelValue="pricePlan.form.AllowEndCustomerOverride ? true : false"
											@input="(val) => pricePlan.form.AllowEndCustomerOverride = val"
										/>
										<label for="allowEndCustomerOverRide" class="crud-label crud-label-margin-left">
											{{$t('billing.price_plan.fields.allow_end_customer_over_ride')}}
										</label>
									</GlobCol>
								</GlobRow>
							</GlobXpanel>
						</div>
						<div v-if="errorMessage">
							<p class="text-danger mt-2" >{{errorMessage}}</p>
						</div>
						<div class="locker-action-buttons">
							<button class="button"  :disabled="isSaveButtonDisabled()" @click="update">{{$t('general.button.save')}}</button>
							<router-link class="button button--orange" :to="{name:'PricePlan'}"
							>{{$t('general.button.cancel')}}</router-link>
							<button class="button button--red"  @click="pricePlanDeleteConfirmModal = true">{{$t('general.button.delete')}}</button>
						</div>
					</GlobXpanel>

					<GlobXpanel :title="$t('billing.price_plan.tiers')" style="width: 490px; margin-left: 3rem">
						<GlobRow>
							<GlobCol :medium="12">
							<div class="mt-3 d-flex form-expand-icon">
								<p>{{$t('billing.price_plan.tier')}}</p>
								<span @click="showTierForm = !showTierForm; pricePlanTier.form = {};"><i class="fas fa-plus-circle"></i></span>
							</div>
							</GlobCol>
						</GlobRow>
						
						<GlobRow v-if="showTierForm">
								<GlobCol :medium="4">
										<label class="crud-label">
											{{$t('billing.price_plan.tier_fields.tier')}}*
											<InputDynamic
											:fullWidth="true"
											styling="margin-bottom: .5rem;"
											:type="'number'"
											:placeholder="$t('billing.price_plan.tier_fields.tier')"
											v-model="pricePlanTier.form.Tier"
											/>
										</label>
									</GlobCol>
								<GlobCol :medium="4">
										<label class="crud-label">
											{{$t('billing.price_plan.tier_fields.price')}}*
											<InputDynamic
											:fullWidth="true"
											styling="margin-bottom: .5rem;"
											:type="'number'"
											:placeholder="$t('billing.price_plan.tier_fields.price')"
											v-model="pricePlanTier.form.Price"
											/>
										</label>
									</GlobCol>
									<GlobCol :medium="4">
										<label class="crud-label">
											{{$t('billing.price_plan.tier_fields.range_start')}}*
											<InputDynamic
											:fullWidth="true"
											styling="margin-bottom: .5rem;"
											:type="'number'"
											:placeholder="$t('billing.price_plan.tier_fields.range_start')"
											v-model="pricePlanTier.form.RangeStart"
											/>
										</label>
									</GlobCol>
									<GlobCol :medium="4">
									<label class="crud-label">
										{{$t('billing.price_plan.tier_fields.range_stop')}}*
										<InputDynamic
										:fullWidth="true"
										styling="margin-bottom: .5rem;"
										:type="'number'"
										:placeholder="$t('billing.price_plan.tier_fields.range_stop')"
										v-model="pricePlanTier.form.RangeStop"
										/>
									</label>
									</GlobCol>
									<GlobCol :medium="8" class="tier-action-button">
											<button class="button" :disabled="isTierSaveButtonDisabled()" @click="saveOrUpdateTier">{{pricePlanTier.form.Id ? $t('general.button.update') : $t('general.button.save')}}</button>
											<button class="button button--orange" @click="pricePlanTier.form={}">{{$t('general.button.clear')}}</button>
									</GlobCol>
						</GlobRow>

						<GlobRow>
							<GlobCol :medium="12">
								<GlobTable
									:maxHeight="50"
									:data="{
										head : pricePlanTierListHead,
										body : pricePlanTierList
									}"
									:actions="pricePlanTierActions"
								/>
							</GlobCol>
						</GlobRow>
					
					</GlobXpanel>
			</GlobRow>

			<!-- Delete confirm modal -->
			<GlobConsequence :show="pricePlanDeleteConfirmModal" 
				@close="pricePlanDeleteConfirmModal=false"  
				@confirm = "deletePlan()"
				:title="$t('billing.price_plan.delete_plan')" 
				:message="$t('billing.price_plan.delete_confirm')"  />

			<!-- Delete tier confirm modal -->
			<GlobConsequence :show="tierDeleteConfirmModal" 
				@close="tierDeleteConfirmModal=false"  
				@confirm = "deleteTier()"
				:title="$t('billing.price_plan.delete_tier')" 
				:message="$t('billing.price_plan.delete_tier_confirm')"  />
	</div>
</template>
<script>
import { onMounted, computed, watch, ref, reactive } from "vue";
import { useApi } from "@/plugins/api";
import { useRouter, useRoute } from "vue-router";
import { useNotifications } from "@/plugins/notifications";
import { useI18n } from 'vue-i18n';

export default {
 setup() {

	const { tm } = useI18n();
	const api = useApi();
	const router = useRouter();
    const route = useRoute();
	const notifications = useNotifications();

    const endpoint = computed(() => api.pricePlans);
	const tierEndPoint = computed(() =>  api.pricePlanTiers);
    const isLoading = ref(false);

	const billingTypes = [{
		'value': 'post',
		'name': 'Post'
	},{
		'value': 'pre',
		'name': 'Pre'
	}]

    const currencies = ref([]);

    const errorMessage = ref(null);
    const pricePlan = reactive({
		form: {
            ResellerCustomerId: "",
			Name: "",
            StartDate: "",
            StopDate: "",
            AllowEndCustomerOverride: "",
            BillingType: "",
            CurrencyId: ""
		},
	});

	//Tiers related objects
	const showTierForm = ref(false);
	const pricePlanTier = reactive({
		form: {
			Tier: "",
            Price: "",
            RangeStart: "",
            RangeStop: ""
		},
	});

	const pricePlanTierListHead = computed(() => 
	    [{
				Alias: "Tier",
				Value: tm('billing')['price_plan']['tier_fields']['tier']
			},
			{
				Alias: "Price",
				Value: tm('billing')['price_plan']['tier_fields']['price']
			},
			{
				Alias: "RangeStart",
				Value: tm('billing')['price_plan']['tier_fields']['range_start']
			},
            {
				Alias: "RangeStop",
				Value: tm('billing')['price_plan']['tier_fields']['range_stop']
			},
		]
	
	);

	const pricePlanTierList = ref([]);
	const pricePlanDeleteConfirmModal = ref(false);
	const tierDeleteConfirmModal = ref(false);
	const selectedPricePlanTierId = ref(false);
	

	const getPricePlanTiers = async() => {
		tierEndPoint.value.get(route.params.id).then(function(res){
           pricePlanTierList.value = res.price_plan_tiers;
		   isLoading.value = false;
		})
	}

	const saveOrUpdateTier = () => {

		isLoading.value = true;
		if(!pricePlanTier.form.Id){
			tierEndPoint.value.store(route.params.id, pricePlanTier.form).then(function(res){
			if(res.success){
					notifications.push(
						{
							msg: tm('billing')['price_plan']['tier_create_success']
						},
						"success",
						false
						);
			} else {
					errorMessage.value = tm('general')['something_went_wrong'];
			}
				pricePlanTier.form = {};
				getPricePlanTiers();
				isLoading.value = false;
			})

		}else{
			tierEndPoint.value.update(route.params.id, pricePlanTier.form.Id, pricePlanTier.form).then(function(res){
			if(res.success){
					notifications.push(
						{
							msg: tm('billing')['price_plan']['tier_update_success']
						},
						"success",
						false
						);
			} else {
					errorMessage.value = tm('general')['something_went_wrong'];
			}	
				pricePlanTier.form = {};
				getPricePlanTiers();
				isLoading.value = false;
			})
		}
	}

	const deleteTier = () => {
		isLoading.value = true;
		tierEndPoint.value.delete(route.params.id, selectedPricePlanTierId.value).then(function(res){
           if(res.success){
				tierDeleteConfirmModal.value = false;
				notifications.push(
					{
						msg: tm('billing')['price_plan']['tier_delete_success']
					},
					"success",
					false
				);
				getPricePlanTiers();
			}
		})
	 }

	const isTierSaveButtonDisabled = () => {
		return pricePlanTier.form.Tier && pricePlanTier.form.Price && pricePlanTier.form.RangeStart && pricePlanTier.form.RangeStop  ? false : true;
	}

	const pricePlanTierActions = computed(() => [
			{
				name : tm('general')['button']['edit'],
				color: "green",
				action: (obj) => {
					showTierForm.value = true;
					pricePlanTier.form = obj;
				}
			},
			{
				name : tm('general')['button']['delete'],
				color: "red",
				action: (obj) => {
					selectedPricePlanTierId.value = obj.Id;
					tierDeleteConfirmModal.value = true;
				}
			},
		]
	)

		
    let resellers = ref([]);
	 onMounted(async() => {

		//get currencies
		 api.currencies.get().then((res) => {
                currencies.value = res.currencies;
        });
        //Get list of resellers
        api.resellers.get().then((res) => {
                resellers.value = res.resellers.map((item) => ({
                value: item.id,
                name: item.CustomerName
            }));
        });

        getPricePlan();
		getPricePlanTiers();

	 });

     const getPricePlan = () => {
         isLoading.value = true;
	     endpoint.value.getById(route.params.id).then(function(res){
			if(!res.success){
				notifications.push(
					{
						msg: tm('general')['something_went_wrong']
					},
					"warning",
					false
					);
				router.push({'name': 'PricePlan'})
			}
			pricePlan.form = res.price_plan;
			isLoading.value = false;
		});
     }

	const update = () => {

       if(!validateForm()){
		   return false;
	   }
		isLoading.value = true;

		endpoint.value.update(route.params.id, pricePlan.form).then(function(res){
           if(res.success){
				notifications.push(
					{
						msg: tm('billing')['price_plan']['update_success']
					},
					"success",
					false
					);
				getPricePlan();
		   } else {
				errorMessage.value = tm('general')['something_went_wrong'];
		   }
		   isLoading.value = false;
		})
	}

	const deletePlan = () => {
		isLoading.value = true;
		endpoint.value.delete(route.params.id).then(function(res){
           if(res.success){
				pricePlanDeleteConfirmModal.value = false;
				isLoading.value = false;
				router.push({'name': 'PricePlan'})
				notifications.push(
					{
						msg: tm('billing')['price_plan']['delete_success']
					},
					"success",
					false
				);
			}
		})
	}

	const isSaveButtonDisabled = () => {
		return pricePlan.form.ResellerCustomerId && pricePlan.form.Name && pricePlan.form.BillingType  ? false : true;
	}

	const validateForm = () =>{
		errorMessage.value = null;
		
		if(pricePlan.form.StartDate && pricePlan.form.StopDate){
			if(pricePlan.form.StopDate < pricePlan.form.StartDate){
				errorMessage.value = tm('billing')['price_plan']['errors']['stop_date_should_be_greater_than_start'];
				return false;
			}
		}

		return true;
	}
     	
    return {
        route,
        getPricePlan,
		pricePlan,
		isLoading,
		validateForm,
		update,
		deletePlan,
		errorMessage,
		isSaveButtonDisabled,
		resellers,
		billingTypes,
        currencies,
		pricePlanDeleteConfirmModal,
		//Tier
		pricePlanTier,
		pricePlanTierList,
		pricePlanTierListHead,
		tierEndPoint,
		isTierSaveButtonDisabled,
		saveOrUpdateTier,
		pricePlanTierActions,
		showTierForm,
		tierDeleteConfirmModal,
		selectedPricePlanTierId,
		deleteTier
    };
  }
};
</script>
<style lang="scss">
.crud-label-margin-left{
    margin-left: .5rem;
}
.tier-action-button{
	align-items: flex-end !important;
    display: flex !important;
    padding: 0 !important;
    margin: 7px !important;
}

.form-expand-icon{
	p{
		color: #495057;
		font-size: 1rem;
		font-weight: bold;
	}

	span{
		justify-content: center;
		align-items: center;
		display: flex;
		margin-left: 4px;
		cursor: pointer;

		i{
			font-size: 22px;
		}
	}
}
</style>
