<template>
	<div>
	<GlobSpinner :isLoading="isLoading" ></GlobSpinner>
	<!-- Filter section start-->
	<GlobRow class="tab filter-section">
		<GlobCol :medium="4" class="mas-mt-3">
			<label>
				{{ $t('storage.statistics.from_to') }}
				<InputDynamic
					:type="'daterange'"
					:fullWidth="true"
					v-model="filter.dateRange"
					:placeholder="$t('storage.statistics.from_to')"
					>
				</InputDynamic>
			</label>
		</GlobCol>
		<GlobCol :medium="4">
				<label>
					{{ $t('storage.statistics.article') }}
					<InputDynamic
						:type="'multiselect'"
						:fullWidth="true"
						v-model="filter.articles"
						:data="articleList"
						:placeholder="$t('storage.statistics.search_article')"
						@searchKey="(searchKey) => searchArticle(searchKey)"
						>
					</InputDynamic>
				</label>
		</GlobCol>
		<GlobCol :medium="3" class="filter-buttons">
			<button class="button" @click="getStatistics">{{ $t('general.button.filter') }}</button>
			<button class="button button--orange" @click="clearFilter">{{ $t('general.button.clear') }}</button>
		</GlobCol>
	</GlobRow>
	<!-- Filter section end-->
	<GlobTable :data="{
		body: body,
		head: head
	}"/>
		</div>
</template>

<script>

import { onMounted, reactive, ref, computed } from 'vue';
import { useRouter } from 'vue-router';
import { useApi } from '@/plugins/api';
import { useI18n } from 'vue-i18n';
import moment from 'moment';
import inputDynamic from '../../../components/inputs/input-dynamic.vue';
export default {
  components: { inputDynamic },
	setup() {
		const { tm } = useI18n();
		const api = useApi();
		const isLoading = ref(false);
		const router = useRouter();
		const id = ref(router.currentRoute.value.params.id);
		const body = ref([]);

		const head = computed(() =>
			[
				{ Alias: 'ArticleGroupName', Value: tm('storage.statistics.group_name') },
				{ Alias: 'ArticleName', Value: tm('storage.statistics.article') },
				{ Alias: 'TotalUsed', Value: tm('storage.statistics.consumption') },
				{ Alias: 'DailyUse', Value: tm('storage.statistics.daily_consumption') }
			]
		);
		
		const currentDate = new Date();

		let filter = reactive({
			articles: [],
			dateRange: []
		});

		const articleList = ref([]);
		const loadedArticleList = ref([]);

		const getStatistics = () => {
			isLoading.value = true;
			api.lockers.getStatistics(id.value, {
				articles: filter.articles,
				from: filter.dateRange && filter.dateRange[0] ? moment(filter.dateRange[0]).format('YYYY-MM-DD') : null,
				to: filter.dateRange && filter.dateRange[1] ? moment(filter.dateRange[1]).format('YYYY-MM-DD') : null
			}).then(res => {
				body.value = res.data;
				isLoading.value = false;
			})
		}

		const searchArticle = (searchKey) => {
			
			if (searchKey && searchKey.length > 2) {
				if (loadedArticleList.value && loadedArticleList.value[searchKey]) {
					articleList.value = loadedArticleList.value[searchKey]
				} else {
					api.articles.search(searchKey).then((res) => {
						let articles = res.articles.map((article) => {
							return {
								'value': article.Id,
								'name': article.Name
							}
						});
						articleList.value = articles;
						loadedArticleList.value[searchKey] = articleList.value;
					})
				}
			}
		}

		onMounted(() => {
			clearFilter();
		})

		const clearFilter = () => {
			filter.dateRange = [new Date(new Date().setDate(new Date().getDate() - 14)), currentDate]
			filter.articles = [];
			getStatistics();
		}

		return {
			head,
			body,
			getStatistics,
			filter,
			searchArticle,
			articleList,
			clearFilter,
			isLoading
		}
	}
}
</script>