<template>
  <div style="position: absolute; overflow: hidden; left: -0px; right: -0px; top: -0px; bottom: -0px;">
    <div style="height: 100vh; position: relative; z-index: 2;">
      <div class="ips-center-content">
        <div class="ips-login-form">
          <div class="ips-login-form__header">
            <img class="logo-text logo-text--big" src="@/assets/logo/logo-white-text.svg" />
          </div>
          <form @submit.prevent="login" accept-charset="utf-8">
            <div class="form-group">
              <div class="form-group">
                <label for="email">E-post:</label>
                <input
                  type="email"
                  spellcheck="false"
                  class="form-control"
                  placeholder="Ange epost"
                  id="validateUsername"
                  name="validateUsername"
                  v-model="userData.username"
                />
              </div>
              <label for="pwd">Lösenord:</label>
              <input
                type="password"
                class="form-control"
                :class="passwordError ? 'error' : ''"
                placeholder="Ange lösenord"
                id="validateUserPassword"
                name="validateUserPassword"
                @input="passwordError = false"
                v-model="userData.password"
              />
            </div>
            <div class="form-group form-check">
              <label class="form-check-label">
                <input class="form-check-input" v-model="remember" type="checkbox" /> Kom ihåg mig
              </label>
            </div>
            <input
              type="submit"
              style="display: block; margin: .5rem auto;"
              value="Logga in"
              class="button button--white button--outline"
            />
          </form>
        </div>
      </div>
    </div>
    <div
      :style="
        `left: -20px; right: -20px; top: -20px; bottom: -20px; transition: all 1s ease-in-out; background-image: linear-gradient(0deg, rgba(0,0,0, 0.3), rgba(0,0,0, 0.3)), url(${bg}); filter: blur(10px); background-size: cover;  position: absolute;`
      "
    ></div>
  </div>
</template>

<script>
import bg from "@/assets/green-highway.jpg";
import logo from "@/assets/logo/logo.svg";
import Cookies from "js-cookie";
import { onMounted, ref } from "vue";
import { useRouter } from "vue-router";
import { useApi } from "@/plugins/api";
import { useNotifications } from "@/plugins/notifications";
import $ from "jquery";
export default {
  setup() {
    const api = useApi();
    const notifications = useNotifications();
		const router = useRouter();

    const remember = ref(false);
    const passwordError = ref(false);
    const SessionKey = ref("");

    const userData = ref({
      username: "",
      password: ""
    });

    const getCredentials = () => {
      const user = Cookies.get("ips_credentials_user");
      if (user) {
        userData.value.username = user;
        remember.value = true;
      }
    };

    const login = () => {
      if (remember.value) {
        Cookies.set("ips_credentials_user", userData.value.username, { expires: 365 });
      }
      api.login(
          {
            validateUsername: userData.value.username,
            validateUserPassword: userData.value.password,
            SessionKey: Cookies.get("FrontendSessionKey")
          },
          notifications
        )
        .then((res) => {
          Cookies.set("FrontendSessionKey", res.SessionKey);
          return true;
        })
        .then((res) => {
          router.push(router.currentRoute.value.query.redirect ? router.currentRoute.value.query.redirect : "/");
        })
        .catch((err) => {
          userData.value.password = "";
          passwordError.value = true;
        });
    };

    onMounted(() => {
      getCredentials();
    });

    return {
      passwordError,
      userData,
      remember,
      login,
      bg
    };
  }
};
</script>

<style lang="scss">
.ips-center-content {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  width: 100%;
}
.ips-login-form {
  min-width: 500px;
  padding: 2rem;
  color: white;
  background-color: rgba(0, 0, 0, 0.4);
  form {
    padding: 0rem 3rem 3rem;
  }
  &__header {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 2rem 0;
    .logo-text {
      margin-bottom: 1rem;
      max-width: 8rem;
    }
    h2 {
      text-align: center;
      font-size: 3rem;
      font-family: Helvetica;
      color: white;
      font-weight: normal;
    }
  }
  input[type="email"],
  input[type="password"] {
    border: none;
    border-bottom: 3px solid white;
    background: transparent;
    color: white;
    font-weight: bold;
    width: 100%;
    margin: 0.5rem 0 1rem;
    padding: 0.5rem 0;
    &:focus {
      border-color: $kits-orange;
      outline: 0;
    }
    &::placeholder {
      color: white;
    }
    &.error {
      border-color: $kits-red !important;
    }
  }
  .button {
    margin-top: 2rem !important;
    font-weight: bold !important;
    &:hover {
      box-shadow: inset 0px 0px 0px 2px $kits-green !important;
      background-color: $kits-green;
    }
  }
}
</style>
