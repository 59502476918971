
import SecureCloud from "@/views/crud/SecureCloud/SecureCloud.vue";

export const secureCloud = [
    {
        path: "/admin/view/secure-cloud",
        component: SecureCloud,
        isCRUD: true,
        name: "secureCloud",
        nav: {
            name: "Admin",
            icon: "sliders-h"
        },
        meta: {
            noPadding: true,
            access: ['superadmin'],
            breadcrumbs: [{ name: "general.admin", link: "/admin" }, { name: "administration.locker.breadcrumb.overview" }] ,
            languageSupport: true
        },
    }
]
