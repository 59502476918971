<template>
	<div class="ips-user" @click="toggleControls">
		<div class="ips-user__main">
			<div class="ips-user__main-letter">{{user && user.Name && user.Name[0]}}</div>
			<div class="ips-user__main-name">{{user.Name}}</div>
			<i class="fas fa-angle-down"></i>
		</div>
		<div class="ips-user__controls" @mouseleave="closeControls" :class="isControlsToggled ? 'ips-user__controls--active' : ''">
			<div class="ips-user__controls-item" v-for="item in controls" :key="item.id" @click="item.action">
				<span>{{item.name}}</span> <i :class="`fas fa-${item.icon}`"></i>
			</div>
		</div>
	</div>
</template>

<script>
import { ref, computed } from 'vue';
import { useRouter } from 'vue-router'
import { useStore } from 'vuex'
import { useApi } from '@/plugins/api';
import { useNotifications } from '@/plugins/notifications'
export default {
	setup() {
		const api = useApi();
		const notifications = useNotifications();
		const router = useRouter();
		const store = useStore();

		const user = computed(() => store.state.USER);


		const isControlsToggled = ref(false)

		const toggleControls = () => {
			isControlsToggled.value = !isControlsToggled.value
		}

		const closeControls = () => {
			isControlsToggled.value = false;
		}

		const logout = () => {
			api.logout().then(res => {
				notifications.push({
					title: 'Loggade ut',
					msg: 'Du loggas ut.'
				}, 'success', false, false)
				router.go()
			})
		}

		const controls = [
			{
				name: 'Logga ut',
				slug: 'log-out',
				icon: 'sign-in-alt',
				id: Symbol(),
				action: () => logout()
			}
		]

		return {
			controls,
			toggleControls,
			isControlsToggled,
			closeControls,
			user
		}
	}
}
</script>

<style lang="scss" scoped>
	.ips-user {
		margin: 0 1rem;
		position: relative;
		cursor: pointer;
		&__main {
			display: flex;
			align-items: center;
			&-letter {
				width: 1.6rem;
				height: 1.6rem;
				font-weight: 700;
				display: flex;
				justify-content: center;
				align-items: center;
				background-color: $kits-green;
				border-radius: 100px;
				color: white;
				margin-right: .5rem;
				border: 2px solid $kits-greenBlue;
			}
			i {
				margin-left: .5rem;
			}
		}
		&__controls {
			z-index: 15;
			background-color: white;
			visibility: hidden;
			opacity: 0;
			transition: all .3s ease-in-out;
			position: absolute;
			right: 0;
			background-color: white;
			top: 30px;
			border-radius: 4px;
			@include box-shadow(1);
			border: 1px solid $kits-lightGray;
			&-item {
				padding: .5rem 1rem;
				display: flex;
				font-size: .8rem;
				align-items: center;
				cursor: pointer;
				span {
					white-space: nowrap;
					margin-right: 2rem;
				}
				&:hover {
					background-color: $kits-lightGray;
				}
			}
			&--active {
				opacity: 1;
				visibility: visible;
			}
		}
	}
</style>