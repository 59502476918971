<template>
    <GlobLoader v-if="isLoading"/>
	<div class="Kits-crud" v-else>
		<Navbar :items="routes" />
        <div class="kits-top-button-row">
            <router-link class="button button--linearGray" :to="'/admin/view/lockers-v2'"
                >{{$t('general.button.back_to_overview')}}</router-link>
        </div>
		   <GlobXpanel :title="$t('administration.locker.edit_locker')" style="max-width: 900px;">
				<div class="ips-admin-fieldgroup">
					<GlobXpanel :noShadow="true" :tools="false" :title="$t('general.customer')">
					<GlobRow>
						<GlobCol :medium="4">
							<label class="crud-label">
										{{$t('general.fields.customer')}}*
							<InputSelect :fullWidth="true" v-model="locker.form.EndCustomerId" :placeholder="$t('general.select_customer')" :data="customers" />
							</label>
						</GlobCol>
						</GlobRow>
					</GlobXpanel>
				</div>
				<div class="ips-admin-fieldgroup">
					<GlobXpanel :noShadow="true" :tools="false" :title="$t('general.general')">
						<GlobRow>
							<GlobCol :medium="4">
								<label class="crud-label">
									{{$t('general.fields.name')}}*
									<GlobLabel :help="$t('administration.locker.info.name')" class="locker-field-help-info" />
									<InputDynamic
									:fullWidth="true"
									styling="margin-bottom: .5rem;"
									:type="'text'"
									:placeholder="$t('general.fields.name')"
									v-model="locker.form.Name"
									/>
								</label>
							</GlobCol>
							<GlobCol :medium="4">
								<label class="crud-label">
									{{$t('general.fields.description')}}
									<GlobLabel :help="$t('administration.locker.info.description')" class="locker-field-help-info" />
									<InputDynamic
									:fullWidth="true"
									styling="margin-bottom: .5rem;"
									:type="'text'"
									:placeholder="$t('general.fields.description')"
									v-model="locker.form.Description"
									/>
								</label>
							</GlobCol>
							<GlobCol :medium="4">
							<label class="crud-label">
								{{$t('general.fields.location')}}
								<GlobLabel :help="$t('administration.locker.info.location')" class="locker-field-help-info" />
								<InputDynamic
								:fullWidth="true"
								styling="margin-bottom: .5rem;"
								:type="'text'"
								:placeholder="$t('general.fields.location')"
								v-model="locker.form.Location"
								/>
							</label>
							</GlobCol>
						</GlobRow>
					</GlobXpanel>
				</div>
				<div class="ips-admin-fieldgroup">
					<GlobXpanel :noShadow="true" :tools="false" :title="$t('general.layout')">
						<GlobRow>
							<GlobCol :medium="4">
								<label class="crud-label">
									{{$t('administration.locker.amount_of_self')}}
									<GlobLabel :help="$t('administration.locker.info.amount_of_self')" class="locker-field-help-info" />
									<InputDynamic
									:fullWidth="true"
									styling="margin-bottom: .5rem;"
									:type="'number'"
									:placeholder="$t('administration.locker.amount_of_self')"
									v-model="locker.form.ShelfAmount"
									/>
								</label>
							</GlobCol>
							<GlobCol :medium="4">
								<label class="crud-label">
									{{$t('administration.locker.section_amount')}}
									<GlobLabel :help="$t('administration.locker.info.section_amount')" class="locker-field-help-info" />
									<InputDynamic
									:fullWidth="true"
									styling="margin-bottom: .5rem;"
									:type="'number'"
									:placeholder="$t('administration.locker.section_amount')"
									v-model="locker.form.ColumnAmount"
									/>
								</label>
							</GlobCol>
							<GlobCol :medium="4">
									<label class="crud-label">
										{{ $t('administration.locker.allow_enduser_to_set_placement') }}
									</label>
									<InputSwitch 
										class="kis-mt-1"  
										v-model="locker.form.AllowEnduserToSetPlacement"
										:value="locker.form.AllowEnduserToSetPlacement"
										:toggles="{
											on: $t('general.yes'),
											off: $t('general.no')
										}"
										
										/>
								</GlobCol>
						</GlobRow>
						<GlobRow>
							<GlobCol :medium="4">
								<input type="file" ref="logoImage" style="display: none" accept="image/*" @change="e => fileAttached(e, 'logoImage')">
										<label class="crud-label">
											{{$t('administration.locker.logo')}}
											<template v-if="!locker.form.LogoImageURL">
												<div class="file-upload-input-block">
													<div class="dynamic-type-input" style="display:flex;">
													<InputDynamic
													    :disabled= "true" 
													    @click.stop="$refs.logoImage.click()"
														:class="'input-block custom-disabled-input'"
														:fullWidth="true"
														:type="'text'"
														:placeholder="$t('administration.locker.logo')"
														/>
													<span @click.stop="$refs.logoImage.click()"><i
													class="fa fa-upload work-flow-file-icon" aria-hidden="true"></i></span>
													</div>
												</div>
											</template>
											<template v-else>
												<div class="attachment">
													<a id="uploaded-doc-link" :href="locker.form.LogoImageURL"
														download="" target="_blank">{{getLogoName()}}</a>
													<span @click.stop="$refs.logoImage.value = null; locker.form.Logo = ''; locker.form.LogoImageURL = null; locker.form.LogoRemoved = true; "><i class="fas fa-times"></i></span>
												</div>
											</template>
										</label>
							</GlobCol>
							<GlobCol :medium="4">
								<input type="file" ref="backgroundImage" style="display: none" accept="image/*" @change="e => fileAttached(e, 'backgroundImage')">
										<label class="crud-label">
											{{$t('administration.locker.background_image')}}
											<template v-if="!locker.form.BackgroundImageURL">
												<div class="file-upload-input-block">
													<div class="dynamic-type-input" style="display:flex;">
													<InputDynamic
													   :disabled= "true" 
													    @click="$refs.backgroundImage.click()"
														:fullWidth="true"
														:class="'input-block custom-disabled-input'"
														:type="'text'"
														:placeholder="$t('administration.locker.background_image')"
														/>
													<span @click="$refs.backgroundImage.click()"><i
													class="fa fa-upload work-flow-file-icon" aria-hidden="true"></i></span>
													</div>
												</div>
											</template>
											<template v-else>
												<div class="attachment">
													<a id="uploaded-doc-link" :href="locker.form.BackgroundImageURL"
														download="" target="_blank">{{getBackgroundImageName()}}</a>
													<span @click.stop="$refs.backgroundImage.value = null; locker.form.BackgroundImage = ''; locker.form.BackgroundImageURL = null; locker.form.BackgroundImageRemoved = true; "><i class="fas fa-times"></i></span>
												</div>
											</template>
										</label>
							</GlobCol>
						</GlobRow>
					</GlobXpanel>
				</div>
				<div class="ips-admin-fieldgroup">
					<GlobXpanel :noShadow="true" :tools="false" :title="$t('general.settings')">
						<GlobRow>
							<GlobCol :medium="4">
								<label class="crud-label">
									{{$t('administration.locker.validation_type')}}
									<GlobLabel :help="$t('administration.locker.info.validation_type')" class="locker-field-help-info" />
									<InputSelect
									:fullWidth="true"
									styling="margin-bottom: .5rem;"
									:type="'select'"
									:placeholder="$t('administration.locker.validation_type')"
									v-model="locker.form.ValidateBy"
									:data="validationTypeList"
									/>
								</label>
							</GlobCol>
							<GlobCol :medium="4">
								<label class="crud-label">
									{{$t('administration.locker.closing_time')}}
									<GlobLabel :help="$t('administration.locker.info.closing_time')" class="locker-field-help-info" />
									<InputDynamic
									:fullWidth="true"
									styling="margin-bottom: .5rem;"
									:type="'number'"
									:placeholder="$t('administration.locker.closing_time')"
									v-model="locker.form.ClosingTimeSeconds"
									/>
								</label>
							</GlobCol>
							<GlobCol :medium="4">
							<label class="crud-label">
								{{$t('administration.locker.admin_code')}}
								<GlobLabel :help="$t('administration.locker.info.admin_code')" class="locker-field-help-info" />
								<InputDynamic
								:fullWidth="true"
								styling="margin-bottom: .5rem;"
								:type="'number'"
								:placeholder="$t('administration.locker.admin_code')"
								v-model="locker.form.AdminPassword"
								/>
							</label>
							</GlobCol>
						</GlobRow>
					</GlobXpanel>
				</div>
				<div class="ips-admin-fieldgroup">
					<GlobXpanel :noShadow="true" :tools="false" :title="$t('administration.locker.gpio_settings')">
						<GlobRow>
							<GlobCol :medium="4">
								<label class="crud-label">
									{{$t('administration.locker.locker_pin_number')}}
									<GlobLabel :help="$t('administration.locker.info.locker_pin_number')" class="locker-field-help-info" />
									<InputDynamic
									:fullWidth="true"
									styling="margin-bottom: .5rem;"
									:type="'number'"
									:placeholder="$t('administration.locker.locker_pin_number')"
									v-model="locker.form.Pin"
									/>
								</label>
							</GlobCol>
							<GlobCol :medium="4">
								<label class="crud-label">
									{{$t('administration.locker.open_state')}}
									<GlobLabel :help="$t('administration.locker.info.open_state')" class="locker-field-help-info" />
									<InputSelect
									:fullWidth="true"
									styling="margin-bottom: .5rem;"
									:type="'number'"
									:placeholder="$t('administration.locker.open_state')"
									v-model="locker.form.OpenPinState"
									:data="openStates"
									/>
								</label>
							</GlobCol>
						</GlobRow>
					</GlobXpanel>
				</div>
				<div class="ips-admin-fieldgroup">
					<GlobXpanel :noShadow="true" :tools="false" :title="$t('administration.locker.provisioning')">
                     <!-- Locker provisioned section -->
					 <template v-if="locker.form.isProvisioned">
						<div>
							<template v-if="isProvisionAwaiting">
								<span class="badge rounded-pill bg-warning"></span>
								{{$t('administration.locker.locker_provision_awaiting')}}
							</template>
							<template v-else>
								<span class="badge rounded-pill bg-success"></span>
								{{$t('administration.locker.locker_provisioned')}} {{provisionedDateTime}}
							</template>
						</div>
						<div class="add-edit-provision-block">
							<span @click="showEditProvisionControl = !showEditProvisionControl"><i class="fas fa-pen"></i></span>
							{{$t('administration.locker.edit_provision')}}
						</div>
						<template v-if="showEditProvisionControl">
							<p class="provisioning-control-title">{{$t('administration.locker.provisioning_controls')}}</p>
							<div class="provisioning-control-action">
							<button class="button" v-if="false">{{$t('administration.locker.edit_device')}}</button>
							<button class="button" @click="lockerRestartConfirmModal=true;">{{$t('administration.locker.restart_locker')}}</button>
							<button class="button button--red" v-if="false">{{$t('administration.locker.de_provision')}}</button>
							</div>
						</template>
					 </template>
					 <!-- Locker not provisioned section -->
					<template v-else>
						<div>
							<span class="badge rounded-pill bg-danger"></span>
							{{$t('administration.locker.locker_not_provisioned')}}
						</div>
						<div class="add-edit-provision-block">
							<span @click="showAddProvisionControl = !showAddProvisionControl"><i class="fas fa-plus"></i></span>
							{{$t('administration.locker.start_provision')}}
						</div>
						<template v-if="showAddProvisionControl">
							<p class="provisioning-control-title">{{$t('administration.locker.provisioning_controls')}}</p>
							<div class="provisioning-control-action">
								<button class="button" v-if="false">{{$t('administration.locker.select_device')}}</button>
								<button class="button" @click="showAddProvisionModal = true">{{$t('administration.locker.provision')}}</button>
							</div>
						</template>
					</template>
					</GlobXpanel>
				</div>
				<div v-if="errorMessage">
					<p class="text-danger mt-2" >{{errorMessage}}</p>
				</div>
				<div class="locker-action-buttons">
					<button class="button" :disabled="isSaveButtonDisabled()" @click="update()">{{$t('general.button.save')}}</button>
					<router-link class="button button--orange" :to="'/admin/view/lockers-v2'"
					>{{$t('general.button.cancel')}}</router-link>
					<button class="button button--red" @click="lockerDeleteConfirmModal = true">{{$t('general.button.delete')}}</button>
				</div>
		</GlobXpanel>

		<!-- Restart locker confirm modal -->
       	<GlobConsequence :show="lockerRestartConfirmModal" 
			 @close="lockerRestartConfirmModal=false"  
			 @confirm = "restartLocker()"
			 :title="$t('administration.locker.restart_locker')" 
			 :message="$t('administration.locker.restart_confirm')"  />

		<GlobConsequence :show="lockerDeleteConfirmModal" 
			 @close="lockerDeleteConfirmModal=false"  
			 @confirm = "deleteLocker()"
			 :title="$t('administration.locker.delete_locker')" 
			 :message="$t('administration.locker.delete_confirm')"  />

        <!-- Add provision modal -->
		<GlobModal :show="showAddProvisionModal" @close="showAddProvisionModal = false" :width="30" :title="$t('administration.locker.add_provision')">
			<label class="crud-label provision-input">
				{{$t('administration.locker.provision_code')}}*
				<InputDynamic
				:fullWidth="true"
				style="margin-bottom: 1rem;"
				:type="'text'"
				:placeholder="$t('administration.locker.provision_code')"
				v-model="provisionCode"
				/>
			</label>
			<div v-if="provisionErrorMessage">
				<p class="text-danger" >{{provisionErrorMessage}}</p>
			</div>
			<div class="ips-globmodal-confirm-btns">
				<button class="button button--red" :disabled="!provisionCode" @click="addProvision()"
				>{{$t('general.button.save')}}</button>
				<button class="button ml-2 button--orange"
				@click='showAddProvisionModal = false'>{{$t('general.button.cancel')}}</button>
			</div>
		</GlobModal>
	</div>
</template>
<script>
import { onMounted, computed, watch, ref, reactive } from "vue";
import { useApi } from "@/plugins/api";
import { useRoute, useRouter } from "vue-router";
import { useNotifications } from "@/plugins/notifications";
import Navbar from "@/views/Admin/Navbar.vue";
import { routes } from "@/views/Admin/config";
import { useI18n } from 'vue-i18n';
import moment from "moment";

export default {
components: {
	Navbar
},
 setup() {

	const { tm } = useI18n();
	const api = useApi();
	const router = useRouter();
	const route = useRoute();
	const notifications = useNotifications();

	const endpoint = computed(() => api.lockersV2);
    const isLoading = ref(false);
	const errorMessage = ref(null);

	//Provion related params
	const showAddProvisionModal = ref(false);
	const provisionCode = ref(null);
	const provisionErrorMessage = ref(null);
	const isProvisionAwaiting = ref(0);

	const validationTypeList = [{
		'value': 'employeenumber',
		'name': 'Employeenumber'
	},{
		'value': 'pincode',
		'name': 'Pincode'
	},{
		'value': 'employeenumber_and_pincode',
		'name': 'Employeenumber & Pincode'
	}]
	const openStates = [{
		'value': 1,
		'name': 'Open'
	},{
		'value': 0,
		'name': 'Closed'
	}]

	const showEditProvisionControl = ref(false);
	const showAddProvisionControl = ref(false);
	const provisionedDateTime = ref(false);

	const lockerDeleteConfirmModal = ref(false);
	const lockerRestartConfirmModal = ref(false);

    const locker = reactive({
		form: {
			Name: "",
			Description: "",
			Location: "",
			EndCustomerId: null,
			Logo: "",
			BackgroundImage: "",
			ValidateBy: "",
			ClosingTimeSeconds: "",
			AdminPassword: "",
			Pin: "",
			OpenPinState: "",
			AllowEnduserToSetPlacement: false
		},
	});
	
	let customers = ref([]);
	 onMounted(async() => {
        getLocker();
		//Get list of customers
        api.customers.get().then((res) => {
                customers.value = res.data.map((item) => ({
                value: item.id,
                name: item.CustomerName
            }));
        });
	 });

	 const getLocker = () => {
		isLoading.value = true;
		endpoint.value.getById(route.params.id).then(function(res){
			if(!res.success){
				notifications.push(
					{
						msg: tm('general')['something_went_wrong']
					},
					"warning",
					false
					);
				router.push(`/admin/view/lockers-v2`);
			}
			locker.form = res.locker;
			locker.form.lockerId = res.locker.Id;
			locker.form.LogoImageURL = res.locker.LogoImageURL;
			// locker.form.AllowEnduserToSetPlacement = res.locker.AllowEnduserToSetPlacement ? true : false;

			locker.form.BackgroundImageURL =  res.locker.BackgroundImageURL;
			if(!res.locker.device || !res.locker.device.device_provision){
              locker.form.isProvisioned = false;
			} else {
			   locker.form.isProvisioned = true;
			   if(res.locker.device.device_provision){
					isProvisionAwaiting.value = res.locker.device.device_provision.ProvisionAwaiting;
					provisionedDateTime.value = moment(res.locker.device.device_provision.ProvisionedTime).format('YYYY-MM-DD');
			   }
			}
			isLoading.value = false;
		});
	 };
	const update = () => {
		if(!validateForm()){
		   return false;
	   	}
		const formData = new FormData();
		Object.keys(locker.form).forEach(key => 
			locker.form[key] ?  formData.append(key, locker.form[key]) : ''
		);
		isLoading.value = true;
		endpoint.value.updateLocker(route.params.id, formData).then(function(res){
           if(res.data.success){
				notifications.push(
					{
						msg : tm('administration')['locker']['update_success']
					},
					"success",
					false
					);
				router.push('/admin/view/lockers-v2')
		   }else {
				if (res.data.errors) {
					for (let errorField in res.data.errors) {
							let error = JSON.parse(res.data.errors[errorField][0]);
							errorMessage.value = tm(error.error).replace('{attribute}', tm(error.attribute));
							break;
					}
				} else if(res.data.errorcode === 413){
                    errorMessage.value = tm('general')['errors']['large_file_exception'];
				} else {
					errorMessage.value = tm('general')['something_went_wrong'];
				}
		   }
		   isLoading.value = false;
		})
	 }

	 const deleteLocker = () => {
		isLoading.value = true;
		endpoint.value.delete(route.params.id).then(function(res){
           if(res.data.success){
				lockerDeleteConfirmModal.value = false;
				isLoading.value = false;
				router.push('/admin/view/lockers-v2')
				notifications.push(
					{
						msg: tm('administration')['locker']['delete_success']
					},
					"success",
					false
				);
			}
		})
	 }

	const isSaveButtonDisabled = () => {
		return locker.form.Name && locker.form.EndCustomerId ? false : true;
	}

	const fileAttached = (e,type) => {
		var files = e.target.files;
		if(type == 'logoImage'){
			locker.form.Logo = files[0];
			locker.form.LogoImageURL = URL.createObjectURL(locker.form.Logo);
			locker.form.LogoRemoved = true;
		} else {
			locker.form.BackgroundImage = files[0];
			locker.form.BackgroundImageURL = URL.createObjectURL(locker.form.BackgroundImage);
			locker.form.BackgroundImageRemoved = true;
		}
	}

	const getLogoName = () => {
		if(locker.form.Logo && locker.form.Logo.name){
           return locker.form.Logo.name.length > 20 ? locker.form.Logo.name.substring(0,10)+".." : locker.form.Logo.name;
		} else {
			return locker.form.LogoImage.length > 20 ? locker.form.LogoImage.substring(0,10)+".." : locker.form.LogoImage;
		}
		
	}

	const getBackgroundImageName = () => {

		if(locker.form.BackgroundImage && locker.form.BackgroundImage.name){
           return locker.form.BackgroundImage.name.length > 20 ? locker.form.BackgroundImage.name.substring(0,10)+".." : locker.form.BackgroundImage.name;
		} else {
			return locker.form.BackgroundImage.length > 20 ? locker.form.BackgroundImage.substring(0,10)+".." : locker.form.BackgroundImage;
		}
		
	}

	const validateForm = () =>{
		errorMessage.value = null;
		if(locker.form.ShelfAmount && !Number.isInteger(Number(locker.form.ShelfAmount))){
			errorMessage.value = tm('administration')['locker']['errors']['amount_of_self'];
			return false;
		}

		if(locker.form.ColumnAmount && !Number.isInteger(Number(locker.form.ColumnAmount))){
			errorMessage.value = tm('administration')['locker']['errors']['section_amount'];
			return false;
		}
		if(locker.form.ClosingTimeSeconds &&  ( !Number.isInteger(Number(locker.form.ClosingTimeSeconds)) || locker.form.ClosingTimeSeconds < 0 || locker.form.ClosingTimeSeconds > 30)){
			errorMessage.value = tm('administration')['locker']['errors']['closing_time'];
			return false;
		}

		if(locker.form.AdminPassword && !Number.isInteger(Number(locker.form.AdminPassword))){
			errorMessage.value = tm('administration')['locker']['errors']['admin_code'];
			return false;
		}
		return true;
	}


	const addProvision = () => {
		isLoading.value = true;
		provisionErrorMessage.value = null;
		endpoint.value.addProvision(route.params.id, provisionCode.value).then(function(res){
            if(res.data.success){
				getLocker();
				showAddProvisionModal.value = null;
            	notifications.push(
					{
						msg: tm('administration.locker.provisioned_success')
					},
					"success",
					false
				);
			}else{
            	provisionErrorMessage.value = tm('administration.locker.errors.provision_device_not_found');
			}
			isLoading.value = false;
		});
	}

	const restartLocker = () => {
        isLoading.value = true;
		endpoint.value.restartLocker(route.params.id).then(function(res){
			lockerRestartConfirmModal.value = false;
           	notifications.push(
					{
						msg: tm('administration.locker.restart_success')
					},
					"success",
					false
			);
          	isLoading.value = false;
		});
	}

     	
    return {
		routes,
		locker,
		isLoading,
		update,
		errorMessage,
		route,
		getLocker,
		lockerRestartConfirmModal,
		lockerDeleteConfirmModal,
		deleteLocker,
		showEditProvisionControl,
		showAddProvisionControl,
		provisionedDateTime,
		isSaveButtonDisabled,
		customers,
		fileAttached,
		getLogoName,
		getBackgroundImageName,
		validateForm,
		validationTypeList,
		openStates,
		//Provision related params
		showAddProvisionModal,
		addProvision,
		provisionCode,
		provisionErrorMessage,
		restartLocker,
		isProvisionAwaiting
    };
  }
};
</script>

<style scoped>
.provision-input{
	margin-top: 1rem;
    display: block;
}
</style>
