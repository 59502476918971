<template>
  <div v-if="loaded">
    <GlobRow>
      <GlobCol :medium="quickSelected ? 9 : 12">
        <GlobXpanel :title="manager.content.name">
					<router-link v-if="manager.hasCreate()" :to="`/admin/create/${manager.content.config.slug}`" style="margin-bottom: 1rem; display: inline-block;" class="button button--margin button--small">Skapa ny</router-link>
					<InputText type="text" v-model="searchString" placeholder="Sök" :style="'margin-left: 1rem; padding: .4rem 1rem'"/>
					<div class="filter-by-archived" v-if="manager.content.config.includeFilterByArchive">
						<label class="title">{{$t('general.show_archived')}}</label>
						<InputSwitch  :toggles="showarchiveToggles" v-model="showArchived"/>
					</div>
          <GlobTable
			:enableRenderFunctions="true"
            :maxHeight="50"
            :data="filteredData"
            :clickRow="(item) => handleClick(item)"
            :actions="actions"
          />
        </GlobXpanel>
      </GlobCol>
      <GlobCol v-if="quickSelected" :medium="3">
        <GlobXpanel title="Snabbredigera">
          <div class="ips-admin-quickedit" v-if="quickSelected">
            <label v-for="input in manager.quickEditFields" :key="'gi-' + input.key" style="font-size: .8rem;">
              {{ input.name }}
              <InputDynamic
                :fullWidth="true"
                styling="width: 100%; padding: .5rem;"
                :type="input.type"
                :data="input.data ? input.data() : null"
                v-model="dataObject[input.key]"
                :disabled="input.disableEdit"
                @input="handleChange"
              />
            </label>
            <button :disabled="noChanges" @click="save" class="button button--margin">
              Spara
            </button>
          </div>
          <div v-else>Välj {{ manager.content.name.toLowerCase() }} att redigera i listan till vänster</div>
        </GlobXpanel>
      </GlobCol>
    </GlobRow>
  </div>
  <div v-else>Laddar</div>
</template>

<script>
import { createManager } from "./core";
import { useRouter } from "vue-router";
import { computed, onMounted, ref, reactive } from "vue";
import { useI18n } from 'vue-i18n'

export default {
  async setup() {
	const { tm } = useI18n()
    const router = useRouter();
		const manager = await createManager(router.currentRoute.value.params.slug);
		const loaded = computed(() => manager.state && manager.state.loaded);
		const searchString = ref('');
		const showArchived = ref(false);
		const showarchiveToggles = computed(() => {
			return{
				on: tm('general')['yes'],
                off: tm('general')['no']
			}
		})

		const filteredData = computed(() => {
			let fileredList = manager.state.data.body;

			if(manager.content.config.includeFilterByArchive){
				if(!showArchived.value){
					fileredList = fileredList.filter(i => i.IsArchived != 1);
				}
			}
			
			return {
				head: manager.state.data.head.filter(i => i.HideField != true),
				body: searchString.value.length > 0 ? fileredList.filter(i => i.searchString.match(searchString.value.toLowerCase())) : fileredList
			}
		})

    const dataObject = ref({});
    const quickSelected = ref(false);
    const noChanges = ref(true);
    const setEditor = (obj) => {
      dataObject.value = obj;
      quickSelected.value = true;
      manager.validator.update(obj);
      noChanges.value = manager.validator.check(dataObject.value);
    };
    const handleChange = () => {
      noChanges.value = manager.validator.check(dataObject.value);
    };

    const handleClick = (item) => {
      router.push(`/admin/edit/${manager.content.config.slug}/${item.Id}`);
    };

    const save = () => {
      manager.endpoint.update(dataObject.value.Id, dataObject.value).then((res) => {
        quickSelected.value = false;
        noChanges.value = true;
        manager.getData();
      });
    };

    const actions = [
      {
        name: "Redigera",
        color: "green",
        if: () => manager.content.config.methods.includes("EDIT"),
        action: (obj) => {
          handleClick(obj);
        }
      },
      {
        name: "Snabbredigera",
        color: "blue",
        action: (obj) => {
          if (noChanges.value) {
            setEditor(obj);
          } else {
            notifications.push(
              {
                title: "Osparade ändringar",
                msg: "Du har inte sparat dina ändringar"
              },
              "warning",
              false
            );
          }
        }
      },
      {
        name: "Radera",
        color: "red",
        if: () => manager.content.config.methods.includes("DELETE"),
        requirements: {
          consequence: (obj) => `Är du säker på att du vill radera?`
        },
        action: async (obj) => {
          await manager.endpoint.delete(obj.Id);
          await manager.getData();
        }
      },
      {
        name: "Arkivera",
        color: "orange",
        if: () => manager.content.config.methods.includes("ARCHIVE"),
        requirements: {
          consequence: (obj) => `Är du säker på att du vill arkivera?`
        },
        action: async (obj) => {
          await manager.endpoint.archive(obj.Id);
          await manager.getData();
        }
      }
    ];

    return {
      manager,
      loaded,
      actions,
      quickSelected,
      dataObject,
      noChanges,
      handleChange,
	save,
	filteredData,
	searchString,
	showArchived,
	showarchiveToggles
    };
  }
};
</script>

<style lang="scss">
.filter-by-archived{
	    display: grid;
    float: right;

	label.title{
		    display: inline-block;
    font-size: .8rem;
	}

	.ma-toggle{
		height: 30px;
	}
	.slider__wheel{
		height: 22px;
	}
}
</style>
