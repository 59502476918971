<template>
  <Wrapper :name="containerId" :rawName="name" :xPanel="xPanel" :height="height" />
</template>

<script>
import * as am4core from "@amcharts/amcharts4/core";
import * as am4charts from "@amcharts/amcharts4/charts";
import am4themes_animated from "@amcharts/amcharts4/themes/animated";
import Wrapper from "./molecules/Wrapper.vue";
import { generateId } from "@/services/utils";
import { ref, onUnmounted, onMounted } from 'vue';
export default {
  components: {
    Wrapper
  },
  props: {
    data: {
      type: Array,
      required: true
    },
    name: {
      type: String,
      required: true
    },
    axisY: {
      type: String,
      required: true
    },
    axisX: {
      type: String,
      required: true
    },
    color: {
      type: String,
      required: false
    },
    xPanel: {
      required: false,
      default: true,
      type: Boolean
    },
    height: {
      type: Number,
      required: false
    }
	},
	
	setup(props) {
		let chart = null;
		const containerId = generateId(props.name);

		const init = () => {
      am4core.ready(() => {
        am4core.useTheme(am4themes_animated);
        chart = am4core.create(containerId, am4charts.XYChart);
        chart.data = props.data;

        var categoryAxis = chart.xAxes.push(new am4charts.CategoryAxis());
        categoryAxis.dataFields.category = props.axisX;
        categoryAxis.renderer.grid.template.location = 0;
        categoryAxis.renderer.minGridDistance = 30;

        categoryAxis.renderer.labels.template.adapter.add("dy", (dy, target) => {
          if (target.dataItem && target.dataItem.index & (2 == 2)) {
            return dy + 25;
          }
          return dy;
        });

        var valueAxis = chart.yAxes.push(new am4charts.ValueAxis());
        valueAxis.baseInterval = {
          timeUnit: "second",
          count: 1
        };

        // Create series
        var series = chart.series.push(new am4charts.ColumnSeries());
        series.dataFields.valueY = props.axisY;
        series.dataFields.categoryX = props.axisX;
        series.name = props.name;
        series.columns.template.tooltipText = "{categoryX}: [bold]{valueY}[/]";
        series.columns.template.fillOpacity = 0.8;
        series.stroke = props.color ? props.color : "#34495e";
        series.fill = props.color ? props.color : "#34495e";

        var columnTemplate = series.columns.template;
        columnTemplate.strokeWidth = 2;
        columnTemplate.strokeOpacity = 1;
      });
		}

		onMounted(() => {
			init();
		})
		
		onUnmounted(() => {
			chart.dispose();
		})
		return {
			containerId
		}
	}
};
</script>
