<template>
	<GlobXpanel v-if="data" :title="data.LockerName">
		<a
			class="text-white"
			:href="`https://keepitsmart.invens.se/modules/lockers/Locker5.html?CallerId=${data.CallerId}&AccessKey=${data.AccessKey}&cid=${data.CustomerId}&ResetCookies=1`"
			target="_blank"
			><button class="button button--green">Visa Skåpsvy</button></a
		>

		<GlobTabs :tabs="tabs" :dataTransferName="'article-id'" :initialTab="'Artiklar'" :width="40">
			<template :name="'Artiklar'" #tab-panel-Artiklar>
				<Articles :allowEnduserToSetPlacement="data.AllowEnduserToSetPlacement" :userRole="userRole" />
			</template>
			<template :name="'Användare'" #tab-panel-Användare>
				<Users />
			</template>
			<template :name="'Transaktioner'" #tab-panel-Transaktioner>
				<Transactions />
			</template>
			<template :name="'Statistik'" #tab-panel-Statistik>
				<Statistics />
			</template>
			<template :name="'Beställning'" #tab-panel-Beställning>
				<Orderpoints />
			</template>
		</GlobTabs>
	</GlobXpanel>
</template>

<script>
import { onMounted, ref, reactive } from "vue";
import { useRouter } from "vue-router";
import { useApi } from "@/plugins/api";
import { usePermissions } from "@/plugins/permissions";
import Articles from "./molecules/Articles";
import Users from "./molecules/Users";
import Transactions from "./molecules/Transactions";
import Statistics from "./molecules/Statistics";
import Orderpoints from "./molecules/Orderpoints";
import { useStore } from 'vuex';

export default {
	components: {
		Articles,
		Users,
		Transactions,
		Statistics,
		Orderpoints,
	},
	setup() {
		const router = useRouter();
		const api = useApi();
		const store = useStore();
   		const userRole = store.getters.GET_USER.UserRole;
		const { userCanHandleOrders } = usePermissions();

		let data = ref(null);
		const getData = async () => {
			await api.lockers.getById(router.currentRoute.value.params.id).then((res) => {
				data.value = res.data[0];
			});
		};

		const tabs = userCanHandleOrders()
			? ["Artiklar", "Användare", "Transaktioner", "Statistik", "Beställning"]
			: ["Artiklar", "Användare", "Transaktioner", "Statistik"];

		onMounted(() => {
			getData();
		});

		return {
			data,
			tabs,
			userRole
		};
	},
};
</script>

<style lang="scss">
.tab.filter-section{
	margin-top: 1rem;
	margin-bottom: 1rem;
	.filter-buttons{
		button{
			margin-top: 27px;
		}
	}
}
</style>
