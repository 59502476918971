<template>
 <div>
		<GlobRow>
			<GlobCol :medium="12">
				<GlobXpanel :title="$t('billing.customer_discount.customer_discounts')">
					<router-link :to="{name: 'CreateDiscount'}" style="margin-bottom: 1rem; display: inline-block;" class="button button--margin button--small">{{$t('general.create_new')}}</router-link>
					<InputText type="text" v-model="searchString" :placeholder="$t('general.search')" :style="'margin-left: 1rem; padding: .4rem 1rem'"/>
				<GlobTable
					:enableRenderFunctions="true"
					:maxHeight="50"
					:data="{
						head : discountListHead,
						body : filteredDiscountList
					}"
					:actions="actions"
				/>
				</GlobXpanel>
			</GlobCol>
		</GlobRow>
</div>
</template>

<script>
import { onMounted, computed, watch, ref , getCurrentInstance } from "vue";
import { useApi } from "@/plugins/api";
import { useRouter } from "vue-router";
import { useI18n } from 'vue-i18n'

export default {

 setup() {

	const { tm } = useI18n()
	const api = useApi();
	const router = useRouter();
	const endpoint = computed(() => api.discounts);
    
	const loaded = ref(false);
	const searchString = ref('');
	const discountList  = ref([]);
	const discountListHead = computed(() => 
	    [{
				Alias: "Id",
				Value: tm('billing')['customer_discount']['fields']['id']
			},
			{
				Alias: "CustomerName",
				Value: tm('billing')['customer_discount']['fields']['customer_name']
			},
			{
				Alias: "Discount",
				Value: tm('billing')['customer_discount']['fields']['discount']
			},
		]
	
	 );
	
	
	const actions = computed(() => [
			{
				name : tm('general')['button']['edit'],
				color: "green",
				action: (obj) => {
					router.push({
							name: 'EditDiscount', 
							params: { id: obj.Id }
						})
				}
			},
		]
	)
	
	 onMounted(async() => {
          loadDiscounts();
	 });

	const loadDiscounts = async() => {
		endpoint.value.get().then(function(res){
           discountList.value = res.customer_discounts.map((item) => {
			   if(item.CurrencyCode){
				   item.Discount = item.Discount+' '+item.CurrencyCode;
			   }
			   return item;
		   });
		})
	}

	const filteredDiscountList = computed(() => {
       return searchString.value.length > 0 ? discountList.value.filter(i => i.Name && i.Name.toLowerCase().match(searchString.value.toLowerCase())) : discountList.value
	});

    return {
		//Methods
		loadDiscounts,
		//Variables
		endpoint,
		filteredDiscountList,
		discountList,
		discountListHead,
		searchString,
		actions,
		loaded
    };
  }
};
</script>