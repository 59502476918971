<template>
	<ul class="ips-breadcrumbs">
		<li v-for="crumb in crumbs" :key="crumb.name">
			<router-link v-if="crumb.link" :to="crumb.link && renderLink(crumb.link, Object.values($route.params))">{{ languageSupport ? $t(crumb.name) : crumb.name}}</router-link>
			<p v-if="!crumb.link">{{ languageSupport ? $t(crumb.name) : crumb.name}}</p>
		</li>
	</ul>
</template>

<script>
import { useRouter } from 'vue-router';
import { computed } from 'vue';
export default { 
	setup() {
		const router = useRouter();
		const crumbs = computed(() => router.currentRoute.value.meta.breadcrumbs);
		const languageSupport = computed(() => router.currentRoute.value.meta.languageSupport);
		const renderLink = (callback, prop) => {
			return typeof callback === "function" ? callback(prop) : callback
		}
		return {
			renderLink,
			crumbs,
			languageSupport
		}
	}
}
</script>

<style lang="scss" scoped>
	.ips-breadcrumbs {
		display: flex;
		justify-content: flex-end;
		list-style-type: none;
		padding: 0;
		margin: 0;
		margin-right: auto;
		li {
			a, p {
				font-size: 15px;
				margin: 0;
				&:after {
					content: '/';
					display: inline-block;
					margin: 0 .5rem;
				}
			}
			&:last-child {
				a, p {
					&:after {
						content: '';
					}
				}
			}
		}
	}
</style>