<template>
 <div>
		<GlobRow>
			<GlobCol :medium="12">
				<GlobFilter
						@apply="(e) => getData(e)"
						:defaultValues="{
							'AverageBy': 14,
							'DaysToShow': 10
						}"
						:languageSupportCustomField="true"
						:inputMinWidth="'250px'"
						:enable="false"
						:dateToggle="false"
						:useDate="false"
						:assemblyToggle="false"
						:materialPlanSelectToggle="false"
						:multiselect="true"
						:endpoint="filterURL"
						>
				</GlobFilter>
			</GlobCol>
			<GlobLoader v-if="isLoading"/>
			<GlobCol :medium="12">
				<GlobXpanel :title="$t('planing.forecast.heading')">
					<GlobTable
						:maxHeight="50"
						:data="{
							head : tableHead,
							body : tableBody
						}"
					/>
				</GlobXpanel>
			</GlobCol>
		</GlobRow>
</div>
</template>

<script>
import { onMounted, ref, computed, reactive } from "vue";
import { useApi } from "@/plugins/api";
import { useRouter } from "vue-router";
import { useI18n } from 'vue-i18n';
import moment from "moment";

export default {
 setup() {
    const { tm, tc } = useI18n();
	const api = useApi();
	const router = useRouter();
	const endpoint = computed(() => api.materialForecast);
	const filterURL =  computed(() => api.filterURLs.materialForecast);
	const isLoading = ref(false);
	const avgBy = ref(14);


    const tableBody = ref([]);
	const tableHead = computed(() => {
		return staticTableHead.concat(dynamicTableHead.value);

	})
	const dynamicTableHead = ref([]);
	const staticTableHead = reactive([
			{
				Alias: "EndCustomer",
				Value: computed(() => tm('planing')['forecast']['fields']['customer'])
			},
			{
				Alias: "LockerName",
				Value: computed(() => tm('planing')['forecast']['fields']['locker'])
			},
			{
				Alias: "ArticleName",
				Value: computed(() => tm('planing')['forecast']['fields']['article'])
			},
            {
				Alias: "CurrentVolume",
				Value: computed(() => tm('planing')['forecast']['fields']['current_stock'])
			},
            {
				Alias: "ArticleDailyAverage",
				// Value: tm('planing.forecast.fields.avg_daily_consumption'+avgBy.value),
				Value: computed(() => tm('planing')['forecast']['fields']['avg_daily_consumption_'+avgBy.value])
			},
            {
				Alias: "MinAmount",
				Value: computed(() => tm('planing')['forecast']['fields']['security_level'])
			}
		]);
	
	
	 onMounted(async() => {
          getData();
	 });

	const getData = async(e) => {
		let body = {filters: {}};
		if (e && e.body) {
			body.filters = e.filters;
		} 

		isLoading.value = true;
		endpoint.value.get({...body}).then(function(res){
           tableBody.value = res.data.map((item) => {

			   let zeroStockReached = false;
			   let securityLevelReached = false;

			   if(item.Day1 == 0){
				   zeroStockReached = true;
				   item.Day1_BgColor = 'red';
			   }else if(item.Day1 <= item.MinAmount){
				   securityLevelReached = true;
				   item.Day1_BgColor = '#ffc100';
			   }

			   if(item.Day2 == 0 && !zeroStockReached){
				   zeroStockReached = true;
				   item.Day2_BgColor = 'red';
			   }else if(item.Day2 !=0 && item.Day2 <= item.MinAmount && !securityLevelReached){
				   securityLevelReached = true;
				   item.Day2_BgColor = '#ffc100';
			   }

			   if(item.Day3 == 0 && !zeroStockReached){
				   zeroStockReached = true;
				   item.Day3_BgColor = 'red';
			   }else if(item.Day3 !=0 && item.Day3 <= item.MinAmount && !securityLevelReached){
				   securityLevelReached = true;
				   item.Day3_BgColor = '#ffc100';
			   }

			   if(item.Day4 == 0 && !zeroStockReached){
				   zeroStockReached = true;
				   item.Day4_BgColor = 'red';
			   }else if(item.Day4 !=0 && item.Day4 <= item.MinAmount && !securityLevelReached){
				   securityLevelReached = true;
				   item.Day4_BgColor = '#ffc100';
			   }

			   if(item.Day5 == 0 && !zeroStockReached){
				   zeroStockReached = true;
				   item.Day5_BgColor = 'red';
			   }else if(item.Day5 !=0 && item.Day5 <= item.MinAmount && !securityLevelReached){
				   securityLevelReached = true;
				   item.Day5_BgColor = '#ffc100';
			   }

			   if(item.Day6 == 0 && !zeroStockReached){
				   zeroStockReached = true;
				   item.Day6_BgColor = 'red';
			   }else if(item.Day6 !=0 && item.Day6 <= item.MinAmount && !securityLevelReached){
				   securityLevelReached = true;
				   item.Day6_BgColor = '#ffc100';
			   }

			   if(item.Day7 == 0 && !zeroStockReached){
				   zeroStockReached = true;
				   item.Day7_BgColor = 'red';
			   }else if(item.Day7 !=0 && item.Day7 <= item.MinAmount && !securityLevelReached){
				   securityLevelReached = true;
				   item.Day7_BgColor = '#ffc100';
			   }

			   if(item.Day8 == 0 && !zeroStockReached){
				   zeroStockReached = true;
				   item.Day8_BgColor = 'red';
			   }else if(item.Day8 !=0 && item.Day8 <= item.MinAmount && !securityLevelReached){
				   securityLevelReached = true;
				   item.Day8_BgColor = '#ffc100';
			   }

			   if(item.Day9 == 0 && !zeroStockReached){
				   zeroStockReached = true;
				   item.Day9_BgColor = 'red';
			   }else if(item.Day9 !=0 && item.Day9 <= item.MinAmount && !securityLevelReached){
				   securityLevelReached = true;
				   item.Day9_BgColor = '#ffc100';
			   }

			   if(item.Day10 == 0 && !zeroStockReached){
				   zeroStockReached = true;
				   item.Day10_BgColor = 'red';
			   }else if(item.Day10 !=0 && item.Day10 <= item.MinAmount && !securityLevelReached){
				   securityLevelReached = true;
				   item.Day10_BgColor = '#ffc100';
			   }

			   return item;
		   });
		   let tempTableHead = [];
		   dynamicTableHead.value =[];

		   let dateArr = [];
		   let fromDate = new Date(moment(res.from_date).format('MM-DD-YYYY'));
		   let endDate = new Date(moment(res.to_date).format('MM-DD-YYYY'));

		   avgBy.value = res.avg_by;
		   let day = 1;
		   tempTableHead.push({
					Alias: "Day"+day,
					Value: moment(fromDate).format('DD-MMM')
			})
		   while(fromDate < endDate){
				let newDate = fromDate.setDate(fromDate.getDate() + 1);
				fromDate = new Date(newDate);
				let dayNumber = fromDate.getDay();
				if(res.include_weekend || (!res.include_weekend && (dayNumber != 6 && dayNumber != 0)))
				{
					day = day+1;
					tempTableHead.push({
						Alias: "Day"+day,
						Value: moment(fromDate).format('DD-MMM')
					})
				}
			}

			dynamicTableHead.value = tempTableHead;
			isLoading.value = false;

		})
	}

    return {
		filterURL,
		getData,
		tableBody,
        staticTableHead,
		dynamicTableHead,
		tableHead,
		endpoint,
		isLoading
    };
  }
};
</script>