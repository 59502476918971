<template>
  <div class="ips-table table-scroll" :style="`${ maxHeight ? `max-height: ${maxHeight}vh;` : ''} `">
    <table>
      <TableHead :data="data" :actions="actions" @sorted="data => applySorting(data)" />
      <TableBody
        :data="data"
				:sortingData="sortingData"
        :actions="actions"
        :enableRenderFunctions="enableRenderFunctions"
        :itemRenderFunctions="itemRenderFunctions"
        :enableEditing="enableEditing"
        :fields="fields"
        :enablePagination="enablePagination"
        :paginationCurrentPage="paginationCurrentPage"
        :paginationLastPage="paginationLastPage"
				@change-item="item => $emit('change-item', item)"
      />
    </table>
    <p class="table-empty-message" v-if="!data.body.length"> {{ $t('general.table_empty_message') }}</p>
  </div>

        <!-- Pagination -->
      <div v-if="data !== null && paginationLastPage && enablePagination">
            <ul class="pagination">
                    
            <li class="paginate_button" :class="paginationCurrentPage == 1 ? 'disabled' : ''" @click="jumpToPage(paginationCurrentPage - 1)">
              <a href="javascript:void(0)">{{$t('general.previous')}}</a>
            </li>

            <li class="paginate_button" v-show="paginationCurrentPage != 1" @click="jumpToPage(1)">
              <a href="javascript:void(0)">1</a>
            </li>

            <li class="paginate_button" v-if="(paginationCurrentPage - 2) > 1" @click="jumpToPage(paginationCurrentPage - 2)">
              <a href="javascript:void(0)">{{ paginationCurrentPage - 2}}</a>
            </li>

            <li class="paginate_button" v-if="(paginationCurrentPage - 1) >= 2" @click="jumpToPage(paginationCurrentPage - 1)">
              <a href="javascript:void(0)">{{ paginationCurrentPage - 1}}</a>
            </li>

            <li class="paginate_button active ">
              <a ref="activePageButton" href="javascript:void(0)">
                <span>{{paginationCurrentPage}}</span>
              </a>
            </li>

            <li  class="paginate_button" v-if="(paginationCurrentPage + 1) < paginationLastPage" @click="jumpToPage(paginationCurrentPage + 1)">
              <a href="javascript:void(0)">{{ paginationCurrentPage + 1}}</a>
            </li>

            <li class="paginate_button" v-if="(paginationCurrentPage + 2) < paginationLastPage" @click="jumpToPage(paginationCurrentPage + 2)">
              <a href="javascript:void(0)">{{ paginationCurrentPage + 2}}</a>
            </li>

            <li class="paginate_button" v-show="paginationCurrentPage != paginationLastPage" @click="jumpToPage(paginationLastPage)">
              <a href="javascript:void(0)">{{paginationLastPage}}</a>
            </li>

            <li class="paginate_button" :class="paginationCurrentPage == paginationLastPage ? 'disabled' : ''" @click="jumpToPage(paginationCurrentPage + 1)">
              <a href="javascript:void(0)">{{$t('general.next')}}</a>
            </li>
          </ul>
      </div>
</template>

<script>
import { props } from "./table/common";
import TableBody from "./table/components/Body";
import TableHead from "./table/components/Head";
import { reactive } from 'vue';
export default {
  props: props,
	emits: ['change-item'],
  components: {
    TableBody,
    TableHead
  },
  setup(props, {emit}) {
		const log = val => console.log;
		let sortingData = reactive({key: false, descending: false})

		const applySorting = (data) => {
			sortingData.key = data.key;
			sortingData.descending = data.descending;
		}

    const jumpToPage = (pageNumber) => {
      emit('jumpToPage', pageNumber)
    }
		return {
			log,
			applySorting,
			sortingData,
      jumpToPage
		}
	}
};
</script>

<style lang="scss" scoped>
.ips-table {
  font-size: 0.8rem;
  margin-bottom: 2rem;
  position: relative;
  max-height: 70vh;
  @include scrollbar(4px);
  overflow-y: scroll;
  @include elevation(1);
  table {
    border-collapse: collapse;
    width: 100%;
    text-align: left;
  }
  border-radius: 15px 15px 15px 15px;
}

.pagination{
    display: flex;
    list-style: none;
    justify-content: flex-end;

    li{
      border: 1px solid $kits-greenBlue;
      padding: 6px 6px 6px 6px;
      min-width: 30px;
      text-align: center;
      cursor: pointer;
    }

    .paginate_button.active{
      background-color: $kits-freshGreen;
      border: 1px solid $kits-freshGreen;
    }

    .paginate_button.disabled{
      pointer-events: none;
      opacity: .6;
    }
}

.table-empty-message{
    text-align: center;
    font-size: 20px;
    opacity: .5;
}
</style>
