<template>
	<Suspense>
		<template #default>
			<Editor/>
		</template>
		<template #fallback>
			<GlobLoader/>
		</template>
	</Suspense>
</template>

<script>
import Editor from '../EditorNew';
export default {
	components: {
		Editor
	}
}
</script>