<template>
    <div class="kis-secure-cloud">
		<Navbar :items="routes" />
		<GlobSpinner :isLoading="isLoading"></GlobSpinner>
        <GlobRow>
			<GlobCol :medium="6">
				<GlobXpanel :title="$t('administration.secure_cloud.status')">
				<GlobTable
					:enableRenderFunctions="true"
					:maxHeight="50"
					:data="{
						head : statusTableHead,
						body : statusTableBody
					}"
				/>
				</GlobXpanel>
			</GlobCol>
            <GlobCol :medium="6">
				<GlobXpanel :title="$t('administration.secure_cloud.secure_cloud_servers')">
				<GlobTable
					:enableRenderFunctions="true"
					:itemRenderFunctions="cloudServerRenderFunctions"
					:maxHeight="50"
					:data="{
						head : cloudServerTableHead,
						body : cloudServerTableBody
					}"
				/>
				</GlobXpanel>
			</GlobCol>
		</GlobRow>

        <GlobRow>
			<GlobCol :medium="12">
				<GlobXpanel :title="$t('administration.secure_cloud.clients')" class="test">
				<GlobTable
					:enableRenderFunctions="true"
					:maxHeight="50"
					:data="{
						head : clientsHead,
						body : clientsBody
					}"
					:actions="clientsActions"
				/>
				</GlobXpanel>
			</GlobCol>
		</GlobRow>
    </div>
</template>

<script>
import { onMounted, ref, computed  } from "vue";
import { useApi } from "@/plugins/api";
import { useI18n } from 'vue-i18n'
import { routes } from "@/views/Admin/config";
import globSpinner from '../../../components/globals/glob-spinner.vue';

export default {
  components: { globSpinner },
 setup() {
	const { tm } = useI18n()
	const api = useApi();
	const endpoint = computed(() => api.secureCloud);
    
	const isLoading = ref(false);
	const statusTableBody  = ref([]);
	const statusTableHead = computed(() => 
	    [
			{
				Alias: "ServerAddress",
				Value: tm('administration')['secure_cloud']['fields']['server']
			},
			{
				Alias: "Clients",
				Value: tm('administration')['secure_cloud']['fields']['clients']
			}
		]
	);

    const cloudServerTableBody  = ref([]);
	const cloudServerTableHead = computed(() => 
	    [
			{
				Alias: "ServerAddress",
				Value: tm('administration')['secure_cloud']['fields']['server']
			},
			{
				Alias: "IsActive",
				Value: tm('administration')['secure_cloud']['fields']['active']
			},
            {
				Alias: "Clients",
				Value: tm('administration')['secure_cloud']['fields']['clients']
			},
            {
				Alias: "LastIpAssigned",
				Value: tm('administration')['secure_cloud']['fields']['last_ip_assigned']
			}
		]
	);

	const cloudServerRenderFunctions = computed(()=> {
		return {
			IsActive(obj){
				if(obj.IsActive)
				{
					return `<i class="fas fa-check success"></i>`;
				}else{
					return `<i class="fas fa-times danger"></i>`;
				}
				
			},
		}
	})

    const clientsBody  = ref([]);
    const clientsHead  = computed(() => 
	    [
			{
				Alias: "Customer",
				Value: tm('administration')['secure_cloud']['fields']['customer']
			},
			{
				Alias: "Locker",
				Value: tm('administration')['secure_cloud']['fields']['locker']
			},
            {
				Alias: "SecureIp",
				Value: tm('administration')['secure_cloud']['fields']['secure_ip']
			},
            {
				Alias: "PublicIp",
				Value: tm('administration')['secure_cloud']['fields']['public_ip']
			},
            {
				Alias: "LastContact",
				Value: tm('administration')['secure_cloud']['fields']['last_contact']
			}
		]
	);
	
	
	const clientsActions = computed(() => [
			{
				name : tm('administration')['secure_cloud']['ping'],
				color: "green",
				action: (obj) => {
					
				}
			},
		]
	)
	
	 onMounted(async() => {
          getData();
	 });

	const getData = async() => {
		isLoading.value = true;
		endpoint.value.get().then(function(res){
           statusTableBody.value = res.status;
           cloudServerTableBody.value = res.secure_clouds;
           clientsBody.value = res.clients;
		   isLoading.value = false;
		})
	}

    return {
		routes,
		//Variables
		endpoint,
		statusTableBody,
		isLoading,
        statusTableHead,
        cloudServerTableBody,
        cloudServerTableHead,
        clientsBody,
        clientsHead,
        clientsActions,
		cloudServerRenderFunctions
    };
  }
};
</script>

<style lang="scss">
.kis-secure-cloud{
	i.success{
	color: $kits-parisGreen;
	font-size: 22px;

}
i.danger{
	color: $kits-crimsonRed;
	font-size: 22px;

}
}

</style>
