<template>
	<div class="ips-status-field">
		<div class="ips-status-field__left">
			<Breadcrumbs/>
		</div>
		<div class="ips-status-field__right">
		   <NotificationsToggle />
		   <User/>
		  <LocaleChanger />
			
		</div>
	</div>
	<div id="loader-portal"></div>
	<div id="status-field-portal"></div>
</template>

<script>
import User from './status/User';
import Breadcrumbs from './status/Breadcrumbs';
import NotificationsToggle from './status/NotificationCenter/Toggle';
import { ref } from 'vue';
export default {
	components: {
		User,
		NotificationsToggle,
		Breadcrumbs
	},
}
</script>

<style lang="scss" scoped>
	.ips-status-field {
		z-index: 9;
		top: 0;
		height: 40px;
		background-color: $kits-lightGray;
		padding-left: .5rem;
		display: flex;
		align-items: center;
		font-size: .8rem;
		&__right {
			display: flex;
			align-items: center;
			margin-left: auto;
		}

		&__left {
			border-bottom: 1px solid #f0f1f3;
			display: flex;
			width: 70%;
			padding-bottom: 5px;
		}
	}

	//New version
	.ips-status-field{
		background-color: unset !important;
		padding-left: 14px;
	}
</style>