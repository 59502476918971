<template>
  <div v-if="loaded" >
    <div class="kits-top-button-row">
      <router-link class="button button--linearGray" :to="`/admin/view/${manager.content.config.slug}`"
        >Tillbaka till översikt</router-link
      >
      <button v-if="prevItem" :style="'margin-left: auto;'" @click="goToPrev()" class="button">Föregående</button>
      <button v-if="nextItem" :style="!prevItem ? 'margin-left: auto;' : ''" @click="goToNext()" class="button">Nästa</button>
    </div>
    <div v-if="manager.hasTemplate()">
      <component v-bind="{ manager, router }" :is="templateInstance" />
    </div>
    <div v-else>
      <GlobXpanel
        :bigTitle="true"
        v-if="manager.state.single"
        :title="manager.state.single[manager.content.config.template.name]"
        :subTitle="manager.state.single[manager.content.config.template.subTitle]"
      >
        <FieldGroup
          v-for="group in manager.content.config.fieldGroups"
          :key="'fg' + group.key"
          :data="manager.state.single"
          :group="group"
          @input="(data) => handleChange(data)"
        />
        <button @click="save" class="button" :disabled="noChanges">Spara</button>
      </GlobXpanel>
    </div>
  </div>
</template>

<script>
import { contentTypes } from "./config";
import { content, validator, createManager } from "./core";
import FieldGroup from "./molecules/FieldGroup.vue";
import { useApi } from "@/plugins/api";
import { computed, ref, reactive, onMounted, watchEffect, shallowRef } from "vue";
import { useRouter } from "vue-router";
export default {
  components: {
    FieldGroup
  },
  async setup() {
    const router = useRouter();
    const manager = await createManager(router.currentRoute.value.params.slug, router.currentRoute.value.params.id);
    const data = ref(manager.state.single);

    const templateInstance = shallowRef(false);
    const getTemplate = () => {
      return new Promise((resolve, reject) => {
        if (manager.hasTemplate()) {
          import(`./templates/${manager.content.config.template.component}.vue`)
            .then((res) => {
              resolve(res);
            })
            .catch((err) => {
              throw new Error(
                `No template with name '${manager.content.config.template.component}' was found. Check your spelling.`
              );
            });
        } else {
          resolve(false);
        }
      });
    };
    if (manager.hasTemplate()) {
      await getTemplate().then((res) => {
        templateInstance.value = res.default;
      });
		}
		
		const loaded = computed(() => manager.state.loaded)
		const nextItem = computed(() => manager.nextItem.value)
		const prevItem = computed(() => manager.prevItem.value)

    const slug = computed(() => router.currentRoute.value.params.slug);
    const selectedContent = computed(() => content.getTypeBySlug(slug.value));

    const noChanges = ref(true);

    const handleChange = (dataObject) => {
      Object.keys(data.value).forEach((item) => {
        if (dataObject[item] !== undefined) {
          data.value[item] = dataObject[item];
        }
      });
      noChanges.value = manager.validator.check(data.value);
    };

    const save = () => {
      manager.endpoint.update(data.value.Id, data.value).then((res) => {
        noChanges.value = true;
        router.push(`/admin/view/${slug.value}`);
      });
    };

    const goToNext = () => {
			router.push(`/admin/edit/${manager.content.config.slug}/${manager.nextItem.value}`);
			manager.getSingle(manager.nextItem.value).then(res => {
				data.value = manager.state.single;
			})
    };

    const goToPrev = () => {
			router.push(`/admin/edit/${manager.content.config.slug}/${manager.prevItem.value}`);
			manager.getSingle(manager.prevItem.value).then(res => {
				data.value = manager.state.single;
			})
    };

    /* 

    onMounted(async () => {
			console.log(manager)
      if (!hasTemplate()) {
        getById();
      } else {
				await getTemplate().then(res => {
					templateInstance.value = res.default;
				});
			}
    }); */

    return {
      selectedContent,
      data,
      handleChange,
      noChanges,
      save,
      content,
      templateInstance,
			manager,
			router,
			goToNext,
			goToPrev,
			loaded,
			nextItem,
			prevItem
    };
  }
};
</script>

<style lang="scss">
.kits-top-button-row {
  display: flex;
}
</style>
