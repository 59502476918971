<template>
 <div>	
		 <GlobLoader v-if="isLoading"/>
		<GlobRow v-else>
			<GlobCol :medium="12">
				<GlobXpanel :title="$t('billing.statement.statements')">
					<InputText type="text" v-model="searchString" :placeholder="$t('general.search')" :style="'padding: .4rem 1rem; margin-bottom: 1.5rem; margin-top: 1rem;'"/>
					<template v-if="['superadmin', 'admin'].includes(userRole)">
						<button class="button button--margin button--blue ml-2 generate-statement" @click="generateBills()">{{$t('billing.statement.generate_statement')}}</button>
					</template>
				<GlobTable
					:enableRenderFunctions="true"
					:data="{
						head : statementListHead,
						body : filteredStatementList
					}"
					:actions="actions"
				/>
				</GlobXpanel>
			</GlobCol>
		</GlobRow>

		<!-- View statement modal -->
		<GlobModal :show="viewStatementModal && !showCreditStatementConfirmModal" @close="viewStatementModal = false" :width="60" :title="selectedStatement.Title">
			<div class="view-statement-modal">
			<GlobRow>
				<GlobCol :medium="6">
					<label>{{$t('billing.statement.fields.customer_name')}}</label>
					<p><b>{{selectedStatement.CustomerName}}</b></p>
				</GlobCol>
				<GlobCol :medium="6">
					<button class="button button--small credit" @click="downloadPDF()">{{$t('billing.statement.download')}}</button>
					<template v-if="!selectedStatement.credit_statement && !selectedStatement.DebitStatementId && ['superadmin', 'admin'].includes(userRole)">
						<button class="button button--small credit" @click="showCreditStatementConfirmModal = true">{{$t('billing.statement.credit')}}</button>
					</template>
					<template v-else-if="selectedStatement.credit_statement">
						<button class="button button--small credit" @click="viewCreditStatement">{{$t('billing.statement.view_credit_statement')}}</button>
					</template>
					<template v-else-if="selectedStatement.DebitStatementId">
						<button class="button button--small credit" @click="viewDebitStatement">{{$t('billing.statement.view_debit_statement')}}</button>
					</template>
				</GlobCol>
			</GlobRow>
			<GlobRow>
				<GlobCol :medium="3">
					<label>{{$t('billing.statement.fields.period_start')}}</label>
					<p><b>{{selectedStatement.PeriodStart}}</b></p>
				</GlobCol>
				<GlobCol :medium="3">
					<label>{{$t('billing.statement.fields.period_stop')}}</label>
					<p><b>{{selectedStatement.PeriodStop}}</b></p>
				</GlobCol>
				<GlobCol :medium="3">
					<label>{{$t('billing.statement.fields.billing_date')}}</label>
					<p><b>{{selectedStatement.BillingDate}}</b></p>
				</GlobCol>
			</GlobRow>
			<GlobRow>
				<GlobCol :medium="12">
					<div class="row-break"></div>
				</GlobCol>
			</GlobRow>
			<GlobRow>
				<GlobCol :medium="3">
					<label>{{$t('billing.statement.fields.billed_net_amount')}}</label>
					<p><b>{{selectedStatement.BilledNetAmount}}</b></p>
				</GlobCol>
				<GlobCol :medium="3">
					<label>{{$t('billing.statement.fields.tax_amount')}}</label>
					<p><b>{{selectedStatement.TaxAmount}}</b></p>
				</GlobCol>
				<GlobCol :medium="3">
					<label>{{$t('billing.statement.fields.discount_pct')}}</label>
					<p><b>{{selectedStatement.DiscountPct}}</b></p>
				</GlobCol>
			</GlobRow>
			<GlobRow>
				<GlobCol :medium="12">
					<div class="row-break"></div>
				</GlobCol>
			</GlobRow>
			<GlobRow>
				<GlobCol :medium="3">
					<label>{{$t('billing.statement.fields.paid_amount')}}</label>
					<p><b>{{selectedStatement.PaidAmount}}</b></p>
				</GlobCol>
				<GlobCol :medium="3">
					<label>{{$t('billing.statement.fields.remaining_amount')}}</label>
					<p><b>{{selectedStatement.RemainingAmount}}</b></p>
				</GlobCol>
			</GlobRow>
			<GlobRow>
				<GlobCol :medium="12">
					<h3>{{$t('billing.statement.statement_details')}}</h3>
					<GlobTable
						:enableRenderFunctions="true"
						:maxHeight="40"
						:data="{
							head : statementDetailListHead,
							body : statementDetailList
						}"
					/>
				</GlobCol>
			</GlobRow>
			</div>
		</GlobModal>

		<!-- Credit statement confirm modal -->
		<GlobConsequence :show="showCreditStatementConfirmModal" 
			@close="showCreditStatementConfirmModal=false"  
			@confirm = "creditStatement()"
			:title="$t('billing.statement.credit_statement')" 
			:message="$t('billing.statement.credit_statement_confirm')"  />
</div>
</template>

<script>
import { onMounted, computed, watch, ref , getCurrentInstance } from "vue";
import { useApi } from "@/plugins/api";
import { useRouter } from "vue-router";
import { useI18n } from 'vue-i18n'
import { useNotifications } from "@/plugins/notifications";
import { useStore } from 'vuex';
import { downloadFromBlob } from "@/services/utils";
import axios from "axios";


export default {

 setup() {
	const store = useStore();
    const userRole = store.getters.GET_USER.UserRole;

	const { tm } = useI18n()
	const api = useApi();
	const router = useRouter();
	const notifications = useNotifications();
	const endpoint = computed(() => api.billingStatements);
    
	const isLoading = ref(false);
	const viewStatementModal = ref(false);
	const showCreditStatementConfirmModal = ref(false);
	const selectedStatement = ref({});
	const loaded = ref(false);
	const searchString = ref('');
	const statementList  = ref([]);
	const statementDetailList  = ref([]);

	const statementListHead = computed(() => 
	    [{
				Alias: "Id",
				Value: tm('billing')['statement']['fields']['id']
			},
            {
				Alias: "CustomerName",
				Value: tm('billing')['statement']['fields']['customer_name']
			},
			{
				Alias: "Title",
				Value: tm('billing')['statement']['fields']['title']
			},
			{
				Alias: "PeriodStart",
				Value: tm('billing')['statement']['fields']['period_start']
			},
            {
				Alias: "PeriodStop",
				Value: tm('billing')['statement']['fields']['period_stop']
			},
            {
				Alias: "BillingDate",
				Value: tm('billing')['statement']['fields']['billing_date']
			},
             {
				Alias: "BilledNetAmount",
				Value: tm('billing')['statement']['fields']['billed_net_amount']
			},
            {
				Alias: "TaxAmount",
				Value: tm('billing')['statement']['fields']['tax_amount']
			},
            {
				Alias: "PaidAmount",
				Value: tm('billing')['statement']['fields']['paid_amount']
			},
            {
				Alias: "RemainingAmount",
				Value: tm('billing')['statement']['fields']['remaining_amount']
			},
			 {
				Alias: "IsCreditStatement",
				Value: tm('billing')['statement']['fields']['is_credit']
			},
            
		]
	
	);
	const statementDetailListHead = computed(() => 
		    [
				{
					Alias: "Locker",
					Value: tm('billing')['statement']['fields']['locker']
				},
				{
					Alias: "PeriodStart",
					Value: tm('billing')['statement']['fields']['period_start']
				},
				{
					Alias: "PeriodStop",
					Value: tm('billing')['statement']['fields']['period_stop']
				},
				{
					Alias: "BilledAmount",
					Value: tm('billing')['statement']['fields']['billed_amount']
				},
				{
					Alias: "BilledDays",
					Value: tm('billing')['statement']['fields']['billed_days']
				},
				{
					Alias: "DiscountPct",
					Value: tm('billing')['statement']['fields']['discount_pct']
				},
				
			]
	);
	
	
	const actions = computed(() => [
			{
				name : tm('general')['button']['view'],
				color: "green",
				action: (obj) => {
					showStatement(obj);
				}
			},
		]
	)

	const showStatement = (obj) => {
		statementDetailList.value = [];
		viewStatementModal.value = true;
		selectedStatement.value = obj;
		statementDetailList.value = obj.billing_statement_detail.map((item) => {
			if(item.locker){
				item.Locker = item.locker.Name;
			}else{
				item.Locker = '';
			}
			return item;
		});
	}
	
	 onMounted(async() => {
          loadStatements();
	 });

	const loadStatements = async() => {
		endpoint.value.get().then(function(res){
           statementList.value = res.statements.map((item) => {
				item.IsCreditStatement = item.DebitStatementId ? tm('general')['yes'] : tm('general')['no'];
				return item;
		   });
		})
	}

	const creditStatement = () => {
		endpoint.value.creditStatement(selectedStatement.value.Id).then(function(res){
			showCreditStatementConfirmModal.value = false;
			viewStatementModal.value = false;
			// selectedStatement.value = {};
			notifications.push(
				{
					msg: tm('billing')['statement']['credit_statement_success']
				},
				"success",
				false
			);
			loadStatements();
		})
	}

	const viewCreditStatement = () => {
		const creditStateMent = statementList.value.find(i => i.Id == selectedStatement.value.credit_statement.Id);
		showStatement(creditStateMent);
	}

	const viewDebitStatement = () => {
		const creditStateMent = statementList.value.find(i => i.Id == selectedStatement.value.DebitStatementId);
		showStatement(creditStateMent);
	}

	const generateBills = () => {
		isLoading.value = true;
		endpoint.value.generateBill().then(function(res){
			notifications.push(
					{
						msg: tm('billing')['statement']['generate_success']
					},
					"success",
					false
			);
           loadStatements();
		   isLoading.value = false;
		})
	}


	const filteredStatementList = computed(() => {
       return searchString.value.length > 0 ? 
	   			statementList.value.filter(i =>  
				   (i.Title && i.Title.toLowerCase().match(searchString.value.toLowerCase())) 
					|| (i.CustomerName && i.CustomerName.toLowerCase().match(searchString.value.toLowerCase())) 
					|| (i.PeriodStart && i.PeriodStart.toLowerCase().match(searchString.value.toLowerCase()))
					|| (i.PeriodStop && i.PeriodStop.toLowerCase().match(searchString.value.toLowerCase())) 
					|| (i.BillingDate && i.BillingDate.toLowerCase().match(searchString.value.toLowerCase())) 
					|| (i.BilledNetAmount && i.BilledNetAmount.toString().match(searchString.value)) 
					|| (i.TaxAmount && i.TaxAmount.toString().match(searchString.value)) 
					|| (i.PaidAmount && i.PaidAmount.toString().match(searchString.value)) 
					|| (i.RemainingAmount && i.RemainingAmount.toString().match(searchString.value))
				) : statementList.value
	});

	const downloadPDF = () => {
		console.log('$http', axios)
		console.log('endpoint.value', endpoint.value)
		axios({
			url: 'billing-statements/'+selectedStatement.value.Id+'/download',
			method: 'GET',
			responseType: 'blob', // <= important
			}).then((response) => {
				var fileURL = window.URL.createObjectURL(new Blob([response.data]));
				var fileLink = document.createElement('a');
			
				fileLink.href = fileURL;
				fileLink.setAttribute('download', 'billing-statement.pdf');
				document.body.appendChild(fileLink);
			
				fileLink.click();
			});
	};

    return {
		//Methods
		loadStatements,
		//Variables
		endpoint,
		filteredStatementList,
		statementList,
		statementListHead,
		searchString,
		actions,
		loaded,
		viewStatementModal,
		selectedStatement,
		statementDetailListHead,
		statementDetailList,
		showCreditStatementConfirmModal,
		creditStatement,
		userRole,
		viewCreditStatement,
		viewDebitStatement,
		generateBills,
		isLoading,
		downloadPDF
    };
  }
};
</script>

<style lang="scss">
.view-statement-modal{
	margin-top: 16px;

	label{
		font-weight: 500;
		display: inline-block;
		margin-bottom: 0.5rem;
	}

	p{
		margin: 0!important;
		font-family: unset;
	}

	.ips-modal__content{
    	padding: 1.5rem !important;
	}

	.row-break{
		border-top: 2px solid #E6E9ED;
	}

	button.credit{
		float: right;
	}
}

button.generate-statement{
	float: right;
    margin: 0;
    margin-top: 1rem;

}
</style>