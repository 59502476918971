<template>
	<GlobModal :show="showModal" :width="300" :widthInPx="true" :title="props.title" @close='$emit("close")'>
		<p class="ips-globmodal-confirm-message">{{props.message}}</p>
		<div class="ips-globmodal-confirm-btns mt-4">
			<button class="button button--red" @click='$emit("confirm")'
			>{{$t('general.yes')}}</button>
			<button class="button ml-2 button--orange"
			@click='$emit("close")'>{{$t('general.no')}}</button>
		</div>
	</GlobModal>
</template>

<script>

import { computed } from 'vue';
export default {
  props: ['show', 'title', 'message', 'action'],
  emits: ['close', 'confirm'],
  setup(props) {
		const showModal = computed(() => props.show);
		return {
			showModal,
			props
		}
	}
};
</script>
