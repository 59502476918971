import Home from '@/views/Statistics'
import Graphs from '@/views/Statistics/Graphs'
import Realtime from '@/views/Statistics/Realtime'
import Reports from '@/views/Statistics/Reports'

export const statistics = {
	name: 'Statistics',
	path: '/statistics',
	component: Home,
	nav: {
		name: "Statistik",
		icon: "ruler",
	},
	children: [
		{
			path: '/statistics/graphs',
			component: Graphs,
			nav: {
				name: 'Grafer'
			},
			meta: {
				breadcrumbs: [
					{name: 'Grafer'}
				]
			}
		},
		{
			path: '/statistics/graphs',
			component: Realtime,
			nav: {
				name: 'Rapporter'
			},
			meta: {
				breadcrumbs: [
					{name: 'Rapporter'}
				]
			}
		},
		{
			path: '/statistics/graphs',
			component: Reports,
			nav: {
				name: 'Realtid'
			},
			meta: {
				breadcrumbs: [
					{name: 'Realtid'}
				]
			}
		}
	]
}