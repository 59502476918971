<template>
 <div>
	 <Navbar :items="routes" />
		<GlobRow>
			<GlobSpinner :isLoading="isLoading"/>
			<GlobCol :medium="quickSelected ? 7 :12">
				<GlobXpanel :title="$t('administration.article_group.article_groups')">
					<button @click="showCreateModal = true" style="margin-bottom: 1rem; display: inline-block;" class="button button--margin button--small">{{$t('general.create_new')}}</button>
					<InputText type="text" v-model="searchString" :placeholder="$t('general.search')" :style="'margin-left: 1rem; padding: .4rem 1rem'"/>
					<GlobTable
						:enableRenderFunctions="true"
						:itemRenderFunctions="renderFunctions"
						:maxHeight="50"
						:data="{
							head : tableHead,
							body : filteredArticleGroups
						}"
						:actions="actions"
					/>
				</GlobXpanel>
			</GlobCol>
			<GlobCol v-if="quickSelected" :medium="5">
				<GlobXpanel :title="$t('general.button.quick_edit')">
					<div class="ips-admin-fieldgroup">
						<div class="ips-admin-quickedit">
							<label>
							{{$t('administration.article_group.fields.name')}}*
							<InputDynamic
								:fullWidth="true"
								styling="width: 100%; padding: .5rem;"
								:type="'text'"
								v-model="dataObject.Name"
							/>
							</label>
							<label>
							{{$t('administration.article_group.fields.description')}}
							<InputDynamic
								:fullWidth="true"
								styling="width: 100%; padding: .5rem;"
								:type="'text'"
								v-model="dataObject.Description"
							/>
							</label>
							<button :disabled="!dataObject.Name" @click="save" class="button kis-mt-2">
							{{$t('general.button.save')}}
							</button>
							<button @click="quickSelected = false; dataObject = {};" class="button button--orange">
							{{$t('general.button.cancel')}}
							</button>
						</div>
					</div>
				</GlobXpanel>
			</GlobCol>
		</GlobRow>
</div>

<!-- Create articleGroup modal -->
<GlobModal :widthInPx="1000" :title="$t('administration.article_group.create')" @close="showCreateModal = false" :show="showCreateModal" :isCRUD="true" v-if="showCreateModal">
	<CreateArticleGroup @created="() => created()" />
</GlobModal>
<!-- Edit articleGroup modal -->
<GlobModal :widthInPx="1000" :title="selectedArticleGroup.Name" @close="selectedArticleGroup = null" :show="selectedArticleGroup && selectedArticleGroup.Id && !hideEditModal" v-if="selectedArticleGroup && selectedArticleGroup.Id" :isCRUD="true">
	<EditArticleGroup 
		:articleGroupId="selectedArticleGroup.Id" 
		:loading="articleModalLoading"
		:reloadData="reloadArticleEdit"
		:isPrevActive="isPrevActive"
		:isNextActive="isNextActive"
		@goToPrev="goToPrev()"
		@goToNext="goToNext()"
		@updated="(articleGroup) => updated(articleGroup)"
		@showADeleteConfirmModal="showADeleteConfirmModal = true"/>
</GlobModal>

	<!-- Delete confirm modal -->
	<GlobConsequence :show="showADeleteConfirmModal" 
		@close="showADeleteConfirmModal=false"  
		@confirm = "deleteArticleGroup()"
		:title="$t('administration.article_group.delete')" 
		:message="$t('administration.article_group.delete_confirm')"  />
</template>

<script>
import { onMounted, computed, watch, ref, watchEffect } from "vue";
import { useApi } from "@/plugins/api";
import { useRouter } from "vue-router";
import Navbar from "@/views/Admin/Navbar.vue";
import { routes } from "@/views/Admin/config";
import { useI18n } from 'vue-i18n';
import { useNotifications } from "@/plugins/notifications";
import CreateArticleGroup from "@/views/crud/ArticleGroup/Create.vue";
import EditArticleGroup from "@/views/crud/ArticleGroup/Edit.vue";

export default {
components: {
	Navbar,
	CreateArticleGroup,
	EditArticleGroup
},
 setup() {
    const { tm } = useI18n();
	const api = useApi();
	const router = useRouter();
	const endpoint = computed(() => api.articlegroups);
    
	const selectedArticleGroup = ref(null);
	const isPrevActive = ref(false);
	const isNextActive = ref(false);

	const showCreateModal = ref(false);
	const showADeleteConfirmModal = ref(false)
	const reloadArticleEdit = ref(false);
	const articleModalLoading = ref(false);
	const hideEditModal = computed(() =>{
		return showADeleteConfirmModal.value ? true : false;
	})


	const file = ref(null);
	 const fileInput = ref(null);
	const isLoading = ref(false);
	const notifications = useNotifications();
	const showArchived = ref(false);
	const loaded = ref(false);
	const searchString = ref('');
	const articleGroupList  = ref([]);
	const tableHead = ref([
			{ Alias: "ImagePath", Value: "" },
			{
				Alias: "Id",
				Value: computed(() => tm('administration')['article_group']['fields']['id'])
			},
			{
				Alias: "Name",
				Value: computed(() => tm('administration')['article_group']['fields']['name'])
			},
			{
				Alias: "Description",
				Value: computed(() => tm('administration')['article_group']['fields']['description'])
			},
		]);
	
	const actions = computed(() => { return [
      {
        name: tm('general')['button']['edit'],
        color: "green",
            action: (obj, key) => {
				 openEditModal(obj)
            }
      },
	  {
        name: tm('general')['button']['quick_edit'],
        color: "blue",
        action: (obj) => {
            setEditor(obj);
        }
      },
    ]
	});
	const renderFunctions = computed(()=> {
		return {
			ImagePath(obj){
				return `<i class="fas fa-${obj.ImagePath ? 'image' : ''}"></i>`;
			},
			GroupAmount(obj){
				return `<i title="${obj.GroupAmount > 0 ? 'Artikeln finns i grupp' : ''}" class="fas fa-${obj.GroupAmount > 0 ? 'object-group' : ''}"></i>`;
			}
		}
	})
	
	 onMounted(async() => {
          loadArticleGroups();
	 });

	const loadArticleGroups = async() => {
		isLoading.value = true;
		endpoint.value.get().then(function(res){
           articleGroupList.value = res.data;
		   isLoading.value = false;
		})
	}

	const filteredArticleGroups = computed(() => {
		return searchString.value.length > 0 ? articleGroupList.value
				.filter(
					i => (i.Name && i.Name.toLowerCase().match(searchString.value.toLowerCase()))
					) : articleGroupList.value;
	})

	const dataObject = ref({});
	const quickSelected = ref(false);
	const setEditor = (obj) => {
		dataObject.value.Id = obj.Id;
		dataObject.value.Name = obj.Name;
		dataObject.value.Description = obj.Description;
		dataObject.value.IsQuickEdit = true;
		quickSelected.value = true;
    };

	const img = computed(() => (file.value ? URL.createObjectURL(file.value) : dataObject.value.ImagePath));

	 const formData = new FormData();
	 const addImage = (files) => {
		file.value = files[0];
		formData.append("image", files[0]);
    };

	const save = () => {
		isLoading.value = true;
		endpoint.value.update(dataObject.value.Id, dataObject.value).then((res) => {
			notifications.push(
					{
						msg: tm('administration')['article_group']['update_success']
					},
					"success",
					false
			);
			updated(dataObject.value);
			dataObject.value = {};
			quickSelected.value = false;
			isLoading.value = false;
			
		});
	};

	const created = () => {
		showCreateModal.value = false;  
		loadArticleGroups();
	}

	const updated = (articleGroup) => {
		let articleIndex = articleGroupList.value.findIndex((i) => i.Id == articleGroup.Id)
		articleGroupList.value[articleIndex].Name = articleGroup.Name;
		articleGroupList.value[articleIndex].Description = articleGroup.Description;
		articleGroupList.value[articleIndex].ImagePath = articleGroup.ImagePath;
	}

	const openEditModal = (obj) => {
		isPrevActive.value = undefined;
		isNextActive.value = undefined;
		
		const index = filteredArticleGroups.value.findIndex(i => i.Id == obj.Id);

		if(filteredArticleGroups.value[index+1]){
		isNextActive.value = index+1;
		}
		if(filteredArticleGroups.value[index-1]){
		isPrevActive.value = index-1;
		}
		selectedArticleGroup.value = obj;
	}

	const goToPrev = () => {
		openEditModal(filteredArticleGroups.value[isPrevActive.value])
	}

	const goToNext = () => {
		openEditModal(filteredArticleGroups.value[isNextActive.value])
	}

	const deleteArticleGroup = () => {
		showADeleteConfirmModal.value = false;
		articleModalLoading.value = !articleModalLoading.value;

		endpoint.value.delete(selectedArticleGroup.value.Id).then((res) => {
			notifications.push(
					{
						msg: tm('administration')['article_group']['delete_success']
					},
					"success",
					false
			);
			selectedArticleGroup.value = null;
			loadArticleGroups();
		});
	}

	const uploadImage = () => {
		isLoading.value = true;
		endpoint.value.uploadImage(dataObject.value.Id, file.value).then((res) => {
			getArticle(dataObject.value.Id)
			quickSelected.value = false;
			dataObject.value = {};
			file.value = null;
			fileInput.value = null;
		});
    };

	const getArticle = (articleGroupId) => {
		endpoint.value.getById(articleGroupId).then(function(res){
			updated(res.data)
			isLoading.value = false;
		});
	}

	const deleteImage = () => {
	  isLoading.value = true;
      endpoint.value.deleteImage(dataObject.value.Id).then((res) => {
		  getArticle(dataObject.value.Id)
		  quickSelected.value = false;
		  file.value = null;
		  fileInput.value = null;
		  dataObject.value = {};
      });
    };

	 watchEffect(() => {
      if (fileInput.value) {
        fileInput.value = null;
      }
    });


    return {
		loadArticleGroups,
		filteredArticleGroups,
		endpoint,
		articleGroupList,
		tableHead,
		renderFunctions,
		searchString,
		actions,
		routes,
		loaded,
		showArchived,
		quickSelected,
		dataObject,
		save,
		isLoading,
		file,
		img,
		uploadImage,
		deleteImage,
		addImage,
		selectedArticleGroup,
		hideEditModal,
		showCreateModal,
		created,
		reloadArticleEdit,
		articleModalLoading,
		//delete
		showADeleteConfirmModal,
		deleteArticleGroup,
		updated,
		isPrevActive,
		isNextActive,
		openEditModal,
		goToPrev,
		goToNext
    };
  }
};
</script>