<template>
  <input
    class="kits-input"
		:class="fullWidth ? 'full-width' : ''"
    type="number"
    :value="modelValue"
    @input="$emit('update:modelValue', $event.target.value)"
  />
</template>

<script>
import { props } from "./common";
export default {
  props: props
};
</script>
