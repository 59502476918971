<template>
  <teleport to="#loader-portal">
		<div class="kits-loader">
			<div class="kits-loader__bar"></div>
			<div class="kits-loader__bar kits-loader__bar--second"></div>
		</div>
  </teleport>
</template>

<style lang="scss">
	.kits-loader {
		height: 4px;
		background-color: $kits-gray;
		width: 100%;
		position: relative;
		&__bar {
			position: absolute;
			left: 0%;
			background-color: $kits-green;
			height: 100%;
			width: 0%;
			animation: slide 2s ease-in-out infinite;
			&--second {
				background-color: darken($kits-green, 10%);
				animation: slide 2s ease-in-out infinite;
				animation-delay: 1s;
			}
		}
	}

	@keyframes slide {
		0% {
			width: 0%;
			left: 0%;
		}
		30% {
			left: 0%;
		}
		50% {
			width: 80%;
			left: 30%;
		}
		100% {
			width: 10%;
			left: 100%;
		}
	}
</style>