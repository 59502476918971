<template>
 <div>
		<GlobRow>
			<GlobSpinner :isLoading="isLoading"></GlobSpinner>
			<GlobCol :medium="12">
				<GlobXpanel :title="$t('billing.price_plan.price_plans')">
					<router-link :to="{name: 'CreatePricePlan'}" style="margin-bottom: 1rem; display: inline-block;" class="button button--margin button--small">{{$t('general.create_new')}}</router-link>
					<InputText type="text" v-model="searchString" :placeholder="$t('general.search')" :style="'margin-left: 1rem; padding: .4rem 1rem'"/>
				<GlobTable
					:enableRenderFunctions="true"
					:enableEditing="true"
	    			:fields="fields"
					 @change-item="(item) => updateItem(item)"
					:maxHeight="50"
					:data="{
						head : pricePlanListHead,
						body : filteredPricePlanList
					}"
					:actions="actions"
				/>
				</GlobXpanel>
			</GlobCol>
		</GlobRow>
</div>
</template>

<script>
import { onMounted, computed, watch, ref , getCurrentInstance } from "vue";
import { useApi } from "@/plugins/api";
import { useRouter } from "vue-router";
import { useI18n } from 'vue-i18n'
import globSpinner from '../../../components/globals/glob-spinner.vue';

export default {
  components: { globSpinner },

 setup() {

	const { tm } = useI18n()
	const api = useApi();
	const router = useRouter();
	const endpoint = computed(() => api.pricePlans);
	 const isLoading = ref(false);
    
	const loaded = ref(false);
	const searchString = ref('');
		const pricePlanList = ref([]);

	const fields = [
		{ key: "IsDefault", type: "checkbox", textForTrueStatement:  tm('general')['yes'] }
	];
	const pricePlanListHead = computed(() => 
	    [{
				Alias: "Id",
				Value: tm('billing')['price_plan']['fields']['id']
			},
			{
				Alias: "Name",
				Value: tm('billing')['price_plan']['fields']['name']
			},
			{
				Alias: "ResellerCustomerName",
				Value: tm('billing')['price_plan']['fields']['reseller_customer']
			},
            {
				Alias: "BillingType",
				Value: tm('billing')['price_plan']['fields']['billing_type']
			},
			 {
				Alias: "IsDefault",
				Value: tm('billing')['price_plan']['fields']['is_default']
			},
            {
				Alias: "StartDate",
				Value: tm('billing')['price_plan']['fields']['start_date']
			},
			{
				Alias: "StopDate",
				Value: tm('billing')['price_plan']['fields']['stop_date']
			},
		]
	
	 );
	
	
	const actions = computed(() => [
			{
				name : tm('general')['button']['edit'],
				color: "green",
				action: (obj) => {
					router.push({
							name: 'EditPricePlan', 
							params: { id: obj.Id }
						})
				}
			},
		]
	)
	
	 onMounted(async() => {
          loadPricePlans();
	 });

	const loadPricePlans = async() => {
		endpoint.value.get().then(function(res){
			pricePlanList.value = res.price_plans;
			isLoading.value = false;
		})
	}

	const updateItem = (item) => {
		isLoading.value = true;
		endpoint.value.makeDefault(item.Id).then(function (res) {
			loadPricePlans();
		});
		
	}

	const filteredPricePlanList = computed(() => {
       return searchString.value.length > 0 ? pricePlanList.value.filter(i => i.Name && i.Name.toLowerCase().match(searchString.value.toLowerCase())) : pricePlanList.value
	});

    return {
		//Methods
		loadPricePlans,
		//Variables
		endpoint,
		filteredPricePlanList,
		pricePlanList,
		pricePlanListHead,
		searchString,
		actions,
		loaded,
		fields,
		updateItem,
		isLoading
    };
  }
};
</script>