<template>
	<div class="tabs">
		<header class="tab-header">
			<ul class="tab-header__heads">
				<li 
				class="tab-head"
				v-for="tab in tabs"
				:key="tab"
				:class="{
					'tab-head--active' : activeTab === tab
				}"
				@click="switchTab(tab)"
				>
					<slot :name="tabHeadSlotName(tab)">
						<span class="tab-head__title">{{ tab }}</span>
					</slot>
				</li>
			</ul>
		</header>
		<main class="tab-content" v-if="keepState">
			<div class="tab-content__panel" v-show="activeTab === tab" v-for="tab in tabs" :key="`tab-conent-${tab}`" >
				<slot :name="`tab-panel-${tab}`"></slot>
			</div>
		</main>
		<main class="tab-content" v-else>
			<div class="tab-content__panel" v-for="tab in tabs" :key="`tab-conent-${tab}`" >
				<slot v-if="activeTab === tab" :name="`tab-panel-${tab}`"></slot>
			</div>
		</main>
	</div>
</template>

<script>
import { ref, reactive, computed } from 'vue';
export default {
	props: {
		initialTab: {
			type: String,
			required: true
		},
		tabs: {
			type: Array,
			required: true
		},
		add: {
			type: Boolean,
			required: false
		},
		keepState: {
			type: Boolean,
			required: false,
			default: false
		},
		search: {
			type: String,
			required: false
		}
	},
	setup(props, { emit }) {
		const { initialTab } = reactive(props)

		const tabHeadSlotName = tabName => {
			return `tab-head-${tabName}`
		}

		const activeTab = ref(initialTab)
		const switchTab = tabName => {
			activeTab.value = tabName;
		}

		const tabPanelSlotName = computed(() => `tab-panel-${activeTab.value}`)

		return {
			activeTab,
			switchTab,
			tabHeadSlotName,
			tabPanelSlotName
		}
	}
}
</script>

<style lang="scss">
.tabs {
	margin-bottom: 1rem;
	.tab-header {
		margin-bottom: -1rem;
		margin-left: 1rem;

		&__heads {
			display: flex;
			list-style-type: none;
			padding: 0;
		}
	}
	.tab-head {
		padding: .8rem 1.5rem;
		cursor: pointer;
		border-top-left-radius: .5rem;
		border-top-right-radius: .5rem;
		margin-right: 1rem;
		background-color: #E6E9ED;
		border-top: 1px solid #E6E9ED;
		border-left: 1px solid #E6E9ED;
		border-right: 1px solid #E6E9ED;
		&__title {
			color: $kits-steelGray;
			padding: .1rem 1rem;
			margin: .5rem 0;
			border-radius: 100px;
			font-weight: 700;
			font-size: .8rem;
		}
		&--active {
		position: relative;
		background-color: white;

			&:after {
				content: '';
				display: block;
				position: absolute;
				background-color: white;
				height: 1px;
				bottom: -1px;
				left: 0;
				right: 0;
			}

		}
		&.add-tab {
			font-size: 1.5rem;
			padding: 0;
			padding-top: .5rem;
		}
	}
	.tab-content {
		border: 1px solid #E6E9ED;
		background-color: white;
		padding: 1rem;
		&__panel {

		}
	}
	.tab-search-bar {
		display: flex;
		position: relative;
		width: 100%;
		&__input {
			input {
				padding-right: 3.5rem;
				height: 1.5rem;
				border-radius: 100px;
				border-color:#E6E9ED;
				font-size: .8rem;
			}
		}
		&__button {
			position: absolute;
			right: -1px;
			top: 0;
			bottom: 0;
			height: 100%;
			padding: 0 1rem;
			display: block;
			margin: 0;
			border: none;
			background-color: #E6E9ED;
			border-radius:100px;
			&:focus {
				outline: 0;
			}
		}
	}
}
</style>