
import ArticleGroup from "@/views/crud/ArticleGroup/ArticleGroups.vue";
import Create from "@/views/crud/ArticleGroup/Create.vue";
import Edit from "@/views/crud/ArticleGroup/Edit.vue";

export const articleGroup = [{
  path: "/admin/view/article-groups",
  component: ArticleGroup,
  isCRUD: true,
  name: "ArticleGroup",
  nav: {
    name: "Admin",
    icon: "sliders-h"
  },
  meta: {
    noPadding: true,
    access: ['superadmin', 'admin', 'reseller'],
    breadcrumbs: [{ name: "Admin", link: "/admin" }, { name: "Översikt" }]
  },
},
{
  path: "/admin/create/article-group",
  component: Create,
  isCRUD: true,
  name: "createArticle",
  nav: {
    name: "Admin",
    icon: "sliders-h"
  },
  meta: {
    noPadding: true,
    access: ['superadmin', 'admin', 'reseller'],
    breadcrumbs: [{ name: "Admin", link: "/admin" }, { name: "Skapa" }]
  }
},
{
  path: "/admin/edit/article-group/:id?",
  component: Edit,
  isCRUD: true,
  name: "editArticle",
  nav: {
    name: "Admin",
    icon: "sliders-h"
  },
  meta: {
    noPadding: true,
    access: ['superadmin', 'admin', 'reseller'],
    breadcrumbs: [{ name: "Admin", link: "/admin" }, { name: "Redigera" }]
  }
}]
