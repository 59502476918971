<template>
	<GlobXpanel :noShadow="true"  :avoidHR="true" :isCRUD="true">
		<GlobSpinner :isLoading="isLoading"/>
		<GlobXpanel :noShadow="true" :tools="false" :avoidHR="true">
			<label style="font-size: .8rem; max-width: 800px; display: block;">
				{{$t('administration.article.fields.article_number')}}
				<InputDynamic
				:fullWidth="true"
				styling="margin-bottom: .5rem;"
				:type="'text'"
				:placeholder="$t('administration.article.fields.article_number')"
				v-model="article.form.ArticleNumber"
				/>
			</label>

			<div v-if="errorMessage">
				<span class="text-danger" >{{errorMessage}}</span>
			</div>
			<button @click="save" class="button kis-mt-4" :disabled="!article.form.ArticleNumber">{{$t('general.button.save')}}</button>
		</GlobXpanel>
	</GlobXpanel>

</template>

<script>
import {onMounted, reactive, ref, computed, watchEffect } from "vue";
import { useI18n } from 'vue-i18n';
import { useApi } from "@/plugins/api";
import {useRouter } from "vue-router";
import { useNotifications } from "@/plugins/notifications";

export default {
  setup(props, {emit}) {
    const { tm } = useI18n();
	const endpoint = computed(() => api.articles);
	const api = useApi();
	const router = useRouter();

	const notifications = useNotifications();
	const errorMessage = ref(null);
	const isLoading = ref(false);

	const article = reactive({
		form: {
			ArticleNumber: "",
		},
	});

    const save = () => {
		errorMessage.value = null;
		isLoading.value = true;
		endpoint.value.create({ArticleNumber: article.form.ArticleNumber}).then((res) => {
			isLoading.value = false;
			if(res.errors && res.errors.ArticleNumber){
				errorMessage.value = tm(res.errors.ArticleNumber[0]);
			}else{
				notifications.push(
					{
						msg: tm('administration')['article']['create_success']
					},
					"success",
					false
				);
				emit('articleCreated', res.article)
			}
		});
    };

    return {
      save,
	  errorMessage,
	  article,
	  isLoading
    };
  },
};
</script>

<style lang="scss">
.kits-edit-article {
  &__image {
    width: 200px;
    display: block;
    margin-bottom: 0.5rem;
  }
}
.kits-article-lockers {
  display: flex;
	margin: 0.5rem 1rem 0rem 0rem;
	> div {
		margin-right: 2rem;
	}
  p {
    font-size: 0.8rem;
    text-decoration: uppercase;
  }
  &__list {
		display: flex;
		&--small {
			font-size: .6rem;
		}
  }
  span {
    padding: 0.35rem 1rem;
    border-radius: 4px;
		@include elevation(1);
    margin-right: 1.5rem;
		font-size: 0.8rem;
		align-items: center;
		display: flex;
		a {
			display: flex;
			font-size: 0.8rem;
			align-items: center;
		}
    i {
      color: $kits-steelGray;
      margin-right: 0.8rem;
    }
  }
}
</style>
