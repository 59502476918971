<template>
	<div>
		<Navbar :items="routes" />
        <div class="kits-top-button-row">
            <router-link class="button button--linearGray" :to="'/admin/view/devices'"
                >Tillbaka till översikt</router-link>
        </div>
		   <GlobXpanel title="Edit device" style="max-width: 900px;">
            		<div class="ips-admin-fieldgroup">
						<GlobXpanel :noShadow="true" :tools="false" :title="'General'">
							<label style="font-size: .8rem; max-width: 800px; display: block;">
								Name*
								<InputDynamic
								:fullWidth="true"
								styling="margin-bottom: .5rem;"
								:type="'text'"
								:placeholder="'Name'"
								v-model="device.form.Name"
								/>
							</label>
							<label style="font-size: .8rem; max-width: 800px; display: block;">
								Description
								<InputDynamic
								:fullWidth="true"
								styling="margin-bottom: .5rem;"
								:type="'text'"
								:placeholder="'Description'"
								v-model="device.form.Description"
								/>
							</label>
							<label style="font-size: .8rem; max-width: 800px; display: block;">
								Location
								<InputDynamic
								:fullWidth="true"
								styling="margin-bottom: .5rem;"
								:type="'text'"
								:placeholder="'Location'"
								v-model="device.form.Location"
								/>
							</label>
						</GlobXpanel>
					</div>
                    <div class="ips-admin-fieldgroup">
						<GlobXpanel :noShadow="true" :tools="false" :title="'Function'">
							<label style="font-size: .8rem; max-width: 800px; display: block;">
								Server address
								<InputDynamic
								:fullWidth="true"
								styling="margin-bottom: .5rem;"
								:type="'text'"
								:placeholder="'Server address'"
								v-model="device.form.ServerAddress"
								/>
							</label>
						</GlobXpanel>
					</div>
                    <div class="ips-admin-fieldgroup">
						<GlobXpanel :noShadow="true" :tools="false" :title="'Wifi'">
							<label style="font-size: .8rem; max-width: 800px; display: block;">
								SSID
								<InputDynamic
								:fullWidth="true"
								styling="margin-bottom: .5rem;"
								:type="'text'"
								:placeholder="'SSID'"
								v-model="device.form.SSID"
								/>
							</label>
							<label style="font-size: .8rem; max-width: 800px; display: block;">
								Passcode
								<InputDynamic
								:fullWidth="true"
								styling="margin-bottom: .5rem;"
								:type="'text'"
								:placeholder="'Passcode'"
								v-model="device.form.Passcode"
								/>
							</label>
						</GlobXpanel>
					</div>
					<div v-if="errorMessage">
                        <p class="text-danger mt-2" >{{errorMessage}}</p>
					</div>
				<button class="button" :disabled="!device.form.Name" @click="update()">Skapa</button>
				<router-link class="button button--orange" :to="'/admin/view/devices'"
                >Avbryt</router-link>
				<button class="button button--red" @click="deviceDeleteConfirmModal = true">Radera</button>
		</GlobXpanel>

		<GlobConsequence :show="deviceDeleteConfirmModal" 
			 @close="deviceDeleteConfirmModal=false"  
			 @confirm = "deleteDevice()"
			 :title="'Delete device'" 
			 :message="'Do you want to delete the device?'"  />
	</div>
</template>
<script>
import { onMounted, computed, watch, ref, reactive } from "vue";
import { useApi } from "@/plugins/api";
import { useRoute, useRouter } from "vue-router";
import { useNotifications } from "@/plugins/notifications";
import Navbar from "@/views/Admin/Navbar.vue";
import { routes } from "@/views/Admin/config";
import { useI18n } from 'vue-i18n';

export default {
components: {
	Navbar
},
 setup() {

	const { tm } = useI18n();
	const api = useApi();
	const router = useRouter();
	const route = useRoute();
	const notifications = useNotifications();

	const endpoint = computed(() => api.devices);
    const isLoading = ref(false);
	const errorMessage = ref(null);
	const deviceDeleteConfirmModal = ref(false);
    const device = reactive({
		form: {
			Name: "",
			Description: "",
			Location: "",
			ServerAddress: "",
			SSID: "",
			Passcode: "",
			Hostname: ""
		},
	});
		
	 onMounted(async() => {
        getDevice();
	 });

	 const getDevice = () => {
		endpoint.value.getById(route.params.id).then(function(res){
			if(!res.success){
				notifications.push(
					{
						msg: tm('general')['something_went_wrong']
					},
					"warning",
					false
					);
				router.push(`/admin/view/devices`);
			}
			device.form.deviceId = res.device.Id;
			device.form.Name = res.device.Name;
			device.form.Location = res.device.Location;
			device.form.Description = res.device.Description;
			device.form.SSID = res.device.WifiSSID;
			device.form.Passcode = res.device.WifiPSK;
			device.form.Hostname = res.device.WifiHostName;
			device.form.ServerAddress = res.device.SwitchboardAddress;
		});
	 };
	const update = () => {
		errorMessage.value = null;
		endpoint.value.update(route.params.id, device.form).then(function(res){
           if(res.success){
				notifications.push(
					{
						title: "Device update",
						msg: `Device updated successfully`
					},
					"success",
					false
					);
				router.push('/admin/view/devices')
		   }else {
				if (res.errors) {
					for (const item in res.errors) {
						errorMessage.value = res.errors[item][0];
						break;
					}
				}
		   }
		})
	 }

	 const deleteDevice = () => {
		endpoint.value.delete(route.params.id).then(function(res){
           if(res.success){
			deviceDeleteConfirmModal.value = false;
			notifications.push(
				{
					title: "Device deleted",
					msg: `Device deleted successfully`
				},
				"success",
				false
				);
			   router.push('/admin/view/devices')
		   }
		})
	 }

     	
    return {
		routes,
		device,
		isLoading,
		update,
		errorMessage,
		route,
		getDevice,
		deviceDeleteConfirmModal,
		deleteDevice
    };
  }
};
</script>

<style scoped>

  .ips-device-inactive-field{
	margin-bottom: 0 !important;
    visibility: hidden;
    height: 0;
	position: absolute;
}

</style>
