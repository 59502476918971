<template>
  <GlobRow v-if="loaded">
      <GlobCol :medium="12" class="home-welcome-messge">
          <p class="welcome">{{$t('general.welcome')}},</p> <p class="user-name">{{user.Name}}</p>
      </GlobCol>
      <!--
      <ChartSpeedometer
        :name="'Aktiva skåp ( % )'"
        :score="score"
        :min="0"
        :max="100"
        :gradingData="[
          {
            title: 'Låg',
            color: '#ee1f25',
            lowScore: 0,
            highScore: 30
          },
          {
            title: 'Medel',
            color: '#f3eb0c',
            lowScore: 30,
            highScore: 70
          },
          {
            title: 'Hög',
            color: '#0f9747',
            lowScore: 70,
            highScore: 100
          }
        ]"
      />-->
    <GlobCol :medium="8">
      <ChartLine :name="'Transaktioner'" :axisX="'date'" :axisY="'value'" :data="transactions" />
    </GlobCol>
  </GlobRow>
  <div v-else>
		<GlobLoader/>
	</div>
</template>

<script>
import { useApi } from "@/plugins/api";
import { reactive, computed, ref } from "vue";
import { useStore } from 'vuex'
export default {
  name: "Home",
  setup() {
    const api = useApi();
    const loaded = ref(false);

    const store = useStore();
    const user = computed(() => store.state.USER);

    const lockers = reactive({
      total: 0,
      active: 0
    });
    const transactions = ref(null);
    const score = computed(() => {
      return Math.floor((parseInt(lockers.active) / parseInt(lockers.total)) * 100);
    });
    const getData = async () => {
      await api.lockers.get().then((res) => {
        lockers.total = res.data.length;
        lockers.active = res.data.reduce((a, c) => {
          if (c.LockerActive) {
            a += 1;
          }
          return a;
        }, 0);
      });
      await api.analytics.getTransactions().then((res) => {
        transactions.value = res.data.reduce((a, c) => {
          if (!a.find((i) => i.name == c.Locker)) {
            a.push({
              name: c.Locker,
              data: res.data
                .filter((item) => item.Locker == c.Locker)
                .map((i) => ({
                  date: new Date(i.TransactionDate),
                  value: i.TransactionAmount
                }))
            });
          }
          return a;
        }, []);
      });
      loaded.value = true;
    };

    getData();

    return {
      lockers,
      score,
      loaded,
      transactions,
      user
    };
  }
};
</script>

<style lang="scss">
.done {
  text-decoration: line-through;
}

.home-welcome-messge{
  color: $kits-steelGrayV1;
  .user-name{
    font-weight: 500;
  }
  p{
    margin: 0;
    display: inline-block;
    font-family: 'Inter';
    font-style: normal;
    font-size: 30px;
    line-height: 36px;
  }
  .welcome{
    font-weight: 100;
  }
}
</style>
