<template>
  <GlobXpanel :noShadow="true"  :avoidHR="true" :isCRUD="true">
	<GlobSpinner :isLoading="isLoading"/>
	<GlobXpanel :noShadow="true" :tools="false" :avoidHR="true">
		<div class="kits-article-lockers">
		<div>
			<p>{{$t('administration.article.available_in_lockers')}}:</p>
			<div class="kits-article-lockers__list">
			<span v-for="locker in article.form.Lockers.filter((i) => i.CustomerName)" :key="locker.LockerName"><router-link :to="`/storage/locker/${locker.Id}`"><i class="fas fa-warehouse"></i> <div><div class="kits-article-lockers__list--small">{{locker.CustomerName}}</div>{{ locker.LockerName }}</div></router-link></span>
			</div>
		</div>
		<div>
			<p>{{$t('administration.article.available_in_groups')}}:</p>
			<div class="kits-article-lockers__list">
			<span v-for="group in article.form.ArticleGroups.filter((i) => i.Name)" :key="group.Name"
				><i class="fas fa-object-group"></i> {{ group.Name }}</span
			>
			</div>
		</div>
		</div>
	</GlobXpanel>
    <GlobXpanel :noShadow="true" :tools="false" :title="$t('administration.article.picture')">
      <div class="kits-dropzone" v-dropzone="{ callback: (data) => log(data) }">
        <img v-if="img" class="kits-edit-article__image" :src="img" /><br />
        <input ref="fileInput" type="file" accept="image/*" @change="addImage($event.target.files)" />
      </div>
	    <button :disabled="!file" @click="uploadImage" class="button button--margin kis-mt-4">{{$t('administration.article.save_image')}}</button>
		<button :disabled="!img" @click="deleteImage" style="margin-left: 1rem;" class="button button--red button--margin">
		{{$t('administration.article.remove_image')}}
		</button>
    </GlobXpanel>
	<GlobXpanel :noShadow="true" :tools="false" :title="'General'">
		<label style="font-size: .8rem; max-width: 800px; display: block;">
			{{$t('administration.article.fields.article_number')}}*
			<InputDynamic
			:fullWidth="true"
			styling="margin-bottom: .5rem;"
			:type="'text'"
			:placeholder="$t('administration.article.fields.article_number')"
			v-model="article.form.ArticleNumber"
			:disabled="true"
			/>
		</label>

		<label style="font-size: .8rem; max-width: 800px; display: block;">
			{{$t('administration.article.fields.name')}}*
			<InputDynamic
			:fullWidth="true"
			styling="margin-bottom: .5rem;"
			:type="'text'"
			:placeholder="$t('administration.article.fields.name')"
			v-model="article.form.Name"
			/>
		</label>

		<label style="font-size: .8rem; max-width: 800px; display: block;">
			{{$t('administration.article.fields.description')}}
			<InputDynamic
			:fullWidth="true"
			styling="margin-bottom: .5rem;"
			:type="'text'"
			:placeholder="$t('administration.article.fields.description')"
			v-model="article.form.Description"
			/>
		</label>

		<div class="article-grp-locker-selector">
			<label>
				{{$t('administration.article.fields.article_groups')}}
				<InputDynamic
				:fullWidth="true"
				styling="margin-bottom: .5rem;"
				:type="'multiselect'"
				:placeholder="$t('administration.article.fields.article_groups')"
				v-model="article.form.ArticleGroupIds"
				:data="articleGroups"
				:selected="article.form.ArticleGroupIds"
				/>
			</label>
			<label>
				{{$t('administration.article.fields.lockers')}}
				<InputDynamic
				:fullWidth="true"
				styling="margin-bottom: .5rem;"
				:type="'multiselect'"
				:placeholder="$t('administration.article.fields.lockers')"
				v-model="article.form.LockerIds"
				:data="lockers"
				:selected="article.form.LockerIds"
				/>
			</label>
		</div>

		<button @click="save" class="button kis-mt-4" :disabled="!article.form.Name">{{$t('general.button.save')}}</button>
		<button @click="$emit('showArticleArchiveConfirmModal', true)" class="button button--red" v-if="!article.form.IsArchived">{{$t('general.button.archive')}}</button>
		<button @click="$emit('showArticleUnArchiveConfirmModal', true)" class="button button--red" v-else>{{$t('general.button.un_archive')}}</button>
		<button @click="$emit('showArticleDeleteConfirmModal', true)" class="button button--red">{{$t('general.button.delete')}}</button>
	</GlobXpanel>
	<div v-if="errorMessage">
		<p class="text-danger mt-2" >{{errorMessage}}</p>
	</div>
  </GlobXpanel>

</template>

<script>
import {onMounted, reactive, ref, computed, watchEffect, watch, toRaw } from "vue";
import { useI18n } from 'vue-i18n';
import { useApi } from "@/plugins/api";
import { useRoute, useRouter } from "vue-router";
import { useNotifications } from "@/plugins/notifications";

export default {
  props: ["manager", "router", "articleId", "reloadData", "loading"],
  setup(props, {emit}) {
    const { tm } = useI18n();
	const endpoint = computed(() => api.articles);
	const api = useApi();
	const router = useRouter();
	const route = useRoute();

	const notifications = useNotifications();
    const reloadDataLocal = ref(props.reloadData);
	
	watch(() => props.reloadData, (selection, prevSelection) => { 
		getArticle();
	})

	watch(() => props.loading, (selection, prevSelection) => { 
		isLoading.value = true;
	})

    const fileInput = ref(null);
    const file = ref(null);
	const isLoading = ref(true);
	const errorMessage = ref(null);

	const articleGroups = ref([]); 
	const lockers = ref([]);
	const article = reactive({
		form: {
			Id: "",
			Name: "",
			ArticleNumber: "",
			Lockers: [],
			ArticleGroups: [],
			IsArchived: "",
			ImagePath: "",
			ArticleGroupIds: [],
			LockerIds: []
		},
	});

	onMounted(async() => {
        getArticle();
	 });

	 const getArticle = (updateMainList = false) => {
		isLoading.value = true;
		endpoint.value.getById(props.articleId, {
			includeDependecies: true
		}).then(function(res){
			if(!res.data){
				notifications.push(
					{
						msg: tm('general')['something_went_wrong']
					},
					"warning",
					false
					);
				router.push(`/admin/view/articles`);
			}
			article.form.Id = res.data.Id;
			article.form.Name = res.data.Name;
			article.form.ArticleNumber = res.data.ArticleNumber;
			article.form.Description = res.data.Description;
			article.form.Lockers = res.data.Lockers;
			article.form.ArticleGroups = res.data.ArticleGroups;
			article.form.ImagePath = res.data.ImagePath;
			article.form.IsArchived = res.data.IsArchived;

			articleGroups.value = res.article_groups.map(function(articleGroup) {
				return {
					name: articleGroup.Name,
					value: articleGroup.Id
				}
			});
			lockers.value = res.lockers.map(function(locker) {
				return {
					name: locker.Name,
					value: locker.Id
				}
			});

			//Set selected lcokers and groups values
			article.form.ArticleGroupIds = article.form.ArticleGroups.map(group => group.Id);
			article.form.LockerIds = article.form.Lockers.map(locker => locker.Id);

			isLoading.value = false;

			if(updateMainList){
				emit('articleUpdated', res.data);
			}
		});
	 };


    const formData = new FormData();
    const img = computed(() => (file.value ? URL.createObjectURL(file.value) : article.form.ImagePath));
    const addImage = (files) => {
      file.value = files[0];
      formData.append("image", files[0]);
    };
    const hasImage = ref(file.value || (article.form.ImagePath && article.form.ImagePath.length > 0));

    const uploadImage = () => {
	  isLoading.value = true;
      endpoint.value.uploadImage(article.form.Id, file.value).then((res) => {
		getArticle(true)
        file.value = null;
        fileInput.value.value = null;
      });
    };

    const deleteImage = () => {
	  isLoading.value = true;
      endpoint.value.deleteImage(article.form.Id).then((res) => {
		getArticle(true)
      });
    };

    const data = ref(article.form);

    const save = () => {
		errorMessage.value = null;
		isLoading.value = true;
		endpoint.value.update(data.value.Id, data.value).then((res) => {
			//Check whether any error exists when create item
			if(res && res.success == 0 && res.errors){
				for (const item in res.errors) {
					errorMessage.value = tm(res.errors[item][0]);
					break;
				}
				return false;
			}
			notifications.push(
					{
						msg: tm('administration')['article']['update_success']
					},
					"success",
					false
			);
			isLoading.value = false;
			getArticle(true)
		});
    };
	

    const log = (val) => console.log;

    watchEffect(() => {
      if (article.form.Id && fileInput.value) {
        fileInput.value.value = null;
      }
    });

    return {
	  isLoading,
      addImage,
      save,
      hasImage,
      img,
      file,
      uploadImage,
      log,
      fileInput,
      deleteImage,
	  errorMessage,
	  article,
	  articleGroups,
	  lockers,
	  reloadDataLocal,
	  ...props
    };
  },
};
</script>

<style lang="scss">
.kits-edit-article {
  &__image {
    width: 200px;
    display: block;
    margin-bottom: 0.5rem;
  }
}
.kits-article-lockers {
  display: flex;
	margin: 0.5rem 1rem 2rem 1rem;
	> div {
		margin-right: 2rem;
	}
  p {
    font-size: 0.8rem;
    text-decoration: uppercase;
  }
  &__list {
		display: flex;
		&--small {
			font-size: .6rem;
		}
  }
  span {
    padding: 0.35rem 1rem;
    border-radius: 4px;
		@include elevation(1);
    margin-right: 1.5rem;
		font-size: 0.8rem;
		align-items: center;
		display: flex;
		a {
			display: flex;
			font-size: 0.8rem;
			align-items: center;
		}
    i {
      color: $kits-steelGray;
      margin-right: 0.8rem;
    }
  }
}

  .article-grp-locker-selector{
	    width: 100%;
		display: flex;
		max-width: 800px;
		gap: 20px;

		label{
			font-size: .8rem;
			flex: 1;
		}
  }
</style>
