<template>
<div>
	<GlobSpinner :isLoading="isLoading"></GlobSpinner>
  <button @click="setUserModal(true)" class="button">Lägg till användare</button>
  <GlobTable
    :data="users"
    :actions="actions"
		style="max-width: 1000px;"
  />
	<GlobModal
		:show="userModal"
		:width="30"
		:title="'Lägg till artiklar'"
		@close="setUserModal(false)"
	>
	<InputText :fullWidth="true" placeholder="Sök användare" v-model="userSearchString" @input="searchUser"/>
	<GlobTable
		:data="searchResult"
		:actions="addActions"
		style="height: 30vh"
	/>
	</GlobModal>
	<GlobModal
		:show="userTransactionsModal"
		:width="50"
		@close="setUserTransactionModal(false)"
		title="Transaktioner"
	>
		<GlobSpinner :isLoading="isLoadingTransactionModal" ></GlobSpinner>
		<GlobTable :data="userTransactions"/>
	</GlobModal>
</div>
</template>

<script>
import { useRouter } from "vue-router";
import { reactive, onMounted, ref } from "vue";
import { useApi } from "@/plugins/api";
import { useNotifications } from '@/plugins/notifications';
export default {
  setup(props) {
    const router = useRouter();
		const api = useApi();
		const isLoading = ref(false);
		const id = ref(router.currentRoute.value.params.id);
		const notifications = useNotifications();
		const isLoadingTransactionModal = ref(false);

		const getData = async () => {
			isLoading.value = true;
			await api.lockers.getUsers(id.value).then((res) => {
				users.body = res.data;
				isLoading.value = false;
      });
			searchUser();
		};
		
    const users = reactive({
      body: [],
      head: [
        { Alias: "Name", Value: "Förnamn" },
        { Alias: "LastName", Value: "Efternamn" },
        { Alias: "Pin", Value: "Pin" },
		{ Alias: "ArticleAmount", Value: "Antal uttag" },
      ]
    });

    const deleteUser = (item) => {
      api.lockers.deleteUser(id.value, item.Id).then(res => {
				getData();
				notifications.push({
					title: 'Användare borttagen',
					msg: `Tog bort <b>&#160;${item.Name} ${item.LastName}</b>`
				}, 'success', false)
			});
		};
    const actions = [
      {
        name: "Transaktioner",
        color: "green",
        action: (obj) => {
          showUserTransactions(obj);
        }
      },
      {
        name: "Radera",
        color: "red",
        requirements: {
          consequence: (obj) => `Är du säker på att du vill radera <b>${obj.Name}</b> som användare för det här skåpet?`
        },
        action: (obj) => {
          deleteUser(obj);
        }
      }
		];

		const userTransactionsModal = ref(false);
		const userTransactions = reactive({
			body: [],
			head: [
        { Alias: "ArticleName", Value: "Artikel" },
        { Alias: "TransactionDate", Value: "Transaktionsdatum" },
		{ Alias: "Amount", Value: "Antal" },
		{ Alias: "Aborted", Value: "Avbruten" },
			]
		});
		const setUserTransactionModal = (bool) => {
			userTransactionsModal.value = bool;
		}
		const showUserTransactions = (user) => {
			setUserTransactionModal(true);
			isLoadingTransactionModal.value = true;
			api.lockers.getUserTransactions(id.value, user.aId).then(res => {
				userTransactions.body = res.data
				isLoadingTransactionModal.value = false;
			})
		}

		const userSearchString = ref('');
		const userModal = ref(false);
		const searchResult = reactive({
			body: [],
			head: [
        { Alias: "Name", Value: "Förnamn" },
        { Alias: "LastName", Value: "Efternamn" },
		{ Alias: "Pin", Value: "Pin" },
		{ Alias: "EndCustomer", Value: "Företag" },
      ]
		});
		const setUserModal = (bool) => {
			userModal.value = bool;
		}
		const searchUser = () => {
			api.lockers.searchUser(id.value, userSearchString.value).then(res => {
				searchResult.body = res.data;
			})
		}

    const addActions = [
      {
        name: "Lägg till",
        color: "green",
        action: (obj) => {
          addUser(obj);
        }
      }
		];
		const addUser = (user) => {
			api.lockers.addUser(id.value, user.AccountId).then(res => {
				getData();
				notifications.push({
					title: 'Användare tillagd',
					msg: `Lade till <b>&#160;${user.Name} ${user.LastName}</b>`
				}, 'success', false)
			})
		}

		const log = (val) => console.log(val);
		
		onMounted(() => {
			getData();
		})

		return {
		isLoading,
      users,
			actions,
			addActions,
			searchUser,
			searchResult,
			setUserModal,
			userModal,
			userSearchString,
			userTransactions,
			userTransactionsModal,
		setUserTransactionModal,
			isLoadingTransactionModal,
      log
    };
  }
};
</script>
