<template>
  <div>
		<div class="kits-top-button-row">
      <router-link class="button button--linearGray" :to="`/admin/view/${manager.content.config.slug}`"
        >Tillbaka till översikt</router-link
      >
    </div>
    <div v-if="manager.hasCreateTemplate()">
      <component v-bind="{ manager }" :is="templateInstance" />
    </div>
    <GlobXpanel
      v-else
      :bigTitle="true"
      :title="
        `Skapa ${(manager.content.config.singular && manager.content.config.singular.toLowerCase()) ||
          manager.content.name.toLowerCase()}`
      "
    >
      <FieldGroup
        v-for="group in fieldGroups"
        :key="'fg' + group.key"
        :data="data"
        :group="group"
        @input="(data) => handleChange(data)"
      />
	<div v-if="errorMessage">
		<p class="text-danger mt-2" >{{errorMessage}}</p>
	</div>
      <button @click="create" class="button" :disabled="noChanges">Spara</button>
    </GlobXpanel>
  </div>
</template>

<script>
import { createManager } from "./core";
import { useRouter } from "vue-router";
import { ref, shallowRef } from "vue";
import FieldGroup from "./molecules/FieldGroup.vue";
import { useI18n } from 'vue-i18n';

export default {
  components: {
    FieldGroup
  },
  async setup() {
    const { tm } = useI18n();
    const router = useRouter();
	const errorMessage = ref(null);
    const manager = await createManager(router.currentRoute.value.params.slug, false, {
      getData: false
    });

    const data = ref(manager.model);
    manager.validator.update(data.value);

    const templateInstance = shallowRef(false);
    const getTemplate = () => {
      return new Promise((resolve, reject) => {
        import(`./templates/${manager.content.config.createTemplate.component}.vue`)
          .then((res) => {
            resolve(res);
          })
          .catch((err) => {
            reject(err);
            throw new Error(
              `No template with name '${manager.content.config.createTemplate.component}' was found. Check your spelling.`
            );
          });
      });
    };
    if (manager.hasCreateTemplate()) {
      await getTemplate().then((res) => {
        templateInstance.value = res.default;
      });
    }

    const noChanges = ref(true);
    const handleChange = (dataObject) => {
      Object.keys(data.value).forEach((item) => {
        if (dataObject[item] !== undefined) {
          data.value[item] = dataObject[item];
        }
      });

      noChanges.value = manager.validator.check(data.value);
    };
    const fieldGroups = manager.content.config.fieldGroups.map((item) => {
      return {
        ...item,
        fields: manager.content.config.create
          ? item.fields.filter((i) => manager.content.config.create.includes(i.key))
          : item.fields
      };
    });

    const create = () => {
	  errorMessage.value = null;
      manager.endpoint.create(data.value).then((res) => {
		
		//Check whether any error exists when create item
		if(res && res.success == 0 && res.errors){
			for (const item in res.errors) {
				errorMessage.value = tm(res.errors[item][0]);
				break;
			}
			return false;
		}

        if(manager.content.config.redirectAfterSave == 'edit'){
           router.push(`/admin/edit/${manager.content.config.slug}/${res}`)
        }else{
           router.push(`/admin/view/${manager.content.config.slug}`)
        }
      });
    };

    return {
		manager,
		data,
		noChanges,
		handleChange,
		create,
		fieldGroups,
		templateInstance,
		errorMessage
    };
  }
};
</script>
