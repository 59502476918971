<template>
	<div>
		<GlobSpinner :isLoading="isLoading"></GlobSpinner>
			<GlobTable
			:data="orderpoints"
			:enableEditing="true"
		:fields="fields"
			/>
	</div>

</template>

<script>
import { onMounted, reactive, ref} from 'vue';
import { useRouter } from 'vue-router';
import { useApi } from '@/plugins/api';
export default {
	setup() {
		const api = useApi();
		const router = useRouter();
		const isLoading = ref(false);
		const id = ref(router.currentRoute.value.params.id);
		const orderpoints = reactive({
			body: [],
			head: [
				{Alias: 'Articlenumber', Value: 'Art.nr'},
				{Alias: 'ArticleName', Value: 'Artikel'},
				{Alias: 'CurrentVolume', Value: 'Antal'},
				{Alias: 'Shelf', Value: 'Hylla'},
				{Alias: 'Placement', Value: 'Plats'},
				{Alias: 'PlaceOrderAmount', Value: 'Beställningspunkt'},
				{Alias: 'MaxStock', Value: 'Maxlager'},
			]
		})
		const getOrderpoints = () => {
			isLoading.value = true;
			api.lockers.getOrderpoints(id.value).then(res => {
				orderpoints.body = res.data;
				isLoading.value = false;
			})
		}

		const fields = [
      { key: "CurrentVolume", type: "number" },
      { key: "Shelf", type: "number" },
      { key: "Placement", type: "number" },
      { key: "PlaceOrderAmount", type: "number" },
      { key: "MaxStock", type: "number" }
    ];

		onMounted(() => {
			getOrderpoints();
		})

		return {
			orderpoints,
			fields,
			isLoading
		}
	}
}
</script>