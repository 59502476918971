import Storage from '@/views/Storage';
import Lockers from '@/views/Storage/Lockers';
import SingleLocker from '@/views/Storage/SingleLocker';

export const storage = {
	name: 'Storage',
	path: '/storage',
	component: Storage,
	nav: {
		name : 'general.storage',
		icon: "pallet",
	},
	children: [
		{
			path: '/storage/lockers',
			component: Lockers,
			nav: {
				name: 'Skåp'
			},
			meta: {}
		},
		{
			path: '/storage/locker/:id',
			component: SingleLocker,
			meta: {
				breadcrumbs: [
					{name: 'Förvaring', link: '/storage/lockers'},
					{name: 'Redigera skåp'},
				]
			}
		}
	]
}