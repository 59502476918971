<template>
	<Suspense>
		<template #default>
			<Create/>
		</template>
		<template #fallback>
			<GlobLoader/>
		</template>
	</Suspense>
</template>

<script>
import Create from '../Create.vue';
export default {
	components: {
		Create
	}
}
</script>