<template>
    <div class="kis__spinner" :class="{'top-right': isTopRight}" :id="'kis__spinner-'+uniqueId" v-show="isLoading" :style="isCenterToPage ? 'position: fixed' : ''">
        <span><i class="fas fa-cog fa-spin fa-5x"></i></span>
        <h5 class="kis__spinner-label" v-show="isLoading && loadingMsg">{{loadingMsg}}</h5>
    </div>
</template>

<script>
export default {
	props: {
		isLoading: {
			type: Boolean,
			required: false,
            default: false
		},
        isCenterToPage: {
            type: Boolean,
            default: false,
            required: false,
        },
        isTopRight: {
            type: Boolean,
            default: false,
            required: false,
        },
        loadingMsg: {
            type: String,
            default: null,
            required: false,
        }
	},
    data(){
        return {
            uniqueId: _.uniqueId(),
        }
    },
    mounted(){
        if(this.isLoading){
             this.renderSpinner();
        }
    },
    methods:{
        renderSpinner(){            
            var element = document.getElementById('kis__spinner-'+this.uniqueId);
             if(element){
                if(this.isLoading){
                    element.parentNode.classList.add('kis__spinner-disable-block');
                }else{
                    element.parentNode.classList.remove('kis__spinner-disable-block');
                }
             }

        }
    },
    watch:{
        isLoading(){
           this.renderSpinner()
        }
    },
    computed:{
        classList(){
            let classList='';
            if(this.isTopRight){
                let classList='kis__spinner--top-right';
            }
            return classList;
        }
    }
}
</script>

<style lang="scss">

.kis__spinner-disable-block
{
     .kis__spinner ~ div, .kis__spinner ~ a, .kis__spinner ~ button{
        pointer-events: none;
        opacity: 0.4;
        position: relative;
     }

}

.kis__spinner.top-right{
    position: absolute;
    display: block;
    padding-right: 2rem;
    top: 8%;
    right: 2rem;
    z-index: 99999;
    left: 0;
    text-align: end;
}

</style>