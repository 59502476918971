
import Device from "@/views/crud/Device/Devices.vue";
import CreateDevice from "@/views/crud/Device/Create.vue";
import EditDevice from "@/views/crud/Device/Edit.vue";

export const device = [{
  path: "/admin/view/devices",
  component: Device,
  isCRUD: true,
  name: "Device",
  nav: {
    name: "Admin",
    icon: "sliders-h"
  },
  meta: {
    noPadding: true,
    access: ['superadmin', 'admin', 'reseller'],
    breadcrumbs: [{ name: "Admin", link: "/admin" }, { name: "Översikt" }]
  },
},
{
  path: "/admin/create/device",
  component: CreateDevice,
  isCRUD: true,
  name: "createDevice",
  nav: {
    name: "Admin",
    icon: "sliders-h"
  },
  meta: {
    noPadding: true,
    access: ['superadmin', 'admin', 'reseller'],
    breadcrumbs: [{ name: "Admin", link: "/admin" }, { name: "Skapa" }]
  }
},
{
  path: "/admin/edit/device/:id?",
  component: EditDevice,
  isCRUD: true,
  name: "editDevice",
  nav: {
    name: "Admin",
    icon: "sliders-h"
  },
  meta: {
    noPadding: true,
    access: ['superadmin', 'admin', 'reseller'],
    breadcrumbs: [{ name: "Admin", link: "/admin" }, { name: "Redigera" }]
  }
}]
