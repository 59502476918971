<template>
  <div>
    <div class="kits-top-row kis-mt-3">
	  <InputText type="text" class="search-field" v-model="searchString" :placeholder="$t('general.search')"/>
      <InputToggle label="Endast aktiva" :flex="true" v-model="showOnlyActive" />
    </div>
    <div class="kits-locker-grid" v-for="customer in customers" :key="'customer-' + customer">
      <div class="kits-locker-grid__header">
        {{ customer }}
        <div @click="addCallback(customer)" class="kits-locker-grid__add">
          <i class="fas fa-plus-circle"></i>
        </div>
      </div>
	   <div class="kits-locker-grid__item-v1"  v-for="locker in getFilteredData(customer)" :key="'locker-' + locker.Id">
			<div class="section-1">
				<div class="kits-locker-status">
					<span class="status" :class="getLockerStatus(locker)" />
					<div class="kits-locker-status__text">
							{{locker.AmountBelowCritical}} / {{locker.TotalArticles}}
					</div>
				</div>
				<div class="icon" :class="`${getGraphData(locker) ? 'flipper' : ''} ${locker.LockerActive > 0 ? 'active' : ''}`">
					<span @click="getGraphData(locker) && flip(locker.Id)">
					<i class="fas fa-warehouse"></i>
					<i class="fas fa-chart-line flipped"></i>
					</span>
				</div>
				<span class="title">{{ locker.LockerName }}</span>
			</div>
			<div class="section-2" v-if="!flipped.includes(locker.Id)">
				<p>{{ locker.LockerDescription }}</p>
			</div>
			<div class="section-2" v-else>
				<ChartCollection
					v-if="getGraphData(locker)"
					:name="'sparkline'"
					:xPanel="false"
					:height="115"
					:data="[
					{
						type: 'line',
						width: 100,
						chart: {
						title: 'Transaktioner',
						data: getGraphData(locker),
						axisX: 'date',
						axisY: 'value',
						color: 7
						}
					}
					]"
				/>
			</div>
			<div class="section-3">
				<button class="button action" @click="itemButtonCallback(locker.Id)">Transakioner</button>
				<button class="button edit" @click="openLocker(locker.Id)"><i class="fas fa-pen"></i></button>
			</div>
	   </div>
	<template v-if="false">
      <div class="kits-locker-grid__item" v-for="locker in getFilteredData(customer)" :key="'locker-' + locker.Id">
		<div class="kits-locker-grid__item-header">
          <div class="kits-locker-status">
            <GlobStatusBubble :color="getLockerStatus(locker)" />
						<div class="kits-locker-status__text">
							{{locker.AmountBelowCritical}} / {{locker.TotalArticles}}
						</div>
          </div>
          <button @click="openLocker(locker.Id)"><i class="fas fa-pen"></i></button>
          <div
            class="kits-locker-grid__item-image"
            @click="getGraphData(locker) && flip(locker.Id)"
            :class="`${getGraphData(locker) ? 'flipper' : ''} ${locker.LockerActive > 0 ? 'active' : ''}`"
          >
            <i class="fas fa-warehouse"></i>
            <i class="fas fa-chart-line flipped"></i>
          </div>
        </div>
        <div class="kits-locker-grid__item-content" :key="'card-content-main'" v-if="!flipped.includes(locker.Id)">
          <h3>{{ locker.LockerName }}</h3>
          <p>{{ locker.LockerDescription }}</p>

          <div class="kits-locker-grid__item-buttons">
            <button class="button button--small" @click="itemButtonCallback(locker.Id)">Transaktioner</button>
          </div>
        </div>
        <div
          class="kits-locker-grid__item-content kits-locker-grid__item-content--back"
          :key="'card-content-flipped'"
          v-if="flipped.includes(locker.Id)"
        >
          <ChartCollection
            v-if="getGraphData(locker)"
            :name="'sparkline'"
            :xPanel="false"
            :height="100"
            :data="[
              {
                type: 'line',
                width: 100,
                chart: {
                  title: 'Transaktioner',
                  data: getGraphData(locker),
                  axisX: 'date',
                  axisY: 'value',
                  color: 7
                }
              }
            ]"
          />
          <div class="kits-locker-grid__item-buttons">
            <button class="button button--small" @click="itemButtonCallback(locker.Id)">Transaktioner</button>
          </div>
        </div>
      </div>
	  		</template>
    </div>
  </div>
</template>

<script>
import { useRouter } from "vue-router";
import { computed, reactive, watchEffect, ref } from "vue";
export default {
  props: ["data", "addCallback", "itemButtonCallback", "transactions"],
  setup(props) {
	const searchString = ref('');
    const flipped = ref([]);
    const flip = (id) => {
      if (!flipped.value.includes(id)) {
        flipped.value.push(id);
      } else {
        flipped.value.splice(flipped.value.indexOf(id), 1);
      }
    };
    const { data } = reactive(props);
    const router = useRouter();
    const openLocker = (id) => {
      router.push(`/storage/locker/${id}`);
    };

    const showOnlyActive = ref(false);

	const filteredLockers = computed(() => {
		return searchString.value.length > 0 ? data.body
				.filter(
					i => (i.LockerName && i.LockerName.toLowerCase().match(searchString.value.toLowerCase()))
					|| (i.LockerLocation && i.LockerLocation.toLowerCase().match(searchString.value.toLowerCase()))
					||  (i.LockerEndCustomer && i.LockerEndCustomer.toLowerCase().match(searchString.value.toLowerCase()))
					) : data.body;
	})

    const customers = computed(() => {
      if (showOnlyActive.value) {
        return filteredLockers.value
          ? filteredLockers.value.reduce((acc, curr) => {
              if (curr.LockerActive && !acc.includes(curr.LockerEndCustomer)) {
                acc.push(curr.LockerEndCustomer);
              }
              return acc;
            }, [])
          : [];
      } else {
        return filteredLockers.value
          ? filteredLockers.value.reduce((acc, curr) => {
              if (!acc.includes(curr.LockerEndCustomer)) {
                acc.push(curr.LockerEndCustomer);
              }
              return acc;
            }, [])
          : [];
      }
    });

    const getLockerStatus = (locker) => {
      const calc = Math.floor((parseInt(locker.AmountBelowCritical) / parseInt(locker.TotalArticles)) * 100);
      const perc = isNaN(calc) ? 0 : calc;
      if (perc === 0) {
        return "green";
      }
      if (perc === 100) {
        return "red";
      }
      if (perc > 0) {
        return "orange";
      }
    };

    const getFilteredData = (customer) => {

      if (showOnlyActive.value) {
          return filteredLockers.value.filter((i) => i.LockerEndCustomer == customer && i.LockerActive);
      } else {
          return filteredLockers.value.filter((i) => i.LockerEndCustomer == customer);
      }
    };

    const getGraphData = (locker) => {
      const data = props.transactions.find((i) => i.name == locker.LockerName);
      return data ? data.data : false;
    };

    const getColor = (index) => {
      if (index == 1) {
        return "orange";
      }
      if (index == 2) {
        return "red";
      }
      return "green";
    };
    return {
      getColor,
      openLocker,
      customers,
      flipped,
      flip,
      showOnlyActive,
      getFilteredData,
      getGraphData,
      getLockerStatus,
	  searchString
    };
  }
};
</script>

<style lang="scss" scoped>
.kits-locker-status {
	position: relative;
	&__text {
		transition: all .3s ease-in-out;
		opacity: 0;
		visibility: hidden;
		background-color: white;
		color: $kits-steelGray;
		padding: .5rem 1rem;
		position: absolute;
		width: 5rem;
		text-align: center;
		left: 50%;
		transform: translate(-50%, -100%);
		top: -100%;
		@include elevation(4);
		border-radius: 4px;
		font-size: .8rem;
		&:after {
			width: 1rem;
			height: 1rem;
			content: '';
			display: block;
			bottom: -.5rem;
			left: 50%;
			transform: translateX(-50%) rotate(45deg);
			position: absolute;
			background-color: white;
		}
	}
	&:hover &__text {
		opacity: 1;
		visibility: visible;
	}
}
.kits-top-row {
  .search-field{
	  display: block;
      float: right;
  }
}
.kits-locker-grid {
  @include xy-grid();
  padding: 1rem;
  border-radius: 5px;
  background-color: $kits-lightGrayV1;
  position: relative;
  padding-right: 2rem;
  padding-top: 0.5rem;
  margin-top: 3rem;
  &__header {
    position: absolute;
    height: 42px;
    background: $kits-steelGrayV1;
    border-radius: 15px 15px 0px 0px;
    left: 0;
    right: 0;
    top: -1.5rem;
    display: flex;
    align-items: center;
    padding: 0 1.5rem;
    // font-family: 'Inter';
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    color: $kits-greyGoose;
  }
  &__add {
    transition: all 0.3s ease-in-out;
    margin-left: auto;
    color: white;
    border-radius: 100px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 1rem;
    width: 1.5rem;
    height: 1.5rem;
    cursor: pointer;
    &:hover {
      background-color: darken($kits-lightGray, 50%);
      color: white;
    }
  }
  &__item {
    @include xy-cell(1 of 5);
    @include elevation(4);
        margin-top: 3.5rem;
    border-radius: 4px;
    background-color: white;
    &-header {
      position: relative;
      height: 2.5rem;
      margin-bottom: 1rem;
      border-top-left-radius: 4px;
      border-top-right-radius: 4px;
      padding: 0.5rem;
      background-color: $kits-steelGray;
      color: white;
      display: flex;
      align-items: center;
      button {
        transition: background-color 0.3s ease-in-out;
        border: none;
        padding: 0.3rem;
        border-radius: 100px;
        margin: 0;
        color: white;
        width: 1.8rem;
        height: 1.8rem;
        margin-left: auto;
        &:hover {
          background-color: lighten($kits-steelGray, 20%);
          color: $kits-steelGray;
        }
        i {
          font-size: 0.8rem;
          color: white;
        }
      }
    }
    &-content {
      display: flex;
      flex-direction: column;
      padding: 0.5rem;
      height: calc(100% - 3.5rem);
      h3 {
        text-align: center;
        font-size: 1rem;
      }
      p {
        text-align: center;
        margin: 0;
        margin-bottom: 1rem;
      }
    }
    &-buttons {
      display: flex;
      justify-content: space-between;
      justify-self: flex-end;
      margin-top: auto;
      button {
        margin: 0;
        flex: 1;
      }
    }
    &-image {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 4rem;
      height: 4rem;
      border-radius: 100px;
      background-color: white;
      border: 3px solid $kits-red;
      position: absolute;
      top: 5px;
      left: 50%;
      transform: translateX(-50%);
      color: $kits-steelGray;
      cursor: pointer;
      @include elevation(4);
      &.active {
        border: 3px solid $kits-green;
      }
      i {
        transition: all 0.3s ease-in-out;
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
      }
      .flipped {
        opacity: 0;
        visibility: hidden;
      }
    }
    &:hover &-image {
      &.flipper {
        i {
          opacity: 0;
          visibility: hidden;
        }
        .flipped {
          opacity: 1;
          visibility: visible;
        }
      }
    }
  }
}

.kits-locker-grid__item-v1{
	margin-left: 1.5rem;
	box-sizing: border-box;
	margin-top: 3rem;
	width: 314px;
	left: 205px;
	top: 175px;
	box-shadow: 0px 10px 20px rgba(0, 48, 75, 0.2);

	background: $kits-white;
	border-radius: 15px;

	.section-1{
		display: flex;
		margin-top: 2rem;
    	margin-left: 2rem;
		position:relative;
		.status{
			width: 16px;
			height: 16px;
			border: 2px solid $kits-white;
			border-radius: 50%;
			border-radius: 50%;
			position: absolute;
			right: 14px;

		}

		.status.green{
			background: $kits-freshGreen;
			box-shadow: 0px 2px 5px rgba(47, 58, 61, 0.1);
		}

		.status.orange{
			background: $kits-lightOrange;
			box-shadow: 0px 2px 5px rgba(47, 58, 61, 0.1);
		}

		.status.red{
			background: $kits-red;
			box-shadow: 0px 2px 5px rgba(47, 58, 61, 0.1);
		}


		.icon{
			background: $kits-white;
			border: 1px solid $kits-greyGoose;
			border-radius: 10px;
			width: 72px;
			height: 72px;
			justify-content: center;
			display: flex;

			i{
				font-size: 2rem;
				margin-top: 17px;
			}

			span{
				cursor: pointer;
			}
		}

		.title{
			font-family: 'Inter';
			font-style: normal;
			font-weight: 600;
			font-size: 20px;
			margin-left: 1rem;
			    margin-top: 3rem;
			color: $kits-carbonGrey;
		}

	}

	.section-2{
    margin-left: 2rem;
		font-family: 'Inter';
		font-style: normal;
		font-weight: 400;
		font-size: 14px;
		line-height: 24px;
		min-height: 100px;
		/* or 171% */


		color: $kits-carbonGrey;

		opacity: 0.7;
	}

	.section-3{
		display: flex;
		margin-left: 2rem;
		button.action{
			width: 75%;
			height: 40px;
			font-size: 18px;
			line-height: 22px;
		}

		button.edit{
			width: 40px;
			height: 40px;
			font-size: 18px;
			background: $kits-white;
			border: 1px solid $kits-greyGoose;
			border-radius: 6px;
			padding: 0;
			i{
				color: $kits-steelGrayV1;
        font-size: 15px;
			}
		}
	}

	.flipped{
		opacity: 0;
    	display: none;
	}
    &:hover .section-1 .icon {
      &.flipper {
        i {
          opacity: 0;
              display: none;
        }
        .flipped {
          opacity: 1;
          display: block;
        }
      }
    }

	.kits-locker-status{
		position: absolute;
    	right: 2rem;
	}

	.kits-locker-status__text {
		transition: all .3s ease-in-out;
		opacity: 0;
		visibility: hidden;
		background-color: white;
		color: $kits-steelGray;
		padding: 0.5rem 1rem;
		position: absolute;
		width: 5rem;
		/* text-align: center; */
		left: -22px;
		transform: translate(-50%, -100%);
		top: -10px;
		border-radius: 4px;
		font-size: .8rem;
	}

	.kits-locker-status:hover .kits-locker-status__text {
		opacity: 99;
		visibility: visible;
	}

}
</style>
