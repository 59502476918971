<template>
	<div class="grid-container">
		<div class="grid-x" :class="margin ? ' grid-margin-x grid-margin-y' : ''">
			<slot/>
		</div>
	</div>
</template>

<script>
export default {
	props: {
		margin: {
			required: false,
			default: true
		}
	}
}
</script>

<style lang="scss">
	.grid-container {
		width: 100%;
		max-width: 100% !important;
		padding-left: 0 !important;
	}
</style>