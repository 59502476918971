<template>
    <GlobLoader v-if="isLoading"/>
	<div v-else class="Kits-crud">
                <div class="kits-top-button-row">
                    <router-link class="button button--linearGray" :to="{name: 'CustomerDiscount'}"
                        >{{$t('general.button.back_to_overview')}}</router-link>
                </div>
            <GlobXpanel :title="$t('billing.customer_discount.create_discount')" style="max-width: 900px;">
				<div class="ips-admin-fieldgroup">
					<GlobXpanel :noShadow="true" :tools="false" :title="$t('billing.customer_discount.crud_section.reseller')">
					<GlobRow>
						<GlobCol :medium="4">
							<label class="crud-label">
										{{$t('billing.customer_discount.fields.reseller')}}*
							<InputSelect :fullWidth="true" v-model="discount.form.CustomerId" :placeholder="$t('billing.customer_discount.fields.select_reseller')" :data="resellers" />
							</label>
						</GlobCol>
						</GlobRow>
					</GlobXpanel>
				</div>
				<div class="ips-admin-fieldgroup">
					<GlobXpanel :noShadow="true" :tools="false" :title="$t('billing.customer_discount.crud_section.general')">
						<GlobRow>
							<GlobCol :medium="4">
								<label class="crud-label">
									{{$t('billing.customer_discount.fields.discount')}}*
									<InputDynamic
									:fullWidth="true"
									styling="margin-bottom: .5rem;"
									:type="'number'"
									:placeholder="$t('billing.customer_discount.fields.discount')"
									v-model="discount.form.Discount"
									/>
								</label>
							</GlobCol>
                            <GlobCol :medium="4">
								<label class="crud-label">
									{{$t('billing.customer_discount.fields.currency')}}*
									<InputSelect
									:fullWidth="true"
									styling="margin-bottom: .5rem;"
									:type="'number'"
									:placeholder="$t('billing.customer_discount.fields.select_currency')"
									v-model="discount.form.CurrencyId"
									:data="currencies"
									/>
								</label>
							</GlobCol>
						</GlobRow>
					</GlobXpanel>
				</div>
				<div v-if="errorMessage">
					<p class="text-danger mt-2" >{{errorMessage}}</p>
				</div>
				<div class="locker-action-buttons">
					<button class="button"  :disabled="isSaveButtonDisabled()" @click="create">{{$t('general.button.save')}}</button>
					<router-link class="button button--orange" :to="{name:'CustomerDiscount'}"
					>{{$t('general.button.cancel')}}</router-link>
				</div>
		</GlobXpanel>
	</div>
</template>
<script>
import { onMounted, computed, watch, ref, reactive } from "vue";
import { useApi } from "@/plugins/api";
import { useRouter } from "vue-router";
import { useNotifications } from "@/plugins/notifications";
import { useI18n } from 'vue-i18n';

export default {
 setup() {

	const { tm } = useI18n();
	const api = useApi();
	const router = useRouter();
	const notifications = useNotifications();

    const endpoint = computed(() => api.discounts);

    const isLoading = ref(false);


    const currencies = ref([]);

    const errorMessage = ref(null);
    const discount = reactive({
		form: {
            CustomerId: "",
			Discount: "",
            CurrencyId: ""
		},
	});
		
    let resellers = ref([]);
	 onMounted(async() => {
		 //get currencies
		 api.currencies.get().then((res) => {
                currencies.value = res.currencies;
        });
		
        //Get list of resellers
        api.resellers.get().then((res) => {
                resellers.value = res.resellers.map((item) => ({
                value: item.id,
                name: item.CustomerName
            }));
        });
	 });

	const create = () => {
		isLoading.value = true;

		endpoint.value.create(discount.form).then(function(res){
           if(res.success){
				notifications.push(
					{
						msg: tm('billing')['customer_discount']['create_success']
					},
					"success",
					false
					);
				router.push({'name': 'CustomerDiscount'})
		   } else {
				errorMessage.value = tm('general')['something_went_wrong'];
		   }
		   isLoading.value = false;
		})
	}

	const isSaveButtonDisabled = () => {
		return discount.form.CustomerId && discount.form.Discount && discount.form.CurrencyId  ? false : true;
	}

     	
    return {
		discount,
		isLoading,
		create,
		errorMessage,
		isSaveButtonDisabled,
		resellers,
        currencies
    };
  }
};
</script>
<style>
.crud-label-margin-left{
    margin-left: .5rem;
}
</style>
