<template>
	<div class="kits-status-bubble" :class="`kits-status-bubble--${color}`"></div>
</template>

<script>
export default {
	props: ['color']
}
</script>

<style lang="scss">
	.kits-status-bubble {
		width: 1em;
		height: 1em;
		border-radius: 100px;
		&--green {
			background-color: $kits-green;
			border: .2em solid lighten($kits-green, 8%)
		}
		&--orange {
			background-color: $kits-orange;
			border: .2em solid lighten($kits-orange, 8%)
		}
		&--red {
			background-color: $kits-red;
			border: .2em solid lighten($kits-red, 8%)
		}
	}
</style>