<template>
  <div style="max-width: 99%">
    <Navbar :items="routes" />
    <GlobXpanel :title="$t('administration.administer')" v-if="$route.name == 'Admin'">
      <div class="kits-admin-cruds">
        <router-link
          :to="`/admin/view/${crud.slug}`"
          class="kits-admin-cruds__item"
          v-for="crud in filteredCruds"
          :key="'crud-' + crud.key"
        >
          <div class="kits-admin-cruds__item-header">
          </div>
		  	<div class="kits-admin-cruds__item-icon">
              <i :class="`fas fa-${crud.config.icon}`"></i>
            </div>
          <div class="kits-admin-cruds__item-content">
           	{{$t('administration.menus.'+crud.slug)}}
          </div>
        </router-link>
      </div>
    </GlobXpanel>
    <MainView/>
  </div>
</template>

<script>
import { contentTypes, routes, cruds } from "./config";
import Navbar from "./Navbar.vue";
import MainView from "./MainView.vue";
import userGroups from './config/userGroups'
import { computed } from 'vue';
import { useStore } from 'vuex';

export default {
  components: {
		Navbar,
		MainView
	},
	setup() {
		const store = useStore();
		const user = computed(() => store.state.USER);
    	const userRole = store.getters.GET_USER.UserRole;
		const filteredCruds = computed(() => cruds.filter(i => {

			if(i.access && !i.access.includes(userRole)){
				return false;
			}

			if(i.hide){
				return false;
			}
			if(i.isCustom){
				return true;
			}
			if (userGroups[user.value.UserGroup]) {
				if (userGroups[user.value.UserGroup] === '*') {
					return true
				} else {
					return userGroups[user.value.UserGroup].includes(i.slug);
				}
			} else {
				return false
			}
		}))
		return {
      types: contentTypes,
      routes,
			cruds,
			filteredCruds
		}
	},
};
</script>

<style lang="scss">
.kits-admin-cruds {
	display: flex;
	flex-flow: row wrap;
	margin-left: .5rem;
	margin-bottom: 1rem;
  &__item {
	position:relative;
	width: 314px;
    height: 210px;
	margin-right: 1rem;
	margin-top: 1rem;
   cursor: pointer;
    text-align: center;
    @include hover-elevation(1, 4);
	background: $kits-blackHaze;
    border-radius: 15px;
    &-header {
		background: linear-gradient(180deg, $kits-blackHaze 0%, $kits-greyGoose 100%);
		opacity: 0.3;
		border-radius: 15px 15px 0px 0px;
		height: 80px;
		position: relative;
		width: 100%;
		border-top-right-radius: 3px;
		border-top-left-radius: 3px;
    }
    &-content {
      	padding-top: 2.9rem;
	    font-size: 20px;
		line-height: 40px;
		color: $kits-steelGrayV1;
		font-weight: 400;
		font-family: 'Inter';
		text-decoration: none;
    }
    &-icon {
		display: flex;
		justify-content: center;
		align-items: center;
		width: 88px;
		height: 88px;
		border-radius: 100px;
		background: $kits-white;
		position: absolute;
		font-size: 1.2rem;
		top: 14%;
		left: 50%;
		transform: translateX(-50%);
		color: $kits-ShakespeareBlue;
		i{	
    		font-size: 1.7rem;
		}
      &.active {
        border: 3px solid $kits-green;
      }
    }
  }
}
</style>
