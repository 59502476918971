import CustomerDiscount from '@/views/Billing/CustomerDiscount/index.vue';
import CreateDiscount from '@/views/Billing/CustomerDiscount/create.vue';
import EditDiscount from '@/views/Billing/CustomerDiscount/edit.vue';

export const customerDiscount = [
  {
	name: "CustomerDiscount",
    isCRUD: true,
	path: "/billing/discounts",
	component: CustomerDiscount,
    meta: {
        noPadding: true,
        breadcrumbs: [{ name: "general.billing", link: "/billing" }, { name: "billing.customer_discount.breadcrumb.overview" }] ,
        languageSupport: true,
        access: ['superadmin', 'admin']
    },
   },
{
	name: "CreateDiscount",
    isCRUD: true,
	path: "/billing/create/discount",
	component: CreateDiscount,
    meta: {
        noPadding: true,
        breadcrumbs: [  { name: "general.billing", link: "/billing" }, 
                        { name: "billing.customer_discount.breadcrumb.overview", link: "/billing/discounts" },
                        { name: "billing.customer_discount.breadcrumb.create" }] ,
        languageSupport: true,
        access: ['superadmin', 'admin']
    },
},
{
	name: "EditDiscount",
    isCRUD: true,
	path: "/billing/edit/discount/:id",
	component: EditDiscount,
    meta: {
        noPadding: true,
        breadcrumbs: [  { name: "general.billing", link: "/billing" }, 
                        { name: "billing.customer_discount.breadcrumb.overview", link: "/billing/discounts" },
                        { name: "billing.customer_discount.breadcrumb.edit" }] ,
        languageSupport: true,
        access: ['superadmin', 'admin']
    },
}];