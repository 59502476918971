import Admin from "@/views/Admin/Admin.vue";
import Viewer from "@/views/Admin/wrappers/Viewer.vue";
import Editor from "@/views/Admin/wrappers/Editor.vue";
import Create from "@/views/Admin/wrappers/Create.vue";

const ViewNew = () => import("@/views/Admin/ViewNew.vue")

export const admin = {
  path: "/admin",
  component: Admin,
  name: "Admin",
  nav: {
    name: "Admin",
    icon: "sliders-h"
  },
  meta: {
    noPadding: true
  },
  children: [
    {
      component: Viewer,
      path: "view/:slug",
      meta: {
        noPadding: true,
        breadcrumbs: [{ name: "Admin", link: "/admin" }, { name: "Översikt" }]
      }
    },
    {
      component: Editor,
      path: "edit/:slug/:id",
      meta: {
        noPadding: true,
        breadcrumbs: [{ name: "Admin", link: "/admin" }, { name: "Redigera" }]
      }
    },
    {
      component: Create,
      path: "create/:slug",
      meta: {
        noPadding: true,
        breadcrumbs: [{ name: "Admin", link: "/admin" }, { name: "Skapa" }]
      }
    }
  ]
};
