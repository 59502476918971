<template>
	<div>
		<div class="kits-page-top-row">
			<button v-if="toggleTable" class="button" @click="setLockerModal(true)">Skapa nytt skåp</button>
			<InputToggle style="margin-left: auto;" label="Visa tabell" :flex="true" v-model="toggleTable"/>
		</div>
		<GlobTable
			v-if="toggleTable"
			:suspend="true"
		:data="data"
		:actions="actions"
			:enableRenderFunctions="true"
    />
		<LockerGrid v-else
			:data="data"
			:addCallback="(customer) => createCustomerLocker(customer)"
			:itemButtonCallback="(id) => getLockerTransactions(id)"
			:transactions="transactions"
			:width="20"
		/>
		<GlobModal
			:show="lockerModal"
			:width="20"
			@close="setLockerModal(false)"
			title="Nytt skåp"
		>
			<InputText :fullWidth="true" v-model="newLocker.LockerName" placeholder="Namn"/>
			<InputSelect :fullWidth="true" v-model="newLocker.EndCustomerId" placeholder="Kund" :data="customers"/>
			<button class="button" @click="createLocker">Skapa</button>
		</GlobModal>
		<GlobModal
			:show="lockerTransactionModal"
			:isLoaded="lockerTransactions.loaded"
			:width="50"
			@close="setLockerTransactionModal(false)"
			title="Transaktioner"
		>
			<GlobTable :data="lockerTransactions"/>
		</GlobModal>
	</div>
</template>

<script>
import { ref, reactive, onMounted } from 'vue';
import { useApi } from '@/plugins/api';
import { useNotifications } from '@/plugins/notifications';
import { useRouter } from 'vue-router';
import LockerGrid from '@/components/lockerGrid/LockerGrid.vue';
export default {
	components: {
		LockerGrid
	},
	setup() {
		//Hooks som används för att kroka på funktionalitet som api, notifikationer eller eventbus.
		const api = useApi();
		const notifications = useNotifications();
		const router = useRouter();

		const data = reactive({
			body: null,
			head: [
				{Alias: 'LockerEndCustomer', Value: 'Kund'},
				{Alias: 'Id', Value: 'Skåp-ID'},
				{Alias: 'LockerName', Value: 'Namn'},
				{Alias: 'LockerDescription', Value: 'Beskrivning'},
				{Alias: 'LockerLocation', Value: 'Placering'},
				{Alias: 'LockerActive', Value: 'Aktivt'},
			]
		})
		let customers = ref([]);
		const getData = () => {
			api.lockers.get().then(res => {
				data.body = res.data;
			})
			api.customers.get().then(res => {
				customers.value = res.data.map(item => ({
					value: item.id,
					name: item.CustomerName
				}))
			})
		}

		const transactions = ref([])
		const getAllTransactions = async () => {
      await api.analytics.getTransactions().then((res) => {
        transactions.value = res.data.reduce((a, c) => {
          if (!a.find((i) => i.name == c.Locker)) {
            a.push({
              name: c.Locker,
              data: res.data
                .filter((item) => item.Locker == c.Locker)
                .map((i) => ({
                  date: new Date(i.TransactionDate),
                  value: i.TransactionAmount
                }))
            });
          }
          return a;
        }, []);
			});
			console.log(transactions.value)
    }

		const newLocker = reactive({
			LockerName: '',
			EndCustomerId: null
		})
		const lockerModal = ref(false);
		const createLocker = () => {
			api.lockers.create(newLocker).then(res => {
				getData();
				setLockerModal(false);
				notifications.push(
          {
            title: "Nytt skåp",
            msg: `Ditt nya skåp med id ${res} är skapat och är redo att användas.`
          },
          "success",
          `/storage/locker/${res}`
        )
			})
		}
		const setLockerModal = (bool) => {
			lockerModal.value = bool;
		}
		const createCustomerLocker = (customer) => {
			newLocker.EndCustomerId = customers.value.find(i => i.name == customer).value;
			setLockerModal(true);
		}

		const deleteLocker = (id) => {
			api.lockers.delete(id).then(res => {
				getData();
				notifications.push(
          {
            title: "Skåp raderat",
            msg: `Raderade skåp med id ${id}`
          },
          "success",
					false,
					false
        )
			})
		}

		const lockerTransactionModal = ref(false);
		const setLockerTransactionModal = (bool) => {
			if (!bool) {
				lockerTransactions.body = [];
				lockerTransactions.loaded = false;
			}
			lockerTransactionModal.value = bool;
		}
		const lockerTransactions = reactive({
			body: [],
			head: [
				{Alias: 'ArticleName', Value: 'Artikel'},
				{Alias: 'Name', Value: 'Förnamn'},
				{Alias: 'LastName', Value: 'Efternamn'},
				{Alias: 'Opened', Value: 'Öppnad'},
				{Alias: 'Closed', Value: 'Stängd'},
			],
			loaded:false
		})
		const getLockerTransactions = (id) => {
			setLockerTransactionModal(true);
			api.lockers.getTransactions(id).then(res => {
				lockerTransactions.body = res.data;
				lockerTransactions.loaded = true;
			})
		}

		const toggleTable = ref(false);

		const actions = [
      {
        name: "Redigera",
        action: (obj) => {
          router.push(`/storage/locker/${obj.Id}`)
        }
      },
      {
        name: "Radera",
        color: "red",
        buttonStyle: "outline",
        requirements: {
          consequence: (obj) => `Är du säker på att du vill radera ${obj.LockerName} (${obj.Id})?`
        },
        action: (obj) => {
          deleteLocker(obj.Id)
        }
      }
    ];

		//onMounted är en hook som fungerar på samma sätt som mounted i gamla options-api:et.
		onMounted(() => {
			getData();
			getAllTransactions();
		});

		//Allt som ska användas i template returneras här.
		return {
			data,
			actions,
			newLocker,
			createLocker,
			lockerModal,
			setLockerModal,
			customers,
			toggleTable,
			getLockerTransactions,
			lockerTransactions,
			lockerTransactionModal,
			setLockerTransactionModal,
			createCustomerLocker,
			transactions
		}
	}
}
</script>