<template>
    <GlobLoader v-if="isLoading"/>
	<div v-else class="Kits-crud">
		<Navbar :items="routes" />
                <div class="kits-top-button-row">
                    <router-link class="button button--linearGray" :to="'/admin/view/lockers-v2'"
                        >{{$t('general.button.back_to_overview')}}</router-link>
                </div>
            <GlobXpanel :title="$t('administration.locker.create_locker')" style="max-width: 900px;">
				<div class="ips-admin-fieldgroup">
					<GlobXpanel :noShadow="true" :tools="false" :title="$t('general.customer')">
					<GlobRow>
						<GlobCol :medium="4">
							<label class="crud-label">
										{{$t('general.fields.customer')}}*
							<InputSelect :fullWidth="true" v-model="locker.form.EndCustomerId" :placeholder="$t('general.select_customer')" :data="customers" />
							</label>
						</GlobCol>
						</GlobRow>
					</GlobXpanel>
				</div>
				<div class="ips-admin-fieldgroup">
					<GlobXpanel :noShadow="true" :tools="false" :title="$t('general.general')">
						<GlobRow>
							<GlobCol :medium="4">
								<label class="crud-label">
									{{$t('general.fields.name')}}*
									<GlobLabel :help="$t('administration.locker.info.name')" class="locker-field-help-info" />
									<InputDynamic
									:fullWidth="true"
									styling="margin-bottom: .5rem;"
									:type="'text'"
									:placeholder="$t('general.fields.name')"
									v-model="locker.form.Name"
									/>
								</label>
							</GlobCol>
							<GlobCol :medium="4">
								<label class="crud-label">
									{{$t('general.fields.description')}}
									<GlobLabel :help="$t('administration.locker.info.description')" class="locker-field-help-info" />
									<InputDynamic
									:fullWidth="true"
									styling="margin-bottom: .5rem;"
									:type="'text'"
									:placeholder="$t('general.fields.description')"
									v-model="locker.form.Description"
									/>
								</label>
							</GlobCol>
							<GlobCol :medium="4">
							<label class="crud-label">
								{{$t('general.fields.location')}}
								<GlobLabel :help="$t('administration.locker.info.location')" class="locker-field-help-info" />
								<InputDynamic
								:fullWidth="true"
								styling="margin-bottom: .5rem;"
								:type="'text'"
								:placeholder="$t('general.fields.location')"
								v-model="locker.form.Location"
								/>
							</label>
							</GlobCol>
						</GlobRow>
					</GlobXpanel>
				</div>
				<div class="ips-admin-fieldgroup">
					<GlobXpanel :noShadow="true" :tools="false" :title="$t('general.layout')">
						<GlobRow>
							<GlobCol :medium="4">
								<label class="crud-label">
									{{$t('administration.locker.amount_of_self')}}
									<GlobLabel :help="$t('administration.locker.info.amount_of_self')" class="locker-field-help-info" />
									<InputDynamic
									:fullWidth="true"
									styling="margin-bottom: .5rem;"
									:type="'number'"
									:placeholder="$t('administration.locker.amount_of_self')"
									v-model="locker.form.ShelfAmount"
									/>
								</label>
							</GlobCol>
							<GlobCol :medium="4">
								<label class="crud-label">
									{{$t('administration.locker.section_amount')}}
									<GlobLabel :help="$t('administration.locker.info.section_amount')" class="locker-field-help-info" />
									<InputDynamic
									:fullWidth="true"
									styling="margin-bottom: .5rem;"
									:type="'number'"
									:placeholder="$t('administration.locker.section_amount')"
									v-model="locker.form.ColumnAmount"
									/>
								</label>
							</GlobCol>
							<GlobCol :medium="4">
										<label class="crud-label">
											{{ $t('administration.locker.allow_enduser_to_set_placement') }}
										</label>
										<InputSwitch 
											class="kis-mt-1"  
											v-model="locker.form.AllowEnduserToSetPlacement"
											:value="locker.form.AllowEnduserToSetPlacement"
											:toggles="{
												on: $t('general.yes'),
												off: $t('general.no')
											}"
										
											/>
									</GlobCol>
						</GlobRow>
						<GlobRow>
							<GlobCol :medium="4">
								<input type="file" ref="logoImage" style="display: none" accept="image/*" @change="e => fileAttached(e, 'logoImage')">
										<label class="crud-label">
											{{$t('administration.locker.logo')}}
											<template v-if="!locker.form.LogoImageURL">
												<div class="file-upload-input-block">
													<div class="dynamic-type-input" style="display:flex;">
													<InputDynamic
													    :disabled= "true" 
													    @click.stop="$refs.logoImage.click()"
														:class="'input-block custom-disabled-input'"
														:fullWidth="true"
														:type="'text'"
														:placeholder="$t('administration.locker.logo')"
														/>
													<span @click.stop="$refs.logoImage.click()"><i
													class="fa fa-upload work-flow-file-icon" aria-hidden="true"></i></span>
													</div>
												</div>
											</template>
											<template v-else>
												<div class="attachment">
													<a id="uploaded-doc-link" :href="locker.form.LogoImageURL"
														download="" target="_blank">{{locker.form.Logo.name.length > 20 ? locker.form.Logo.name.substring(0,10)+".." : locker.form.Logo.name}}</a>
													<span @click.stop="$refs.logoImage.value = null; locker.form.Logo = ''; locker.form.LogoImageURL = null; locker.form.LogoRemoved = true; "><i class="fas fa-times"></i></span>
												</div>
											</template>
										</label>
							</GlobCol>
							<GlobCol :medium="4">
								<input type="file" ref="backgroundImage" style="display: none" accept="image/*" @change="e => fileAttached(e, 'backgroundImage')">
										<label class="crud-label">
											{{$t('administration.locker.background_image')}}
											<template v-if="!locker.form.BackgroundImageURL">
												<div class="file-upload-input-block">
													<div class="dynamic-type-input" style="display:flex;">
													<InputDynamic
													    :disabled= "true" 
													    @click="$refs.backgroundImage.click()"
														:fullWidth="true"
														:class="'input-block custom-disabled-input'"
														:type="'text'"
														:placeholder="$t('administration.locker.background_image')"
														/>
													<span @click="$refs.backgroundImage.click()"><i
													class="fa fa-upload work-flow-file-icon" aria-hidden="true"></i></span>
													</div>
												</div>
											</template>
											<template v-else>
												<div class="attachment">
													<a id="uploaded-doc-link" :href="locker.form.BackgroundImageURL"
														download="" target="_blank">{{locker.form.BackgroundImage.name.length > 20 ? locker.form.BackgroundImage.name.substring(0,20)+".." : locker.form.BackgroundImage.name}}</a>
													<span @click.stop="$refs.backgroundImage.value = null; locker.form.BackgroundImage = ''; locker.form.BackgroundImageURL = null; locker.form.BackgroundImageRemoved = true; "><i class="fas fa-times"></i></span>
												</div>
											</template>
										</label>
							</GlobCol>
						</GlobRow>
					</GlobXpanel>
				</div>
				<div class="ips-admin-fieldgroup">
					<GlobXpanel :noShadow="true" :tools="false" :title="$t('general.settings')">
						<GlobRow>
							<GlobCol :medium="4">
								<label class="crud-label">
									{{$t('administration.locker.validation_type')}}
									<GlobLabel :help="$t('administration.locker.info.validation_type')" class="locker-field-help-info" />
									<InputSelect
									:fullWidth="true"
									styling="margin-bottom: .5rem;"
									:type="'select'"
									:placeholder="$t('administration.locker.select_validation_type')"
									v-model="locker.form.ValidateBy"
									:data="validationTypeList"
									/>
								</label>
							</GlobCol>
							<GlobCol :medium="4">
								<label class="crud-label">
									{{$t('administration.locker.closing_time')}}
									<GlobLabel :help="$t('administration.locker.info.closing_time')" class="locker-field-help-info" />
									<InputDynamic
									:fullWidth="true"
									styling="margin-bottom: .5rem;"
									:type="'number'"
									:placeholder="$t('administration.locker.closing_time')"
									v-model="locker.form.ClosingTimeSeconds"
									/>
								</label>
							</GlobCol>
							<GlobCol :medium="4">
							<label class="crud-label">
								{{$t('administration.locker.admin_code')}}
								<GlobLabel :help="$t('administration.locker.info.admin_code')" class="locker-field-help-info" />
								<InputDynamic
								:fullWidth="true"
								styling="margin-bottom: .5rem;"
								:type="'number'"
								:placeholder="$t('administration.locker.admin_code')"
								v-model="locker.form.AdminPassword"
								/>
							</label>
							</GlobCol>
						</GlobRow>
					</GlobXpanel>
				</div>
				<div class="ips-admin-fieldgroup">
					<GlobXpanel :noShadow="true" :tools="false" :title="$t('administration.locker.gpio_settings')">
						<GlobRow>
							<GlobCol :medium="4">
								<label class="crud-label">
									{{$t('administration.locker.locker_pin_number')}}
									<GlobLabel :help="$t('administration.locker.info.locker_pin_number')" class="locker-field-help-info" />
									<InputDynamic
									:fullWidth="true"
									styling="margin-bottom: .5rem;"
									:type="'number'"
									:placeholder="$t('administration.locker.locker_pin_number')"
									v-model="locker.form.Pin"
									/>
								</label>
							</GlobCol>
							<GlobCol :medium="4">
								<label class="crud-label">
									{{$t('administration.locker.open_state')}}
									<GlobLabel :help="$t('administration.locker.info.open_state')" class="locker-field-help-info" />
									<InputSelect
									:fullWidth="true"
									styling="margin-bottom: .5rem;"
									:type="'number'"
									:placeholder="$t('administration.locker.select_open_state')"
									v-model="locker.form.OpenPinState"
									:data="openStates"
									/>
								</label>
							</GlobCol>
						</GlobRow>
					</GlobXpanel>
				</div>
				<div v-if="errorMessage">
					<p class="text-danger mt-2" >{{errorMessage}}</p>
				</div>
				<div class="locker-action-buttons">
					<button class="button"  :disabled="isSaveButtonDisabled()" @click="create">{{$t('general.button.save')}}</button>
					<router-link class="button button--orange" :to="'/admin/view/lockers-v2'"
					>{{$t('general.button.cancel')}}</router-link>
				</div>
		</GlobXpanel>
	</div>
</template>
<script>
import { onMounted, computed, watch, ref, reactive } from "vue";
import { useApi } from "@/plugins/api";
import { useRouter } from "vue-router";
import { useNotifications } from "@/plugins/notifications";
import Navbar from "@/views/Admin/Navbar.vue";
import { routes } from "@/views/Admin/config";
import { useI18n } from 'vue-i18n';

export default {
components: {
	Navbar
},
 setup() {

	const { tm } = useI18n();
	const api = useApi();
	const router = useRouter();
	const notifications = useNotifications();

    const endpoint = computed(() => api.lockersV2);
    const isLoading = ref(false);
	const validationTypeList = [{
		'value': 'employeenumber',
		'name': 'Employeenumber'
	},{
		'value': 'pincode',
		'name': 'Pincode'
	},{
		'value': 'employeenumber_and_pincode',
		'name': 'Employeenumber & Pincode'
	}]
	const openStates = [{
		'value': 1,
		'name': 'Open'
	},{
		'value': 0,
		'name': 'Closed'
	}]
    const errorMessage = ref(null);
    const locker = reactive({
		form: {
			Name: "",
			Description: "",
			Location: "",
			ServerAddress: "",
			SSID: "",
			Passcode: "",
			Hostname: "",
			EndCustomerId: null,
			Logo: "",
			BackgroundImage: "",
			ValidateBy: "",
			ClosingTimeSeconds: "",
			AdminPassword: "",
			Pin: "23",
			OpenPinState: "0",
			AllowEnduserToSetPlacement: false
		},
	});
		
    let customers = ref([]);
	 onMounted(async() => {
        //Get list of customers
        api.customers.get().then((res) => {
                customers.value = res.data.map((item) => ({
                value: item.id,
                name: item.CustomerName
            }));
        });
	 });

	const create = () => {

       if(!validateForm()){
		   return false;
	   }
		isLoading.value = true;
		const formData = new FormData();
		Object.keys(locker.form).forEach(key => formData.append(key, locker.form[key]));

		endpoint.value.createLocker(formData).then(function(res){
           if(res.data.success){
				notifications.push(
					{
						msg: tm('administration')['locker']['create_success']
					},
					"success",
					false
					);
				router.push('/admin/view/lockers-v2')
		   } else {
				if (res.data.errors) {
					for (let errorField in res.data.errors) {
						let error = JSON.parse(res.data.errors[errorField][0]);
							errorMessage.value = tm(error.error).replace('{attribute}', tm(error.attribute));
							break;
					}
				} else if(res.data.errorcode === 413){
                    errorMessage.value = tm('general')['errors']['large_file_exception'];
				} else {
					errorMessage.value = tm('general')['something_went_wrong'];
				}
		   }
		   isLoading.value = false;
		})
	}

	const isSaveButtonDisabled = () => {
		return locker.form.Name && locker.form.EndCustomerId ? false : true;
	}

	const fileAttached = (e,type) => {
		var files = e.target.files;
		if(type == 'logoImage'){
			locker.form.Logo = files[0];
			locker.form.LogoImageURL = URL.createObjectURL(locker.form.Logo);
		} else {
			locker.form.BackgroundImage = files[0];
			locker.form.BackgroundImageURL = URL.createObjectURL(locker.form.BackgroundImage);
		}
	}

	const validateForm = () =>{
		errorMessage.value = null;
		if(locker.form.ShelfAmount && !Number.isInteger(Number(locker.form.ShelfAmount))){
			errorMessage.value = tm('administration')['locker']['errors']['amount_of_self'];
			return false;
		}

		if(locker.form.ColumnAmount && !Number.isInteger(Number(locker.form.ColumnAmount))){
			errorMessage.value = tm('administration')['locker']['errors']['section_amount'];
			return false;
		}
		if(locker.form.ClosingTimeSeconds &&  ( !Number.isInteger(Number(locker.form.ClosingTimeSeconds)) || locker.form.ClosingTimeSeconds < 0 || locker.form.ClosingTimeSeconds > 30)){
			errorMessage.value = tm('administration')['locker']['errors']['closing_time'];
			return false;
		}

		if(locker.form.AdminPassword && !Number.isInteger(Number(locker.form.AdminPassword))){
			errorMessage.value = tm('administration')['locker']['errors']['admin_code'];
			return false;
		}
		return true;
	}

     	
    return {
		routes,
		locker,
		isLoading,
		validateForm,
		create,
		errorMessage,
		isSaveButtonDisabled,
		customers,
		fileAttached,
		validationTypeList,
		openStates
    };
  }
};
</script>
