import Planing from '@/views/Planing';
import Forecast from '@/views/Planing/Forecast/Forecast';

export const planing = {
	name: 'Planing',
	path: '/planing',
	component: Planing,
	languageSupport: true,
	meta: {
		access: ['superadmin', 'admin', 'reseller']
	},
	nav: {
		name : 'general.left_menu.planing',
		icon: "ruler",
	},
	children: [
		{
			path: '/planing/forecast',
			component: Forecast,
			nav: {
				name: 'general.left_menu.planing',
			},
		},
	]
}