<template>
  <GlobRow>
    <GlobCol medium="12">
      <GlobXpanel :title="'Hej'">
        <GlobTable
          :data="{
            body: [
              {
                Test: 'Cool',
                Id: '7dgfasfhfdfbyb',
                Test2: 'Hej',
                Test3: 'Hej',
                Test4: 'Hej'
              },
              {
                Test: 'Wow',
                Id: 'saighasdbdfhadddd23',
                Test2: 'Hej',
                Test3: 'Hej',
                Test4: 'Hej'
              },
              {
                Test: 'Wow',
                Id: 'saighasdafgabdd23',
                Test2: 'Hej',
                Test3: 'Hej',
                Test4: 'Hej'
              },
              {
                Test: 'Wow',
                Id: 'saighasdbdfsdgdd23',
                Test2: 'Hej',
                Test3: 'Hej',
                Test4: 'Hej'
              },
              {
                Test: 'Wow',
                Id: 'saighasdfgsdbdd23',
                Test2: 'Hej',
                Test3: 'Hej',
                Test4: 'Hej'
              },
              {
                Test: 'Wow',
                Id: 'saighasgahfdbdd23',
                Test2: 'Hej',
                Test3: 'Hej',
                Test4: 'Hej'
              },
              {
                Test: 'Wow',
                Id: 'saighgadgasdbdd23',
                Test2: 'Hej',
                Test3: 'Hej',
                Test4: 'Hej'
              },
              {
                Test: 'Wow',
                Id: 'saigfsghasdbdd23',
                Test2: 'Hej',
                Test3: 'Hej',
                Test4: 'Hej'
              },
              {
                Test: 'Wow',
                Id: 'saighasdfgfgddbdd23',
                Test2: 'Hej',
                Test3: 'Hej',
                Test4: 'Hej'
              },
              {
                Test: 'Wow',
                Id: 'saighasdsdfsfgdsbdd23',
                Test2: 'Hej',
                Test3: 'Hej',
                Test4: 'Hej'
              },
              {
                Test: 'Wow',
                Id: 'ssdfsgdfg',
                Test2: 'Hej',
                Test3: 'Hej',
                Test4: 'Hej'
              },
              {
                Test: 'Wow',
                Id: 'aasdfasdfsg',
                Test2: 'Hej',
                Test3: 'Hej',
                Test4: 'Hej'
              }
            ],
            head: [
              { Alias: 'Id', Value: 'Wow' },
              { Alias: 'Test', Value: 'Test' },
              { Alias: 'Test2', Value: 'Test2' },
              { Alias: 'Test3', Value: 'Test3' },
              { Alias: 'Test4', Value: 'Test4' }
            ]
          }"
          :actions="actions"
        />
        <GlobTableOld
          :items="{
            body: [
              {
                Test: 'Cool',
                Id: '7dgfasfhfdfbyb',
                Test2: 'Hej',
                Test3: 'Hej',
                Test4: 'Hej'
              },
              {
                Test: 'Wow',
                Id: 'saighasdbdfhadddd23',
                Test2: 'Hej',
                Test3: 'Hej',
                Test4: 'Hej'
              },
              {
                Test: 'Wow',
                Id: 'saighasdafgabdd23',
                Test2: 'Hej',
                Test3: 'Hej',
                Test4: 'Hej'
              },
              {
                Test: 'Wow',
                Id: 'saighasdbdfsdgdd23',
                Test2: 'Hej',
                Test3: 'Hej',
                Test4: 'Hej'
              },
              {
                Test: 'Wow',
                Id: 'saighasdfgsdbdd23',
                Test2: 'Hej',
                Test3: 'Hej',
                Test4: 'Hej'
              },
              {
                Test: 'Wow',
                Id: 'saighasgahfdbdd23',
                Test2: 'Hej',
                Test3: 'Hej',
                Test4: 'Hej'
              },
              {
                Test: 'Wow',
                Id: 'saighgadgasdbdd23',
                Test2: 'Hej',
                Test3: 'Hej',
                Test4: 'Hej'
              },
              {
                Test: 'Wow',
                Id: 'saigfsghasdbdd23',
                Test2: 'Hej',
                Test3: 'Hej',
                Test4: 'Hej'
              },
              {
                Test: 'Wow',
                Id: 'saighasdfgfgddbdd23',
                Test2: 'Hej',
                Test3: 'Hej',
                Test4: 'Hej'
              },
              {
                Test: 'Wow',
                Id: 'saighasdsdfsfgdsbdd23',
                Test2: 'Hej',
                Test3: 'Hej',
                Test4: 'Hej'
              },
              {
                Test: 'Wow',
                Id: 'ssdfsgdfg',
                Test2: 'Hej',
                Test3: 'Hej',
                Test4: 'Hej'
              },
              {
                Test: 'Wow',
                Id: 'aasdfasdfsg',
                Test2: 'Hej',
                Test3: 'Hej',
                Test4: 'Hej'
              }
            ],
            head: [
              { Alias: 'Id', Value: 'Wow' },
              { Alias: 'Test', Value: 'Test' },
              { Alias: 'Test2', Value: 'Test2' },
              { Alias: 'Test3', Value: 'Test3' },
              { Alias: 'Test4', Value: 'Test4' }
            ]
          }"
          :disableSummary="true"
          :maxHeight="30"
          :enableRenderFunctions="true"
          :bulkactions="bulkactions"
        />
        <GlobVirtualTable
          :items="{
            body: [
              {
                Test: 'Cool',
                Id: '7dgfasfhfdfbyb',
                Test2: 'Hej',
                Test3: 'Hej',
                Test4: 'Hej'
              },
              {
                Test: 'Wow',
                Id: 'saighasdbdfhadddd23',
                Test2: 'Hej',
                Test3: 'Hej',
                Test4: 'Hej'
              },
              {
                Test: 'Wow',
                Id: 'saighasdafgabdd23',
                Test2: 'Hej',
                Test3: 'Hej',
                Test4: 'Hej'
              },
              {
                Test: 'Wow',
                Id: 'saighasdbdfsdgdd23',
                Test2: 'Hej',
                Test3: 'Hej',
                Test4: 'Hej'
              },
              {
                Test: 'Wow',
                Id: 'saighasdfgsdbdd23',
                Test2: 'Hej',
                Test3: 'Hej',
                Test4: 'Hej'
              },
              {
                Test: 'Wow',
                Id: 'saighasgahfdbdd23',
                Test2: 'Hej',
                Test3: 'Hej',
                Test4: 'Hej'
              },
              {
                Test: 'Wow',
                Id: 'saighgadgasdbdd23',
                Test2: 'Hej',
                Test3: 'Hej',
                Test4: 'Hej'
              },
              {
                Test: 'Wow',
                Id: 'saigfsghasdbdd23',
                Test2: 'Hej',
                Test3: 'Hej',
                Test4: 'Hej'
              },
              {
                Test: 'Wow',
                Id: 'saighasdfgfgddbdd23',
                Test2: 'Hej',
                Test3: 'Hej',
                Test4: 'Hej'
              },
              {
                Test: 'Wow',
                Id: 'saighasdsdfsfgdsbdd23',
                Test2: 'Hej',
                Test3: 'Hej',
                Test4: 'Hej'
              },
              {
                Test: 'Wow',
                Id: 'ssdfsgdfg',
                Test2: 'Hej',
                Test3: 'Hej',
                Test4: 'Hej'
              },
              {
                Test: 'Wow',
                Id: 'aasdfasdfsg',
                Test2: 'Hej',
                Test3: 'Hej',
                Test4: 'Hej'
              }
            ],
            head: [
              { Alias: 'Id', Value: 'Wow' },
              { Alias: 'Test', Value: 'Test' },
              { Alias: 'Test2', Value: 'Test2' },
              { Alias: 'Test3', Value: 'Test3' },
              { Alias: 'Test4', Value: 'Test4' }
            ]
          }"
          :disableSummary="true"
          :maxHeight="30"
          :enableRenderFunctions="true"
          :bulkactions="bulkactions"
        />
      </GlobXpanel>
    </GlobCol>
  </GlobRow>
</template>

<script>
export default {
  setup() {
    const actions = [
      {
        name: "Redigera",
        action: (obj) => {
          console.log(obj)
        }
      },
      {
        name: "Radera",
        color: "red",
        buttonStyle: "outline",
        requirements: {
          consequence: (obj) => `Är du säker på att du vill radera?`
        },
        action: (obj) => {
          console.log(obj)
        }
      }
    ];
    const bulkactions = {
      actions: [
        {
          name: "Frisläpp",
          value: "release"
        },
        {
          name: "Ta bort från plan",
          value: "remove"
        },
        {
          name: "Till förtillverkning",
          value: "prefab"
        }
      ],
      functions: {
        release: async (items) => {
          console.log(items);
        },
        prefab: async (items) => {
          console.log(items);
          return true;
        },
        remove: async (items) => {
          console.log(items);
          return true;
        }
      }
    };
    return {
			bulkactions,
			actions
    };
  }
};
</script>
