<template>
<div class="kis-input-dynamic">
	<div class="dynamic-type-input" v-if="type == 'select'">
		<div class="dynamic-type-input__validation-message">{{validationMessage}}</div>
		<InputSelect
			:fullWidth="fullWidth"
			@input="$emit('update:modelValue', $event.target.value)"
			:disabled="disabled"
			:value="modelValue"
		/>
	</div>
	<div class="dynamic-type-input" v-if="type == 'daterange'">
			<div class="dynamic-type-input__validation-message">{{ validationMessage }}</div>
			<date-picker type="date" 
				:class="fullWidth ? 'full-width' : ''"
				:value="modelValue"
				@change="(val) => emitDateRangeValues(val)"
				range 
				:placeholder="placeholder"></date-picker>
	</div>
	<div class="dynamic-type-input" v-else-if="type == 'multiselect'">
		<div class="dynamic-type-input__validation-message">{{validationMessage}}</div>
		<InputMultiSelect 
			:fullWidth="fullWidth"
			@updateValue=" (values) => emitValues(values)"
			@searchKey="(searchKey) => $emit('searchKey', searchKey)"
			:disabled="disabled"
			:value="modelValue"
			:placeholder="placeholder"
			:data="data"
			:selected="selected"
			:isFilter="isFilter"
		/>
	</div>
	<div class="dynamic-type-input" v-else-if="type == 'number'">
		<div class="dynamic-type-input__validation-message">{{validationMessage}}</div>
		<InputNumber
			:fullWidth="fullWidth"
			@input="$emit('update:modelValue', $event.target.value)"
			:disabled="disabled"
			:value="modelValue"
		/>
	</div>
	<div class="dynamic-type-input" v-else-if="type == 'text'">
		<div class="dynamic-type-input__validation-message">{{validationMessage}}</div>
		<InputText
			:fullWidth="fullWidth"
			@input="$emit('update:modelValue', $event.target.value)"
			:disabled="disabled"
			:value="modelValue"
			:placeholder="placeholder"
		/>
	</div>
	<div class="dynamic-type-input" v-else>
		<div class="dynamic-type-input__validation-message">{{validationMessage}}</div>
		<input
			class="kits-input"
			:class="fullWidth ? 'full-width' : ''"
			@input="$emit('update:modelValue', $event.target.value)"
			:value="modelValue"
			:type="type"
		/>
	</div>
</div>
</template>

<script>
import { props } from './common';
import { ref } from 'vue';
import DatePicker from 'vue-datepicker-next';
import 'vue-datepicker-next/index.css';
export default {
	components: {
		DatePicker
	},
	props: [
		...props,
		'type',
		'fullWidth'
	],
	setup(props, {emit}){
		const validationMessage = ref('')

		const emitValues = (values) => {
			emit('update:modelValue', values)
			emit('input', values)
		}

		const emitDateRangeValues = (dateRange) => {
			emit('update:modelValue', dateRange)
		}

		return {
			validationMessage,
			emitValues,
			emitDateRangeValues
		}
	}
}
</script>

<style lang="scss">
.kis-input-dynamic{
	.mx-input-wrapper{
		margin-top: -5px;
	}
	.mx-datepicker-range {
		width: 100%;
	}
}


</style>