<template>
 <div>
	 <Navbar :items="routes" />
		<GlobRow>
			<GlobCol :medium="12">
				<GlobXpanel :title="$t('administration.locker.lockers')">
					<router-link :to="{name: 'createLockerV2'}" style="margin-bottom: 1rem; display: inline-block;" class="button button--margin button--small">{{$t('general.create_new')}}</router-link>
					<InputText type="text" v-model="searchString" :placeholder="$t('general.search')" :style="'margin-left: 1rem; padding: .4rem 1rem'"/>
				<GlobTable
					:enableRenderFunctions="true"
					:maxHeight="50"
					:data="{
						head : lockerListHead,
						body : filteredLockerList
					}"
					:actions="actions"
				/>
				</GlobXpanel>
			</GlobCol>
		</GlobRow>
</div>
</template>

<script>
import { onMounted, computed, watch, ref , getCurrentInstance } from "vue";
import { useApi } from "@/plugins/api";
import { useRouter } from "vue-router";
import Navbar from "@/views/Admin/Navbar.vue";
import { routes } from "@/views/Admin/config";
import { useI18n } from 'vue-i18n'

export default {
components: {
	Navbar
},

 setup() {

	const { tm } = useI18n()
	const api = useApi();
	const router = useRouter();
	const endpoint = computed(() => api.lockersV2);
    
	const loaded = ref(false);
	const searchString = ref('');
	const lockerList  = ref([]);
	const lockerListHead = computed(() => 
	    [{
				Alias: "Id",
				Value: tm('administration')['locker']['fields']['id']
			},
			{
				Alias: "CustomerName",
				Value: tm('administration')['locker']['fields']['customer']
			},
			{
				Alias: "Name",
				Value: tm('administration')['locker']['fields']['name']
			},
			{
				Alias: "Location",
				Value: tm('administration')['locker']['fields']['location']
			},
		]
	
	 );
	
	
	const actions = computed(() => [
			{
				name : tm('general')['button']['edit'],
				color: "green",
				action: (obj) => {
					router.push('/admin/edit/locker-v2/' + obj.Id)
				}
			},
		]
	)
	
	 onMounted(async() => {
          loadLockers();
	 });

	const loadLockers = async() => {
		endpoint.value.get().then(function(res){
           lockerList.value = res.lockers;
		})
	}

	const filteredLockerList = computed(() => {
       return searchString.value.length > 0 ? lockerList.value.filter(i => i.Name && i.Name.toLowerCase().match(searchString.value.toLowerCase())) : lockerList.value
	});

    return {
		//Methods
		loadLockers,
		//Variables
		endpoint,
		filteredLockerList,
		lockerList,
		lockerListHead,
		searchString,
		actions,
		routes,
		loaded
    };
  }
};
</script>