<template>
    <GlobLoader v-if="isLoading"/>
	<div v-else class="Kits-crud">
                <div class="kits-top-button-row">
                    <router-link class="button button--linearGray" :to="{name: 'PricePlan'}"
                        >{{$t('general.button.back_to_overview')}}</router-link>
                </div>
            <GlobXpanel :title="$t('billing.price_plan.create_price_plan')" style="max-width: 900px;">
				<div class="ips-admin-fieldgroup">
					<GlobXpanel :noShadow="true" :tools="false" :title="$t('billing.price_plan.crud_section.reseller')">
					<GlobRow>
						<GlobCol :medium="4">
							<label class="crud-label">
										{{$t('billing.price_plan.fields.reseller')}}*
							<InputSelect :fullWidth="true" v-model="pricePlan.form.ResellerCustomerId" :placeholder="$t('billing.price_plan.fields.select_reseller')" :data="resellers" />
							</label>
						</GlobCol>
						</GlobRow>
					</GlobXpanel>
				</div>
				<div class="ips-admin-fieldgroup">
					<GlobXpanel :noShadow="true" :tools="false" :title="$t('billing.price_plan.crud_section.general')">
						<GlobRow>
							<GlobCol :medium="4">
								<label class="crud-label">
									{{$t('billing.price_plan.fields.name')}}*
									<InputDynamic
									:fullWidth="true"
									styling="margin-bottom: .5rem;"
									:type="'text'"
									:placeholder="$t('billing.price_plan.fields.name')"
									v-model="pricePlan.form.Name"
									/>
								</label>
							</GlobCol>
							<GlobCol :medium="4">
								<label class="crud-label">
									{{$t('billing.price_plan.fields.start_date')}}
									<InputDynamic
									:fullWidth="true"
									styling="margin-bottom: .5rem;"
									:type="'date'"
									:placeholder="$t('billing.price_plan.fields.start_date')"
									v-model="pricePlan.form.StartDate"
									/>
								</label>
							</GlobCol>
							<GlobCol :medium="4">
							<label class="crud-label">
								{{$t('billing.price_plan.fields.stop_date')}}
								<InputDynamic
								:fullWidth="true"
								styling="margin-bottom: .5rem;"
								:type="'date'"
								:placeholder="$t('billing.price_plan.fields.stop_date')"
								v-model="pricePlan.form.StopDate"
								/>
							</label>
							</GlobCol>
                            <GlobCol :medium="4">
								<label class="crud-label">
									{{$t('billing.price_plan.fields.billing_type')}}*
									<InputSelect
									:fullWidth="true"
									styling="margin-bottom: .5rem;"
									:type="'number'"
									:placeholder="$t('billing.price_plan.fields.select_billing_type')"
									v-model="pricePlan.form.BillingType"
									:data="billingTypes"
									/>
								</label>
							</GlobCol>
                            <GlobCol :medium="4">
								<label class="crud-label">
									{{$t('billing.price_plan.fields.currency')}}
									<InputSelect
									:fullWidth="true"
									styling="margin-bottom: .5rem;"
									:type="'number'"
									:placeholder="$t('billing.price_plan.fields.select_currency')"
									v-model="pricePlan.form.CurrencyId"
									:data="currencies"
									/>
								</label>
							</GlobCol>
                            <GlobCol :medium="12" class="d-flex">
                                <InputCheckbox
											:id="'allowEndCustomerOverRide'"
											:modelValue="pricePlan.form.AllowEndCustomerOverride ? true : false"
											@input="(val) => pricePlan.form.AllowEndCustomerOverride = val"
										/>
                                <label for="allowEndCustomerOverRide" class="crud-label crud-label-margin-left">
                                    {{$t('billing.price_plan.fields.allow_end_customer_over_ride')}}
                                </label>
							</GlobCol>
						</GlobRow>
					</GlobXpanel>
				</div>
				<div v-if="errorMessage">
					<p class="text-danger mt-2" >{{errorMessage}}</p>
				</div>
				<div class="locker-action-buttons">
					<button class="button"  :disabled="isSaveButtonDisabled()" @click="create">{{$t('general.button.save')}}</button>
					<router-link class="button button--orange" :to="{name:'PricePlan'}"
					>{{$t('general.button.cancel')}}</router-link>
				</div>
		</GlobXpanel>
	</div>
</template>
<script>
import { onMounted, computed, watch, ref, reactive } from "vue";
import { useApi } from "@/plugins/api";
import { useRouter } from "vue-router";
import { useNotifications } from "@/plugins/notifications";
import { useI18n } from 'vue-i18n';

export default {
 setup() {

	const { tm } = useI18n();
	const api = useApi();
	const router = useRouter();
	const notifications = useNotifications();

    const endpoint = computed(() => api.pricePlans);

    const isLoading = ref(false);

	const billingTypes = [{
		'value': 'post',
		'name': 'Post'
	},{
		'value': 'pre',
		'name': 'Pre'
	}]

    const currencies = ref([]);

    const errorMessage = ref(null);
    const pricePlan = reactive({
		form: {
            ResellerCustomerId: "",
			Name: "",
            StartDate: "",
            StopDate: "",
            AllowEndCustomerOverride: "",
            BillingType: "",
            CurrencyId: ""
		},
	});
		
    let resellers = ref([]);
	 onMounted(async() => {
		 //get currencies
		 api.currencies.get().then((res) => {
                currencies.value = res.currencies;
        });
		
        //Get list of resellers
        api.resellers.get().then((res) => {
                resellers.value = res.resellers.map((item) => ({
                value: item.id,
                name: item.CustomerName
            }));
        });
	 });

	const create = () => {

       if(!validateForm()){
		   return false;
	   }
		isLoading.value = true;

		endpoint.value.create(pricePlan.form).then(function(res){
           if(res.success){
				notifications.push(
					{
						msg: tm('billing')['price_plan']['create_success']
					},
					"success",
					false
					);
				router.push({'name': 'PricePlan'})
		   } else {
				errorMessage.value = tm('general')['something_went_wrong'];
		   }
		   isLoading.value = false;
		})
	}

	const isSaveButtonDisabled = () => {
		return pricePlan.form.ResellerCustomerId && pricePlan.form.Name && pricePlan.form.BillingType  ? false : true;
	}


	const validateForm = () =>{
		errorMessage.value = null;
		
		if(pricePlan.form.StartDate && pricePlan.form.StopDate){
			if(pricePlan.form.StopDate < pricePlan.form.StartDate){
				errorMessage.value = tm('billing')['price_plan']['errors']['stop_date_should_be_greater_than_start'];
				return false;
			}
		}

		return true;
	}

     	
    return {
		pricePlan,
		isLoading,
		validateForm,
		create,
		errorMessage,
		isSaveButtonDisabled,
		resellers,
		billingTypes,
        currencies
    };
  }
};
</script>
<style>
.crud-label-margin-left{
    margin-left: .5rem;
}
</style>
